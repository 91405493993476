

// @mui material components
import Card from "@mui/material/Card";
// import Divider from "@mui/material/Divider";
import Icon from "@mui/material/Icon";

// Material Dashboard 2 React components
import Box from "@mui/material/Box";
import Typography from "@mui/material/Typography";
// import MDButton from "components/MDButton";
import Transaction from "../Transaction";
// Billing page components
import { DateRange } from '@material-ui/icons';


const getCurrentMonthRange = (): string => {
  const currentDate: Date = new Date();
  const startDate: Date = new Date(currentDate.getTime() - 30 * 24 * 60 * 60 * 1000);
  const endDate: Date = currentDate;

  const options: Intl.DateTimeFormatOptions = {
    day: "numeric",
    month: "long",
    year: "numeric",
  };
  const startDateString: string = startDate.toLocaleDateString(undefined, options);
  const endDateString: string = endDate.toLocaleDateString(undefined, options);

  return `${startDateString} - ${endDateString}`;
};


function Transactions() {

  const dateRange: string = getCurrentMonthRange();

  return (
    <Card sx={{ height: "100%", width: {xs: "50%", lg: "100%"}, boxShadow: "0 20px 25px -5px rgba(0, 0, 0, 0.2), 0 10px 10px -5px rgba(0, 0, 0, 0.2) !important", margin: "6vh auto"
  }}>
      <Box display="flex" justifyContent="space-between" alignItems="center" pt={3} px={2}>
        <Typography variant="h6" fontWeight="medium" textTransform="capitalize" sx={{
          background: "linear-gradient(90deg, #964cff, #bb2cc0)",
          backgroundClip: "text", textFillColor: "transparent", fontSize: "1.4rem"
        }}>
          Your Transactions
        </Typography>
        <Box display="flex" alignItems="flex-start">
          <Box color="text" mr={0.5} lineHeight={1}>
            <Icon color="inherit" >
              <DateRange />
            </Icon>
          </Box>
          <Typography variant="button" color="text" fontWeight="regular" sx={{fontSize: "1rem"}}>
            {dateRange}
          </Typography>
        </Box>
      </Box>
      <Box pt={3} pb={2} px={2}>
        
        <Box
          component="ul"
          display="flex"
          flexDirection="column"
          p={0}
          m={0}
          sx={{ listStyle: "none" }}
        >
          <Transaction
            color="error"
            icon="expand_more"
            name="Referral"
            description="27 March 2020, at 12:30 PM"
            value="- $ 2,500"
          />
          <Transaction
            color="success"
            icon="expand_less"
            name="Referral"
            description="27 March 2020, at 04:30 AM"
            value="+ $ 2,000"
          />
        </Box>
      </Box>
    </Card>
  );
}

export default Transactions;
