

import { useState, useEffect, useContext } from "react";

// prop-types is a library for typechecking of props.
import PropTypes, { any } from "prop-types";

// @mui material components
import Card from "@mui/material/Card";
import Grid from "@mui/material/Grid";

// Material Dashboard 2 React components
import Box from "@mui/material/Box";
import IconButton from "@mui/material/IconButton";
import Avatar from "@mui/material/Avatar";

import Typography from "@mui/material/Typography";
// Material Dashboard 2 React base styles
import breakpoints from "../../../../assets/theme/base/breakpoints";

// Images
import backgroundImage from "../../../../assets/images/bg-profile.jpeg";
import { FireStoreUserContext } from "../../../../context/FireStoreUserContext";
import { AuthContext } from "../../../../context/AuthContext";
import { getStorage, ref, uploadBytes, getDownloadURL } from "firebase/storage";
import * as Sentry from "@sentry/react";
import { db } from "../../../../firebase";
import { firestoreKeys } from "../../../../utils/constants";
import { doc, updateDoc } from "firebase/firestore";

function Header({ children }: any) {
  const [tabsOrientation, setTabsOrientation] = useState("horizontal");
  const [file, setFile] = useState<string>("");

  const { parkshareUser } = useContext(FireStoreUserContext)
  const user = useContext(AuthContext)
  const storage = getStorage();


  console.log("Got user from home:", parkshareUser)
  useEffect(() => {
    // A function that sets the orientation state of the tabs.
    function handleTabsOrientation() {
      return window.innerWidth < breakpoints.values.sm
        ? setTabsOrientation("vertical")
        : setTabsOrientation("horizontal");
    }

    function setImageUrl() {
      if (parkshareUser.person.photoUrl !== undefined) {
        console.log("Use image", parkshareUser.person.photoUrl)
        setFile(parkshareUser.person.photoUrl)
      } else {
        if (user!.photoURL !== undefined) {
          setFile(user!.photoURL?.toString()!)
        }
      }
    }

    /** 
     The event listener that's calling the handleTabsOrientation function when resizing the window.
    */
    window.addEventListener("resize", handleTabsOrientation);

    // Call the handleTabsOrientation function to set the state with the initial value.
    handleTabsOrientation();
    setImageUrl();

    // Remove event listener on cleanup
    return () => window.removeEventListener("resize", handleTabsOrientation);
  }, [tabsOrientation]);



  const handleChange = function loadFile(event: any) {
    const file = URL.createObjectURL(event.target.files[0]);
    setFile(file);
    if (event.target.files.length > 0) {
      // File save and set url
      console.log(event.target.files[0])
      const storageRef = ref(storage, `${user!.uid}/${event.target.files[0].name}`);
      uploadBytes(storageRef, event.target.files[0]).then((snapshot) => {
        console.log('Uploaded a blob or file!');
      });
      getDownloadURL(ref(storage, `${user!.uid}/${event.target.files[0].name}`))
        .then((url) => {
          // setFile(url);
          console.log("Update user to get this")
          const docRef = doc(db, firestoreKeys.USERS, user!.uid);
          updateDoc(docRef, { photoUrl: url})
            .then(docRef => {
              console.log("A New Document Field has been added to an existing document");
            })
            .catch(error => {
              console.log(error);
            })
        })
        .catch((error) => {
          Sentry.captureException(error)
        });
      // Local
    }
  };

  return (
    <Box position="relative" mb={5}>
      <Box
        display="flex"
        alignItems="center"
        position="relative"
        minHeight="18.75rem"
        borderRadius="xl"
        sx={{
          background: `linear-gradient(190deg, #9753f7 5%, rgba(0, 0, 0, 0)30%), url(${backgroundImage});`,
          backgroundSize: "cover",
          backgroundPosition: "50%",
          overflow: "hidden",
        }}
      />
      <Card
        sx={{
          position: "relative",
          mt: -8,
          mx: 3,
          py: 2,
          px: 2,
        }}
      >
        <Grid container spacing={3} alignItems="center">
          <Grid item>
            <input type="file" id="upload" onChange={handleChange} accept="image/*" style={{ display: "none" }} />
            <label htmlFor="upload">
              <IconButton color="primary" aria-label="upload picture" component="span">
                <Avatar id="avatar" src={file}
                  style={{
                    width: "60px",
                    height: "60px",
                  }}
                />
              </IconButton>
            </label>
            <label htmlFor="avatar" />
          </Grid>
          <Grid item>
            <Box height="100%" mt={0.5} lineHeight={1}>
              <Typography variant="h5" fontWeight="medium">
                {parkshareUser.person.firstName} {parkshareUser.person.lastName}
              </Typography>
            </Box>
          </Grid>
        </Grid>
        {children}
      </Card>
    </Box>
  );
}

// Setting default props for the Header
Header.defaultProps = {
  children: "",
};

// Typechecking props for the Header
Header.propTypes = {
  children: PropTypes.node
};


export default Header;
