import Typography from "@mui/material/Typography";
import "./400.css";

// components/Home.js
export function NotFoundPage() {
  return (
    <>
      <Typography
        className="notf"
        variant="h1"
        sx={{ fontSize: { xs: 75, sm: 100, md: 200, lg: 332, xl: 270 } }}
        mt={5}
        mb={0}
      >
        404
      </Typography>

      <Typography variant="h5" sx={{ fontSize: "1.8rem"}}> This page cannot be found. </Typography>
    </>
  );
}
