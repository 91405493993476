import { BounceLoader } from 'react-spinners';
import { useContext, useEffect, useState } from 'react';
import { AuthContext } from '../../context/AuthContext';
import { Grid, Typography, Box, Button } from '@mui/material';
import type { BadgeProps } from 'antd';
import { Badge, Calendar } from 'antd';
import type { Dayjs } from 'dayjs';
import './bookings.css';
import type { CellRenderInfo } from 'rc-picker/lib/interface';
import './bookings.css'

interface ScheduleItem {
  type: 'warning' | 'success' | 'error';
  content: string;
  startTime: string;
  endTime: string;
  spot: string;
}

const getListData = (value: Dayjs) => {
  let listData: ScheduleItem[] = [];
  switch (value.date()) {
    case 8:
      listData = [
        { type: 'warning', content: 'Reupload new images', startTime: '9:00 AM', endTime: '11:00 AM', spot: "parking1" },
        { type: 'success', content: 'This is usual event.', startTime: '1:00 PM', endTime: '3:00 PM', spot: "parking1" },
      ];
      break;
    case 10:
      listData = [
        { type: 'warning', content: 'This is warning event.', startTime: '10:00 AM', endTime: '12:00 PM', spot: "parking1" },
        { type: 'success', content: 'This is usual event.', startTime: '2:00 PM', endTime: '4:00 PM', spot: "parking2" },
        { type: 'error', content: 'This is error event.', startTime: '4:00 PM', endTime: '6:00 PM', spot: "parking2" },
      ];
      break;
    case 15:
      listData = [
        { type: 'warning', content: 'This is warning event', startTime: '9:00 AM', endTime: '10:00 AM', spot: "parking1" },
        { type: 'success', content: 'This is very long usual event...', startTime: '11:00 AM', endTime: '4:00 PM', spot: "parking1" },
        { type: 'error', content: 'This is error event 1.', startTime: '1:00 PM', endTime: '2:00 PM', spot: "parking3" },
        { type: 'error', content: 'This is error event 2.', startTime: '2:00 PM', endTime: '3:00 PM', spot: "parking1" },
        { type: 'error', content: 'This is error event 3.', startTime: '3:00 PM', endTime: '4:00 PM', spot: "parking3" },
        { type: 'error', content: 'This is error event 4.', startTime: '4:00 PM', endTime: '5:00 PM', spot: "parking1" },
      ];
      break;
    default:
  }
  return listData || [];
};

const getMonthData = (value: Dayjs) => {
  if (value.month() === 8) {
    return 1394;
  }
};

export function Bookings() {
  const user = useContext(AuthContext);
  const [isLoaded, setIsLoaded] = useState(false);
  const [selectedDay, setSelectedDay] = useState<Dayjs | null>(null);
  const [selectedDaySchedule, setSelectedDaySchedule] = useState<ScheduleItem[]>([]);

  useEffect(() => {
    const getData = async () => {
      setIsLoaded(true);
      // Retrieve data and perform necessary operations
    };
    getData();
  }, []);

  const monthCellRender = (value: Dayjs) => {
    const num = getMonthData(value);
    return num ? (
      <div className="notes-month">
        <section>{num}</section>
        <span>Backlog number</span>
      </div>
    ) : null;
  };

  const dateCellRender = (value: Dayjs) => {
    const listData = getListData(value);
    return (
      <ul className="events">
        {listData.map((item) => (
          <li key={item.content}>
            <Badge status={item.type as BadgeProps['status']} text={item.content} />
          </li>
        ))}
      </ul>
    );
  };
  const dateFullCellRender = (date: Dayjs): React.ReactNode => {
    const listData = getListData(date);
    const isToday = date.isSame(new Date(), 'day');
    const isSelected = selectedDay?.isSame(date, 'day');

    return (
      <div
        className={`ant-fullcalendar-date ${isToday ? 'ant-fullcalendar-today' : ''} ${isSelected ? 'ant-fullcalendar-selected-day' : ''
          }`}
      >
        <div className="ant-fullcalendar-value">{date.date()}</div>
        <ul className="ant-fullcalendar-content">
          {listData.map((item) => (
            <li key={item.content}>
              <Badge status={item.type as BadgeProps['status']} text={item.content} />
            </li>
          ))}
        </ul>
      </div>
    );
  };

  // Grouping
  const groupScheduleBySpot = (schedule: ScheduleItem[]): Record<string, ScheduleItem[]> => {
    return schedule.reduce((grouped, item) => {
      if (!grouped[item.spot]) {
        grouped[item.spot] = [];
      }
      grouped[item.spot].push(item);
      return grouped;
    }, {} as Record<string, ScheduleItem[]>);
  };

  //Add this to close viewer
  const closeDayViewer = () => {
    setSelectedDay(null);
    setSelectedDaySchedule([]);
  }

  const handleDayClick = (value: Dayjs) => {

      // Clicked on a different day, update the selected day and schedule
      setSelectedDay(value);
      const schedule = getListData(value);
      setSelectedDaySchedule(schedule);
  
  };

  const groupedDaySchedule = groupScheduleBySpot(selectedDaySchedule);


  return (
    <>
      {isLoaded ? (
        <Grid container sx={{display: "flex", justifyContent: "space-around"}}>
          <Grid xs={10} lg={7} sm={selectedDay ? 9 : 12} sx={{ display: 'flex', justifyContent: 'center', alignItems: 'center', margin: '4vh auto', padding: '0px' }}>
            <Calendar  dateCellRender={dateFullCellRender} onSelect={handleDayClick} className="custom-calendar calandar"  />
          </Grid>

          {selectedDay && (
            <Grid xs={10} sm={3} lg={3} container sx={{ display: "flex", justifyContent: "center", margin: "4vh auto"}}>
              <Grid item><Button onClick={ closeDayViewer}><span className="material-symbols-outlined" style={{color: "red", textAlign: "right"}}>cancel</span></Button></Grid>

              <Grid item sx={{marginTop: "3vh"}}>
                <Typography variant="h6" sx={{ marginBottom: '10px' }}>{selectedDay.format('YYYY-MM-DD')}</Typography>
                {selectedDaySchedule.length > 0 ? (
                  // Show grouped schedule
                  <>
                    <Typography variant="body1" sx={{marginTop: "2vh", fontSize: "1.2rem"}}>Here are your bookings:</Typography>
                    {Object.entries(groupedDaySchedule).map(([spot, schedule]) => (
                      <Box key={spot} sx={{ marginTop: '20px', borderBottom: '1px solid #ccc' }}>
                        <Typography variant="h6" sx={{background: "-webkit-linear-gradient(90deg, #964cff, #bb2cc0)", WebkitBackgroundClip: "text", WebkitTextFillColor: "transparent", fontSize: "1.3rem", marginBottom: "2vh"}}>{spot}</Typography>
                        <ul style={{display: "flex", justifyContent: "center", flexDirection: "column"}}>
                          {schedule.map((item) => (
                            <li key={item.content} style={{marginBottom: "2vh", fontSize: "1rem", width: "90%"}}>{`${item.startTime} - ${item.endTime}: ${item.content}`}</li>
                          ))}
                        </ul>
                      </Box>
                    ))}
                  </>
                ) : (
                  // Show message when schedule is empty
                  <Typography variant="body1">No bookings </Typography>
                )}
              </Grid>
            </Grid>
          )}
        </Grid>
      ) : (
        <div style={{ width: '100%', height: '70vh', display: 'flex', alignItems: 'center', justifyContent: 'center' }}>
          <div style={{ marginTop: '25vh' }}>
            <BounceLoader color="#9753f7" cssOverride={{}} loading size={100} />
          </div>
        </div>
      )}
    </>
  );
}
