// components/Home.js
import { BounceLoader } from "react-spinners";
import { useEffect, useState } from "react";
import Grid from "@mui/material/Grid";
import handLogo from "../../../src/assets/img/home/Holding-Hand-Smart-Phone-Mockup-min-removebg.webp";
import mapLogo from "../../../src/assets/img/home/map.webp";
import mockLogo from "../../../src/assets/img/home/Mockup.webp";
import { Box, Button, IconButton } from "@mui/material";
import LooksOneIcon from "@mui/icons-material/LooksOne";
import LooksTwoIcon from "@mui/icons-material/LooksTwo";
import LooksThreeIcon from "@mui/icons-material/Looks3";
import Container from "@mui/material/Container";
import './home.css';
import rgba from "../../assets/theme/functions/rgba";
import Typography from '@mui/material/Typography';


export function Home() {
  const [isLoaded, setIsLoaded] = useState(false);

  useEffect(() => {
    const getData = async () => {};
    getData();
  }, []);

  return (
    <>
      <Grid container>
        <Grid className="landing-container" sx={{display: "flex", flexDirection: {xs: "column", lg: "row"}}}>
          <Grid
            item
            lg={6}
            md={6}
            xs={12}
            
            direction="column"
            alignItems="center"
            sx={{display: "flex", justifySelf: {xs: "center", lg: "flex-start"}, backdropFilter: "blur(10px)", padding: "40px", textAlign: "left", transform: {xs: "translateX(0px) traslateY(0px)", lg: "translateX(130px) translateY(-80px)"} }}
          >
            <div className="textContainer">
              <Typography variant="h1" sx={{ marginLeft: {xs: "0vw", lg: "-1.1vw"}, background: "-webkit-linear-gradient(90deg, #964cff, #bb2cc0)", WebkitBackgroundClip: "text", WebkitTextFillColor: "transparent", fontWeight: 600}}> Parking Reimagined </Typography>
              <Typography variant="body1" sx={{ marginLeft: "-1.1vw", textAlign: {xs: "left", lg: "left"}}}>
                ParkShare Technologies aims to provide a reliable source <br/>of
                income to hosts and an easy-to-use app for users on <br/>their daily
                commute. We are currently based in Cape Town <br/>and will soon be
                available across the globe.
              </Typography>
            </div>
          </Grid>
          
          <Grid
            item
            lg={6}
            md={6}
            xs={12}
            alignItems="center"
            justifyContent="center" sx={{zIndex: "1"}}>
            <Box
              component="img"
              sx={{
                height: "100%",
                width: {xs: "90%", lg: "70%"},
                marginBottom: "-35px",
                zIndex: "1"
              
              }}
              src={handLogo}
            />
          </Grid>

         
          <svg xmlns="http://www.w3.org/2000/svg" viewBox="0 0 1440 320" style={{width: "100%", position: "absolute", left: "0", right: "0", bottom: "-30"}}>
            <path fill="#964cff" fill-opacity="1" d="M0,32L48,69.3C96,107,192,181,288,208C384,235,480,213,576,186.7C672,160,768,128,864,101.3C960,75,1056,53,1152,37.3C1248,21,1344,11,1392,5.3L1440,0L1440,320L1392,320C1344,320,1248,320,1152,320C1056,320,960,320,864,320C768,320,672,320,576,320C480,320,384,320,288,320C192,320,96,320,48,320L0,320Z"></path>
          </svg>
        </Grid>
        {/* Second Section */}
  
          <Grid container
            sx={{ minHeight: "50vh", backgroundColor: "#c7d3e233", 
                  display: "flex",
                  justifyContent: "space-around", marginTop: "0vh", padding: "2vw" }}>
            <Grid item lg={12} md={12} xs={12} mt={3}>
              <h1 style={{fontSize: "2rem", background: "-webkit-linear-gradient(90deg, #964cff, #bb2cc0)", WebkitBackgroundClip: "text", WebkitTextFillColor: "transparent"}}>How It Works?</h1>
            </Grid>

            <Grid item lg={3} md={4} xs={10} mt={"3vh"}>
              <div>
                <LooksOneIcon
                  sx={{ color: "#964cff", width: {xs: 40, lg: 60}, height: {xs: 40, lg: 60} }}
                />
                <div style={{ marginBottom: "2vh" }}>
                  <span style={{ fontSize: "1.2rem", fontWeight: 600}}>Download the App</span>
                </div>
                <div>
                  <p style={{ fontSize: "1.1em" }}>
                    ParkShare will be available on the App Store and Google Play
                    Store.
                  </p>
                </div>
              </div>
            </Grid>

            <Grid item lg={3} md={4} xs={10} mt={"3vh"}>
              <div>
                <LooksTwoIcon
                 sx={{ color: "#964cff", width: {xs: 40, lg: 60}, height: {xs: 40, lg: 60} }}
                />
                <div style={{ marginBottom: "2vh"}}>
                  <span style={{ fontSize: "1.2rem", fontWeight: 600 }}>
                    Sign Up & List your Parking
                  </span>
                </div>
                <div>
                  <p style={{ fontSize: "1.1em" }}>
                    Sign up to effortlessly list your parking and start earning
                    money.
                  </p>
                </div>
              </div>
            </Grid>

            <Grid item lg={3} md={4} xs={10} mt={"3vh"}>
              <div>
                <LooksThreeIcon
                  sx={{ color: "#964cff", width: {xs: 40, lg: 60}, height: {xs: 40, lg: 60} }}
                />
                <div style={{  marginBottom: "2vh" }}>
                  <span style={{ fontSize: "1.2rem", fontWeight: 600 }}>Available Near You</span>
                </div>
                <div>
                  <p style={{fontSize: "1.1em" }}>
                    Enter your destination to find a secure <br/> parking spot hassle
                    free!
                  </p>
                </div>
              </div>
            </Grid>
          </Grid>
  
        {/* Third */}
        <br />
        <Grid item  sx={{  padding: "4vh 6vw", display: "flex", flexDirection: {xs: "column", lg: "row"}, justifyContent: "center" }}>
          <Grid
            item
            lg={6}
            md={4}
            xs={12}
            mt={"3vh"}
            
            sx={{display: "flex", justifySelf: {xs: "center", lg: "flex-start"}, 
            alignItems: "center", justifyContent: "center"}}>
            <div style={{width: "70%"}}>
              <Typography variant="h1" sx={{background: "-webkit-linear-gradient(90deg, #964cff, #bb2cc0)", WebkitBackgroundClip: "text", WebkitTextFillColor: "transparent", fontSize: "1.5rem", display: "flex", justifyContent: "flex-start", padding: 0, fontWeight: 600}}>Find out if we are in your Neighbourhood</Typography>
              <Typography variant="body1" sx={{marginTop: "2vh", textAlign: "left", marginLeft: {xs: "0vw", lg: "1vw"} }}>
                We currently operate in Cape Town and surrounds. We will soon 
                extend to Johannesburg, Centurion, Pretoria, and Durban.
              </Typography>
            </div>
          </Grid>

          <Grid item xs={12} lg={1}></Grid>
          
          <Grid
            item
            lg={5}
            md={4}
            xs={12}
            mt={"3vh"}
          >
            <Box
              component="img"
              sx={{
                border: "3px solid #964cff",
                padding: "1vh",
                borderRadius: "0.5vw",
                width: {xs: "90%", lg: "90%"}
              }}
              src={mapLogo}
            />
          </Grid>
        </Grid>


        {/* 4 */}
        <Grid
          container
          sx={{ backgroundColor: "#c7d3e233", minHeight: "50vh", padding: "4vh 2vw", display: "flex", justifyContent:"center" 
        }}>
          <Grid
            lg={5}
            md={6}
            xs={12}
            container
            direction="column"
            alignItems="center"
            justifyContent="center"
          >
            <Box
              component="img"
              sx={{
                height: "90%",
                width: "60%",
              }}
              alt="Two phones with the parkshare app on the screen"
              src={mockLogo}
            />
          </Grid>

          <Grid item xs={12} lg={1}></Grid>

        

          <Grid
            item
            lg={6}
            md={4}
            xs={12}
           
            sx={{display: "flex", justifySelf: "flex-end", 
            alignItems: "center", justifyContent: "center"}}
           
            direction="column"
          
          ><div style={{width: "70%"}}>
            <h1 style={{fontSize: "2rem", background: "-webkit-linear-gradient(90deg, #964cff, #bb2cc0)", WebkitBackgroundClip: "text", WebkitTextFillColor: "transparent", margin: "1vh auto"}}>Stay Updated!</h1>
            <p style={{marginTop: "3vh", textAlign: "left", marginBottom: "3vh"}}>
              ParkShare provides a platform for hosts to list unoccupied parking
              spots and for users to pre-book a parking near their destination.
            </p>
          
            <p style={{marginTop: "3vh"}}>
              Get the latest app version, news & updates. 
            </p>
            <Button variant="contained"
                    type="submit"
                    color="secondary" sx={{backgroundColor: "#9753f7", color: "white", marginTop: "4vh", padding: " 1vh 1vw"}}>Subscribe to our
              newsletter</Button>
          </div>
            
          </Grid>
        </Grid>
      </Grid>
    </>
  );
}
