// prop-types is a library for typechecking of props
import PropTypes from "prop-types";

// @mui material components
import Icon from "@mui/material/Icon";
import PictureAsPdfIcon from '@mui/icons-material/PictureAsPdf';
// Material Dashboard 2 React components
import Box from "@mui/material/Box";
import Typography from "@mui/material/Typography";
import { getFileItem } from "../../../../utils/api.svs";
import { getAuth } from "firebase/auth";

import { Store } from "react-notifications-component";
import { useState } from "react";

export function Invoice({ date, id, price, noGutter }: any) {

  const [notificationID, setNotificationID] = useState("")

  const auth = getAuth();
  const user = auth.currentUser;

  const handleDownload = async () => {
    const url = "http://127.0.0.1:5001/parkshare-prod/europe-west2/api/billing/statement";
    if (user !== null) {
      const token = await user.getIdToken()
      try {
        getFileItem("billing/statement", token).then(resp => {
          const downloadUrl = window.URL.createObjectURL(new Blob([resp.data]));
          const link = document.createElement("a");
          link.href = downloadUrl;
          link.setAttribute("download", "statement.pdf"); // Specify the file name
          document.body.appendChild(link);
          link.click();
          document.body.removeChild(link);
        }).catch(() => {
          setNotificationID(Store.addNotification({
            title: "We could not download!",
            message: "We have received your messsage and get back to you.",
            type: "danger",
            insert: "top",
            container: "top-left",
            animationIn: ["animate__animated", "animate__fadeIn"],
            animationOut: ["animate__animated", "animate__fadeOut"],
            dismiss: {
              duration: 2000,
              onScreen: true
            }
          }));
          Store.removeNotification(notificationID)
        })
      } catch (error) {
        // Code to handle the error
        console.log(error)
      }
    };
  }

    return (
      <Box
        component="li"
        display="flex"
        justifyContent="space-between"
        alignItems="center"
        py={1}
        pr={1}
        mb={noGutter ? 0 : 1}
      >
        <Box lineHeight={1.125}>
          <Typography display="block" variant="button" fontWeight="medium">
            {date}
          </Typography>
          <Typography variant="caption" fontWeight="regular" color="text">
            {id}
          </Typography>
        </Box>
        <Box display="flex" alignItems="center">
          <Typography variant="button" fontWeight="regular" color="text">
            {price}
          </Typography>
          <Box display="flex" alignItems="center" lineHeight={1} ml={3} sx={{ cursor: "pointer" }}>
            <Icon fontSize="small" onClick={handleDownload}><PictureAsPdfIcon /></Icon>
            <Typography variant="button" fontWeight="bold">

            </Typography>
          </Box>
        </Box>
      </Box>
    );
  }


// Setting default values for the props of Invoice
Invoice.defaultProps = {
  noGutter: false,
};

// Typechecking props for the Invoice
Invoice.propTypes = {
  date: PropTypes.string.isRequired,
  id: PropTypes.string.isRequired,
  price: PropTypes.string.isRequired,
  noGutter: PropTypes.bool,
};


export default Invoice;
