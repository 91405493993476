import firebase from 'firebase/compat/app';
import { collection, doc, getCountFromServer, getDoc, query, where } from "firebase/firestore";
import { db } from "../../firebase";
import { firestoreKeys } from "../../utils/constants";

export class UserHelper {


    async getUser(id: string){
        console.log("Getting user")
        console.log("Using",id)
        const docRef = doc(db, firestoreKeys.USERS, id);
        const docSnap = await getDoc(docRef);
        console.log("Got",docSnap.data())
        return docSnap.data()
    }

    async u(id: string){
        console.log("Getting user")
        console.log("Using",id)
        const docRef = doc(db, firestoreKeys.USERS, id);
        const docSnap = await getDoc(docRef);
        console.log("Got",docSnap.data())
        return docSnap.data()
    }
}