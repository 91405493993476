// components/Protected.js
import { BounceLoader } from 'react-spinners';
import { useContext, useEffect, useState } from 'react';
import { AuthContext } from '../../context/AuthContext';
import { Button, CardMedia, FormControl, FormGroup, Grid, InputAdornment, InputLabel, OutlinedInput, Rating, Tabs, TextField } from '@mui/material';
import backgroundImage from "../../assets/img/defaults/parking-icon-png-32.png";
import { useLocation } from 'react-router-dom';
import {
    Typography,
    Checkbox,
    FormControlLabel,
    Paper,
    styled,
    Box,
    Tab,
    Avatar
} from "@mui/material";
import { List, ListItem, ListItemAvatar, ListItemText } from '@mui/material';
import { UserList } from './spotTabs/delegate';
import DateRangeSelector from './spotTabs/DateRange';
import dayjs, { Dayjs } from 'dayjs';
import WeekScheduleSelector from './spotTabs/WeekSelector';


export interface TabPanelProps {
    children?: React.ReactNode;
    index: number;
    value: number;
}

export function TabPanel(props: TabPanelProps) {
    const { children, value, index, ...other } = props;

    return (
        <div
            role="tabpanel"
            hidden={value !== index}
            id={`simple-tabpanel-${index}`}
            aria-labelledby={`simple-tab-${index}`}
            {...other}
        >
            {value === index && (
                <Box sx={{ p: 3 }}>
                    <Typography>{children}</Typography>
                </Box>
            )}
        </div>
    );
}

const reviews = [
    { id: 1, user: 'User A', rating: 4, comment: 'Great product!' },
    { id: 2, user: 'User B', rating: 3, comment: 'Average quality.' },
    { id: 3, user: 'User C', rating: 5, comment: 'Excellent service!' },
];

const users = [
    { id: 1, name: 'John Doe', avatarUrl: 'https://example.com/avatar1.jpg' },
    { id: 2, name: 'Jane Smith', avatarUrl: 'https://example.com/avatar2.jpg' },
    { id: 3, name: 'Michael Johnson', avatarUrl: 'https://example.com/avatar3.jpg' },
];

function a11yProps(index: number) {
    return {
        id: `simple-tab-${index}`,
        'aria-controls': `simple-tabpanel-${index}`,
    };
}

export interface SpotDetailsProps {
    children?: React.ReactNode;
    active: boolean;
    spot: any;
}

type DateRange = [Date | null, Date | null];

const handleDateRangeChange = (dateRanges: [dayjs.Dayjs | null, dayjs.Dayjs | null][]) => {
    // Handle the selected date ranges here
    console.log('Selected date ranges:', dateRanges);
};

const handleWeekScheduleChange = (weekSchedule: { [day: string]: [Dayjs | null, Dayjs | null] }) => {
    // Handle the updated week schedule here
    console.log('Updated week schedule:', weekSchedule);
};

function SpotDetails(props: SpotDetailsProps) {
    const { children, active, spot, ...other } = props;

    const [value, setValue] = useState(0);
    const handleChange = (event: React.SyntheticEvent, newValue: number) => {
        setValue(newValue);
    };

    const Item = styled(Paper)(({ theme }) => ({

        ...theme.typography.body2,
        textAlign: 'center',
    }));


    return (
        <>
            <Typography variant="h4" style={{ marginTop: "20vh", background: "-webkit-linear-gradient(90deg, #964cff, #bb2cc0)", WebkitBackgroundClip: "text", WebkitTextFillColor: "transparent", fontWeight: 600 }}>{spot.location.formal}</Typography>
            <Rating name="read-only" value={spot.rating?.average || 5} readOnly sx={{ marginTop: "6vh" }} /> /({spot.rating?.total || 0} reviews)
            <Grid item xs={12} container
                spacing={0}
                direction="column"
                alignItems="center"
                justifyContent="center">
                <Box sx={{ borderBottom: 1, borderColor: 'divider', width: "100%", marginTop: "20px", position: "flex" }}>
                    <Tabs value={value} onChange={handleChange} aria-label="basic tabs example" textColor="secondary"
                        indicatorColor="secondary" variant="fullWidth" sx={{ width: "100%" }}>
                        <Tab sx={{ width: "50%" }} label="Basic Information" {...a11yProps(0)} />
                        <Tab sx={{ width: "50%" }} label="Week Selection " {...a11yProps(1)} />
                        <Tab sx={{ width: "50%" }} label="Month Selection" {...a11yProps(2)} />
                        <Tab sx={{ width: "50%" }} label="Delegates" {...a11yProps(3)} />
                        <Tab sx={{ width: "50%" }} label="Reviews" {...a11yProps(4)} />
                    </Tabs>
                </Box>
                <TabPanel value={value} index={0}>
                    <Grid
                        item
                        lg={12}
                        md={12}
                        xs={12}
                        container
                        spacing={0}
                        direction="column"
                        alignItems="center"
                        justifyContent="center"
                        mt={4}
                        mb={4}>

                        <CardMedia
                            src={spot.photoUrl || backgroundImage}
                            component="img"
                            sx={{
                                maxWidth: "30%",
                                margin: 0,
                                objectFit: "cover",
                                objectPosition: "center",
                            }}
                        />

                    </Grid>
                    <Typography id="modal-desc">
                        <Grid
                            item
                            lg={12}
                            md={12}
                            xs={12}
                            container
                            spacing={2}
                            alignItems="center"
                            justifyContent="center"
                        >

                            <Grid item xs={12} sm={12} md={12} lg={6} xl={6} >
                                <Item sx={{ boxShadow: "none" }}>
                                    <FormGroup>
                                        <TextField
                                            required
                                            id="outlined-required"
                                            label="Address"
                                            value={spot.location.formal}
                                            disabled
                                            type="text"
                                            sx={{
                                                width: "100%",
                                                marginTop: { xs: "5vh", lg: "6%" },
                                                marginBottom: {
                                                    xs: "12%",
                                                    sm: "4%",
                                                    md: "4%",
                                                    lg: "4%",
                                                    xl: "4%",
                                                },
                                                color: "#000",
                                                "& .MuiOutlinedInput-root": {
                                                    color: "#000",
                                                },
                                                "& .MuiOutlinedInput-root.Mui-focused": {
                                                    "& > fieldset": {
                                                        borderColor: "#9753f7",
                                                    },
                                                },
                                            }}
                                        />
                                    </FormGroup>
                                </Item>
                            </Grid>

                            <Grid item xs={12} sm={12} md={12} lg={6} xl={6}>
                                <Item sx={{ boxShadow: "none" }}>
                                    <FormGroup>
                                        <TextField
                                            required
                                            id="outlined-required"
                                            label="Name"
                                            value={spot.name}
                                            disabled={!active}
                                            sx={{
                                                width: "100%",
                                                marginTop: { xs: "1vh", lg: "6%" },
                                                marginBottom: {
                                                    xs: "12%",
                                                    sm: "4%",
                                                },
                                                "& .MuiOutlinedInput-root.Mui-focused": {
                                                    "& > fieldset": {
                                                        borderColor: "#9753f7",
                                                    },
                                                },
                                            }}
                                        />
                                    </FormGroup>
                                </Item>
                            </Grid>

                            <Grid item xs={12} sm={12} md={12} lg={6} xl={6}>
                                <Item sx={{ boxShadow: "none" }}>
                                    <FormGroup>
                                        <TextField
                                            required
                                            id="outlined-required"
                                            label="Description"
                                            value={spot.description}
                                            disabled={!active}
                                            sx={{
                                                width: "100%",
                                                marginTop: "2%",
                                                marginBottom: "4%",
                                                "& .MuiOutlinedInput-root.Mui-focused": {
                                                    "& > fieldset": {
                                                        borderColor: "#9753f7",
                                                    },
                                                },
                                            }}
                                        />
                                    </FormGroup>
                                </Item>
                            </Grid>

                            <Grid item xs={12} sm={12} md={12} lg={6} xl={6}>
                                <Item sx={{ boxShadow: "none" }}>
                                    <FormControl
                                        fullWidth
                                        sx={{
                                            "& .MuiOutlinedInput-inputAdornedStart.MuiOutlinedInput-adornedStart .MuiOutlinedInput-root.Mui-focused":
                                            {
                                                borderColor: "#9753f7",
                                            },
                                        }}
                                    >
                                        <InputLabel
                                            htmlFor="outlined-adornment-amount"
                                            sx={{
                                                marginBottom: "4%",
                                                marginTop: { xs: "8%", sm: "4%", md: "4%", lg: "2%" },
                                            }}
                                        >
                                            Amount
                                        </InputLabel>
                                        <OutlinedInput
                                            id="outlined-adornment-amount"
                                            startAdornment={
                                                <InputAdornment position="start">
                                                    R
                                                </InputAdornment>
                                            }
                                            label="Amount"
                                            disabled={!active}
                                            value={spot.price}
                                            // onChange={(e) => {
                                            //     setSpotPrice(Number(e.target.value));
                                            // }}
                                            sx={{
                                                marginBottom: "4%",
                                                marginTop: { xs: "8%", sm: "4%", md: "4%", lg: "2%" },
                                                width: "100%",
                                                "& .MuiOutlinedInput-inputAdornedStart.MuiOutlinedInput-adornedStart .MuiOutlinedInput-root.Mui-focused":
                                                {
                                                    borderColor: "#9753f7",
                                                    color: "#000",
                                                },
                                            }}
                                        />
                                    </FormControl>
                                </Item>
                            </Grid>
                        </Grid>

                        <Grid
                            item
                            lg={12}
                            md={12}
                            xs={12}
                            container
                            spacing={2}
                            alignItems="center"
                            justifyContent="center" mt={2} mb={8}>

                            <Grid item xs={12} sm={12} md={12} lg={6} xl={6}>
                                <Item sx={{ boxShadow: "none" }}>
                                    <FormGroup>
                                        <FormControlLabel
                                            control={
                                                <Checkbox
                                                    disabled={!active}
                                                    // onChange={(e) => {
                                                    //     setWheelchair(e.target.checked);
                                                    // }}
                                                    value={spot.wheelchair}
                                                />
                                            }
                                            label="Wheelchair"
                                            sx={{ marginLeft: "0.5vw" }}
                                        />
                                    </FormGroup>
                                </Item>
                            </Grid>

                            <Grid item xs={12} sm={12} md={12} lg={6} xl={6} >
                                <Item sx={{ boxShadow: "none" }}>
                                    <FormGroup>
                                        <FormControlLabel
                                            control={
                                                <Checkbox
                                                    disabled={!active}
                                                // onChange={(e) => {
                                                //     setMultiSpot(e.target.checked);
                                                // }}
                                                />
                                            }
                                            label="Multiple Parkings"
                                            sx={{
                                                marginLeft: {
                                                    xs: "0.5vw",
                                                    sm: "0.5vw",
                                                    md: "0.5vw",
                                                    lg: "-2%",
                                                    xl: "-2%",
                                                },
                                            }}
                                        />
                                    </FormGroup>
                                </Item>
                            </Grid>
                        </Grid>


                    </Typography>
                </TabPanel>
                {/* Signup */}
                <TabPanel value={value} index={1}>
                    <WeekScheduleSelector onWeekScheduleChange={handleWeekScheduleChange} />
                </TabPanel>
                <TabPanel value={value} index={2}>
                    <DateRangeSelector onDateRangeChange={handleDateRangeChange} ></DateRangeSelector>
                </TabPanel>
                {/* Deletagte Pane; */}
                <TabPanel value={value} index={3}>
                    <UserList spot={spot}></UserList>
                </TabPanel>
                <TabPanel value={value} index={4}>
                    <p style={{ fontSize: "1.2rem", marginBottom: "6vh" }}>
                        You currently have no reviews...
                    </p>

                    <div>
                        {reviews.map((review) => (
                            <div key={review.id} style={{ marginBottom: '10px' }}>
                                <div style={{ display: 'flex', marginBottom: "6vh", alignItems: "baseline" }}>
                                    <Avatar sx={{ width: 50, height: 50, marginRight: '10px', backgroundColor: '#ccc' }}>
                                        {review.user.charAt(0)}
                                    </Avatar>
                                    <div>
                                    <Box sx={{ display: 'flex', alignItems: 'center' }}>
                                        <Typography variant="subtitle1" sx={{fontSize: "1.2rem"}}>{review.user} {Array.from(Array(review.rating)).map((_, index) => (
                                                <svg key={index} xmlns="http://www.w3.org/2000/svg" width="20" height="20" viewBox="0 0 24 24">
                                                    <path fill="#FFD700" d="M12 17.27l-5.41 3.27 1.32-6.08L2.83 9.64l6.14-.53L12 3l2.03 5.11 6.14.53-4.08 3.82 1.32 6.08z" />
                                                </svg>
                                            ))}</Typography>

                                       
                                    </Box>
                                    <p className="review" style={{marginTop: "3vh", fontSize: "1.1rem"}}>{review.comment}</p>
                                    </div>
                                </div>
                               
                            </div>
                        ))}
                    </div>
                </TabPanel>


            </Grid>
        </>
    )

}


export function Spot() {

    const user = useContext(AuthContext)
    const location = useLocation();
    console.log("In spot:", location.state.spot)
    console.log("In rating:", location.state.spot.rating?.average)
    const [isLoaded, setIsLoaded] = useState(false)
    const [spot, setSpot] = useState<any>({})

    useEffect(() => {
        const getData = async () => {
            setSpot(location.state.spot)
            setIsLoaded(true)
        }
        getData()
    }, [location.state.spot])

    return (
        <>

            {isLoaded ? (
                <>
                    {spot.admin.state !== "active" ? (
                        <>
                            {spot.admin.state === "pending" ? (
                                // <Watermark text="Pending Approval" textColor='purple' textSize={30} lineHeight="4rem" gutter={5}>
                                <SpotDetails active={false} spot={spot} />
                                // </Watermark>
                            ) : (<>
                            </>)}
                        </>
                    ) : (<>
                        <SpotDetails active={true} spot={spot} />
                    </>)}
                </>)
                : (
                    <Grid
                        container
                        spacing={2}
                        style={{ minHeight: "50vh", paddingBottom: "20px" }}
                        direction="column"
                        alignItems="center"
                        justifyContent="center"
                    >
                        <Grid item lg={12} md={12} xs={12}>
                            <BounceLoader color="#9753f7" cssOverride={{}} loading size={100} />
                        </Grid>
                    </Grid>


                )
            }
        </>


    );
}


