// components/Home.js
import { useEffect, useState } from 'react';
import Grid from '@mui/material/Grid';
import mockLogo from "../../../src/assets/img/home/Mockup.webp";
import laodingLogo from '../../../src/assets/img/about/loadingLogo.webp'
import { Box } from '@mui/material';
import Typography from '@mui/material/Typography';

export function About() {
  const [isLoaded, setIsLoaded] = useState(false)


  useEffect(() => {
    const getData = async () => {



    }
    getData()
  }, [])


  return (
    <>
    <Grid container sx={{padding: "4vh 7vw"}} mt={6}>
      <Grid container sx={{display: "flex", justifyContent: "space-around"}}>
          <Grid className='aboutContainer' item xs={12} md={4} lg={5} container
              direction="column"
           >
            <Typography variant="h1" sx={{  fontSize: "2.5rem", background: "-webkit-linear-gradient(90deg, #964cff, #bb2cc0)", WebkitBackgroundClip: "text", WebkitTextFillColor: "transparent", marginBottom: "2vh", fontWeight: 500, display: "flex", justifyContent: {xs: "center", lg: "flex-start"} }}>About Us</Typography>
           
            <Typography variant="body1" sx={{fontSize: "1.1rem", textAlign: "left", width: {xs: "100%", lg:"80%"}, marginBottom: "6vh"}}> ParkShare Technologies is a Cape Town based parking provider through the ParkShare mobile application. ParkShare allows users to pre-book parking spots as well as rent out unused parking spots as hosts. </Typography>
          </Grid>
          
          <Grid item lg={7} md={6} xs={12}
           alignItems="center"
            justifyContent="center">
            <Box
                    component="img"
                    sx={{
                      
                      width: "100%"
                    }}
                    alt="A person using the parkshare app"
                    src={laodingLogo}
                  />
          </Grid>
      </Grid>
        
        <Grid container sx={{display: "flex", justifyContent: "space-around", marginTop: {xs: "6vh", lg: "16vh"}}}>
          <Grid item md={6} xs={12}
            alignItems="center"
              justifyContent="center">
              <Box
                      component="img"
                      sx={{
                        width: "100%",
                        height: {xs: "90%",lg: "75%"}
                      }}
                      alt="A person using the parkshare app"
                      src={mockLogo}
                    />
            </Grid>

            <Grid item xs={12} md={6}  container
                
                  direction="column"
                  alignItems="center"
                  justifyContent="center">
                <h1 style={{ fontSize: "2.5rem", background: "-webkit-linear-gradient(90deg, #964cff, #bb2cc0)", WebkitBackgroundClip: "text", WebkitTextFillColor: "transparent"}}>Our Story</h1>
                <Typography variant="body1" sx={{ textAlign: "left", fontSize: "1.1em", marginTop: "6vh", width: {xs: "100%", lg:"80%"} }}>ParkShare aims to ease the parking problem in congested metro areas while simultaneously creating an opportunity for hosts 
                  to earn passive income by renting out their available parking spots.

                  <br /><br /><br/>

                  Our long-term goals for ParkShare is to become the global industry leader in “e-parking”, to create an opportunity for individuals and companies alike to utilise
                  unused parking space and profit from it.

                  <br /><br /><br/>

                  ParkShare is proud to be a part of South Africa’s thriving start-up and tech market. 
                  We hope to support the rental car industry and place less strain on roadside parking in CBDs and popular tourist areas.

                  <br /><br /><br/>

                  It also has the potential for commuters to drive to feeder routes of the MyCiti busses in Cape Town park nearby
                  and then utilize public transport in and out of the CBD.

                  <br /><br /><br/>

                  We’re a young and talented group with a groundbreaking idea designed to contribute towards a better tomorrow. 
                  We provide smart solutions for users and pride ourselves on our unparalleled, dedicated service.

                </Typography>
                <br /><br />
              </Grid>
        </Grid>
    </Grid>
      
    </>


  );
}
