

// prop-types is a library for typechecking of props
import PropTypes from "prop-types";

// @mui material components
import Icon from "@mui/material/Icon";

// Material Dashboard 2 React components
import Box from "@mui/material/Box";
import Typography from "@mui/material/Typography";
import Button from "@mui/material/Button";
import ExpandLessIcon from '@material-ui/icons/ExpandLess';
import ExpandMoreIcon from '@material-ui/icons/ExpandMore';

function Transaction({ color, icon, name, description, value }: any) {
  return (
    <Box key={name} component="li" py={1} pr={2} mb={1}>
      <Box display="flex" justifyContent="space-between" alignItems="center">
        <Box display="flex" alignItems="center">
          <Box mr={2}>
            <Button variant="outlined" color={color} style={{
              width: 32,
              height: 32,
              minWidth: 0,
              borderRadius: '50%',
              display: 'flex',
              justifyContent: 'center',
              alignItems: 'center',
              p: 0,
              ...(color && { backgroundColor: color }),
            }}>
            {icon === 'expand_less' ? (
              <ExpandLessIcon style={{ fontWeight: 'bold' }} />
            ) : icon === 'expand_more' ? (
              <ExpandMoreIcon style={{ fontWeight: 'bold' }} />
            ) : null}
            </Button>
          </Box>
          <Box display="flex" flexDirection="column" sx={{marginTop: "3vh"}}>
            <Typography variant="button" fontWeight="medium" gutterBottom>
              {name}
            </Typography>
            <Typography variant="caption" color="text" fontWeight="regular">
              {description}
            </Typography>
          </Box>
        </Box>
        <Typography
        variant="button"
        color={color}
        fontWeight="medium"
        sx={{
          backgroundImage: `linear-gradient(to right, ${color} 0%, ${color} 50%, transparent 50%)`,
          backgroundClip: 'text',
          WebkitBackgroundClip: 'text',
          color: "inherit", // Use the inherited
        }}
      >
        {value}
      </Typography>

      </Box>
    </Box>
  );
}
// Typechecking props of the Transaction
Transaction.propTypes = {
  color: PropTypes.oneOf([
    "primary",
    "secondary",
    "info",
    "success",
    "warning",
    "error",
    "light",
    "dark",
  ]).isRequired,
  icon: PropTypes.node.isRequired,
  name: PropTypes.string.isRequired,
  description: PropTypes.string.isRequired,
  value: PropTypes.string.isRequired,
};

export default Transaction;
