import "./Footer.css";
import { styled } from "@mui/material/styles";
import Paper from "@mui/material/Paper";
import Grid from "@mui/material/Grid";
import Button from "@mui/material/Button";
import { NavLink } from "react-router-dom";
import logo from "../../assets/img/LOGOPS.png";
import { Box } from "@mui/material";
import { Typography } from "@mui/material";

const Item = styled(Paper)(({ theme }) => ({
  backgroundColor: theme.palette.mode === "dark" ? "#1A2027" : "#fff",
  ...theme.typography.body2,
  textAlign: "center",
  color: theme.palette.text.secondary,
}));

export function Footer() {
  return (
    <>
      <footer
        style={{
          color: "gray",
          width: "100%",
          minHeight: "19vh",
          backgroundColor: "#c7d3e277",
          margin: 0,
          padding: 3,
        }}
      >
        <Grid
          container
          spacing={2}
          style={{ marginBottom: "0px", position: "relative" }}
        >
          <Grid
            item
            lg={6}
            md={6}
            xs={12}
            alignItems="center"
            justifyContent="center"
          >
            <Box
              component="img"
              sx={{
                width: { xs: "60%", sm: "25%", md: "40%", lg: "40%" },
                marginTop: "6vh"
              }}
              alt="Parkshare logo"
              src={logo}
            />
          </Grid>

          <Grid
            item
            lg={6}
            md={6}
            xs={12}
            container
            spacing={0}
            alignItems="center"
            justifyContent="center"
            sx={{
              width: "100%",
              display: "flex",
              flexDirection: { xs: "column", sm: "column", md: "row", lg: "row", xl: "row" },
              paddingTop: 0
            }}
          >
             <NavLink
              className="dd"
              to="/terms-conditions"
              style={{
                marginLeft: "3vw",
                marginRight: "3vw",
                color: "#964cff",
                textDecoration: "none",
                fontSize: "1.1rem",
                marginTop: "3vh",
              }}
            >
              Terms & Conditions
            </NavLink>

            <NavLink
              className="dd"
              to="/privacy"
              style={{
                marginLeft: "3vw",
                marginRight: "3vw",
                color: "#964cff",
                textDecoration: "none",
                fontSize: "1.1rem",
                marginTop: "3vh",
              }}
            >
              Privacy
            </NavLink>

          

            <NavLink
              className="dd"
              to="/faq"
              style={{
                marginLeft: "3vw",
                marginRight: "3vw",
                color: "#964cff",
                textDecoration: "none",
                fontSize: "1.1rem",
                marginTop: "3vh",
              }}
            >
              FAQ
            </NavLink>
          </Grid>

          <Grid
            item
            lg={12}
            md={12}
            xs={12}
            alignItems="center"
            justifyContent="flex-end"
          >
            <Typography
              sx={{
                color: "grey",
                float: "right",
                marginRight: "28vw",
                marginTop: {xs: "1vh", lg: "-4vh"},
                fontSize: "11px",
                textDecoration: "none",
              
              }}
            >
              <a href="https://pyrrhotech.com"><div className="copyright">
                © 2023 created by Pyrrho Tech
              </div></a>
            </Typography>
          </Grid>
        </Grid>
      </footer>
    </>
  );
}
