

// @mui material components
import Card from "@mui/material/Card";

// Material Dashboard 2 React components
import Box from "@mui/material/Box";
import Typography from "@mui/material/Typography";

// Billing page components
import Bill from "../Bill";

function BillingInformation() {
  return (
    <Card id="delete-account" sx={{ boxShadow: "0 20px 25px -5px rgba(0, 0, 0, 0.2), 0 10px 10px -5px rgba(0, 0, 0, 0.2) !important", marginBottom: "6vh"}}>
      <Box pt={3} px={2}>
        <Typography variant="h6" fontWeight="medium" sx={{
          background: "-webkit-linear-gradient(90deg, #964cff, #bb2cc0)",
          backgroundClip: "text", textFillColor: "transparent", fontSize: "1.4rem"
        }}>
          Host Billing Information
        </Typography>
      </Box>
      <Box pt={1} pb={2} px={2}>
        <Box component="ul" display="flex" flexDirection="column" p={0} m={0} sx={{fontSize: "1.5rem"}}>
          <Bill
            name="oliver liam"
            company="viking burrito"
            email="oliver@burrito.com"
            vat="FRB1235476"
          />
        </Box>
      </Box>
    </Card>
  );
}

export default BillingInformation;
