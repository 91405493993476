// components/Protected.js
import { BounceLoader } from 'react-spinners';
import { useContext, useEffect, useState } from 'react';
import { AuthContext } from '../../context/AuthContext';
import { Avatar, Divider, Grid, Tab, Tabs } from '@mui/material';
import Typography from '@mui/material/Typography';
import { FireStoreUserContext } from '../../context/FireStoreUserContext';
import Box from '@mui/material/Box';
import PaymentMethod from "../../layout/billing/components/PaymentMethod";
import Invoices from "../../layout/billing/components/Invoices";
import BillingInformation from "../../layout/billing/components/BillingInformation";
import Transactions from "../../layout/billing/components/Transactions";
import MasterCard from '../../examples/Cards/MasterCard';
import DefaultInfoCard from '../../examples/Cards/InfoCards/DefaultInfoCard';

interface TabPanelProps {
  children?: React.ReactNode;
  index: number;
  value: number;
}

function TabPanel(props: TabPanelProps) {
  const { children, value, index, ...other } = props;

  return (
    <div
      role="tabpanel"
      hidden={value !== index}
      id={`vertical-tabpanel-${index}`}
      aria-labelledby={`vertical-tab-${index}`}
      {...other}
    >
      {value === index && (
        <Box sx={{ p: 3 }}>
          <Typography>{children}</Typography>
        </Box>
      )}
    </div>
  );
}

function a11yProps(index: number) {
  return {
    id: `simple-tab-${index}`,
    'aria-controls': `simple-tabpanel-${index}`,
  };
}

export function Billing() {

  const user = useContext(AuthContext)
  const { parkshareUser, setParkshareUser } = useContext(FireStoreUserContext)

  const [isLoaded, setIsLoaded] = useState(false)
  const [notificationID, setNotificationID] = useState("")
  const [cardList, setCardList] = useState([])
  const [expanded, setExpanded] = useState<string | false>(false);
  const [card, setCard] = useState({
    expiry: '',
    name: '',
    number: '',
    card_type: '',
  })
  const [value, setValue] = useState(0);
  const [isHost, setIsHost] = useState(true);
  const [hasCards, setHasCards] = useState(false);

  const handleChange = (event: React.SyntheticEvent, newValue: number) => {
    setValue(newValue);
  };



  useEffect(() => {
    const getData = async () => {
      console.log("Context:", parkshareUser)
      if ("payment_methods" in parkshareUser) {
        console.log("Found cards")
        console.log(parkshareUser.payment_methods)
        setCard({
          expiry: `${parkshareUser.payment_methods[0].exp_month}/${parkshareUser.payment_methods[0].exp_year}`,
          name: 'Test User',
          number: `${parkshareUser.payment_methods[0].bin}XXXXXX${parkshareUser.payment_methods[0].last4}`,
          card_type: parkshareUser.payment_methods[0].brand,
        })
        setCardList(parkshareUser.payment_methods)
        setHasCards(true)
      } else {
        console.log("No methods")

        setHasCards(false)
      }
      setIsLoaded(true)
    }
    getData()
  }, [])


  return (
    <>
      {isLoaded ? (
        <>
          <Grid xs={12} item direction="column" sx={{ display: "flex", alignItems: "center", justifyContent: "center" }}>
            <Grid item xs={10} sx={{ borderBottom: 1, borderColor: 'divider', width: "100%", marginTop: "20px", display: "flex", justifyContent: "center" }}>

              <Tabs value={value} onChange={handleChange} aria-label="basic tabs example" textColor="secondary" indicatorColor="secondary" variant="fullWidth" sx={{ width: "100%" }}>

                {isHost ? (
                  <Tab sx={{ width: "50%", padding: 0, margin: 0 }} label="User Information" {...a11yProps(0)} />
                ) : (
                  <Tab sx={{ width: "50%" }} label="User Information" {...a11yProps(0)} disabled />
                )}

                {isHost && <Tab sx={{ width: "50%" }} label="Host" {...a11yProps(1)} />}
              </Tabs>
            </Grid>

            {/* User Information Tab */}
            <TabPanel value={value} index={0}>
              <Grid xs={12} container sx={{ display: "flex", justifyContent: "center" }}>
                {/* Main Card */}
                {hasCards && (
                  <Grid item xs={12} sm={6} xl={6} lg={7} mb={6}>
                    <MasterCard number={card.number} holder={card.name} expires={card.expiry} type={card.card_type} />c

                  </Grid>
                )}
                {/* Card list */}
                <Grid item xs={12} md={6} lg={5} mb={5}>
                  <PaymentMethod cards={cardList} />
                </Grid>
                
                <Grid item xs={12} sx={{marginBottom: "8vh"}}>
                  <Transactions />
                </Grid>
              </Grid>
            </TabPanel>

            {/* Host Tab */}
            {isHost && (
              <TabPanel value={value} index={1}>
                <Grid item xs={12} container spacing={2}>
                  <Grid item xs={12} md={12} xl={9}>
                    <BillingInformation />
                  </Grid>
                  <Grid item xs={12} md={12} xl={3}>
                    <DefaultInfoCard
                      icon="account_balance"
                      title="host income"
                      description="Your income as a host"
                      value="+$0"
                    />
                  </Grid>
                  <Grid item xs={12} md={8}>
                    <Transactions />
                  </Grid>
                  <Grid item xs={12} lg={4}>
                    <Invoices />
                  </Grid>
                </Grid>
              </TabPanel>
            )}
          </Grid>
        </>
      ) : (
        <div style={{ width: '100%', height: '70vh', alignContent: 'center', textAlign: 'center', justifyContent: 'center', display: 'flex' }}>
          <div style={{ marginTop: '25vh' }}><BounceLoader color="#9753f7" cssOverride={{}} loading size={100} /></div>
        </div>
      )}
    </>
  );
}
