import WhatsAppIcon from '@mui/icons-material/WhatsApp';
import MailOutlineIcon from '@mui/icons-material/MailOutline';
import LocationOnIconOutlined from '@mui/icons-material/LocationOnOutlined';
import ThumbUpOffAltIcon from '@mui/icons-material/ThumbUpOffAlt';
import FacebookIcon from '@mui/icons-material/Facebook';
import InstagramIcon from '@mui/icons-material/Instagram';
import Maps from '../../components/maps/Maps';
import { BounceLoader } from 'react-spinners';
import { useEffect, useState } from 'react';
import { Button, Divider, Grid, IconButton, TextField } from '@mui/material';
import { useFormControls } from './contactFormControls';
import "./contactUs.css";
import Typography from '@mui/material/Typography';

export function ContactUs() {
  const [isLoaded, setIsLoaded] = useState(false);

  useEffect(() => {
    const getData = async () => {
      setIsLoaded(true);
    };
    getData();
  }, []);

  const inputFieldValues = [
    {
      name: "fullName",
      label: "Full Name",
      id: "my-name",
      width: "50%",
      padding: "5%",
    },
    {
      name: "lastName",
      label: "Last Name",
      id: "my-lastname",
      width: "50%",
    },
    {
      name: "email",
      label: "Email",
      id: "my-email",
      width: "100%",
    },
    {
      name: "title",
      label: "Title",
      id: "my-title",
      width: "100%",
    },
    {
      name: "message",
      label: "Message",
      id: "my-message",
      width: "100%",
      multiline: true,
      rows: 4,
    },
  ];

  const {
    handleInputValue,
    handleFormSubmit,
    formIsValid,
    errors,
  } = useFormControls();

  return (
    <>
      {isLoaded ? (
        <>
          <Grid container sx={{padding: "2vh 2vw"}}>
            <Grid container sx={{margin: "6vh 3vw 2vh 3vw", display: "flex", justifyContent: "space-around", alignItems: "start"}}>
              <Grid container>
              <Typography variant="h1" sx={{ fontSize: "4rem", marginLeft: "4vw", fontWeight: 600, background: "-webkit-linear-gradient(90deg, #964cff, #bb2cc0)", WebkitBackgroundClip: "text", WebkitTextFillColor: "transparent" }}>Contact</Typography>
              </Grid>
         

              <Grid container lg={5} md={6} xs={12} style={{ paddingTop: "0vh", display: "flex", alignItems: "center" }}>

                <Grid item xs={12} sx={{ textAlign: "left", display: "flex", justifyContent: "center", margin: "8vh auto 3vh auto" }}>
                  <Grid item xs={2} sx={{color: "#964cff"}}><LocationOnIconOutlined /></Grid>
                  <Grid item xs={8}><a href="mailto:info@mysite.com">Woodstock Quarter, 187 Sir Lowry Rd, Woodstock, Cape Town, 7915</a></Grid>
                </Grid>
                
                {/* Email */}
                <Grid className="email" item xs={12} sx={{ textAlign: "left", display: "flex", justifyContent: "center", margin: "3vh auto" }}>
                  <Grid item xs={2} sx={{color: "#964cff"}}><MailOutlineIcon /></Grid>
                  <Grid item xs={8}><a href="mailto:info@mysite.com">support@parkshare.co.za</a></Grid>
                </Grid>

                {/* Whatsapp */}
                <Grid item xs={12} sx={{ textAlign: "left", display: "flex", justifyContent: "center", margin: "3vh auto" }}>
                  <Grid item xs={2}  sx={{color: "#964cff"}}><WhatsAppIcon /></Grid>
                  <Grid item xs={8}><a href="https://wa.me/27818212245">(081) 821-2245</a></Grid>
                </Grid>

                {/* Social */}
                <Grid item xs={12}sx={{ textAlign: "left", display: "flex", justifyContent: "center",  margin: "3vh auto" }}>
                  <Grid item xs={2} mt={1}><ThumbUpOffAltIcon  sx={{color: "#964cff"}}/></Grid>
                  <Grid item xs={8}>
                    <IconButton aria-label="Facebook.com" onClick={() => window.open('https://www.facebook.com/parkshare.tech/')}  sx={{color: "#964cff"}}>
                      <FacebookIcon />
                    </IconButton>

                    <IconButton
                      aria-label="Instagram.com"
                      onClick={() =>
                        window.open("https://www.instagram.com/parkshare_app/?hl=en")
                      }
                      sx={{ color: "#964cff" }}
                    >
                      <InstagramIcon />
                    </IconButton>
                  </Grid>
                </Grid>
              </Grid>
              {/* Form */}
              <Grid
                item
                lg={6}
                md={6}
                xs={12}
                sx={{
                  marginTop: "6vh",
                  paddingLeft: "3vw",
                  paddingRight: "3vw",
                }}
              >
                <form autoComplete="off" onSubmit={handleFormSubmit}>
                  {inputFieldValues.map((inputFieldValue, index) => {
                    return (
                      <TextField
                        key={index}
                        onChange={handleInputValue}
                        onBlur={handleInputValue}
                        name={inputFieldValue.name}
                        label={inputFieldValue.label}
                        error={errors[inputFieldValue.name]}
                        multiline={inputFieldValue.multiline ?? false}
                        style={{
                          width: inputFieldValue.width,
                          paddingBottom: "5%",
                          paddingRight: inputFieldValue.padding,
                          marginBottom: "2vh",
                        }}
                        rows={inputFieldValue.rows ?? 1}
                        autoComplete="none"
                        {...(errors[inputFieldValue.name] && {
                          error: true,
                          helperText: errors[inputFieldValue.name],
                        })}
                      />
                    );
                  })}
                  <div style={{ width: "100%", textAlign: "right" }}>
                    <Button
                      variant="contained"
                      type="submit"
                      color="secondary"
                      disabled={!formIsValid()}
                      sx={{
                        backgroundColor: "#9753f7",
                        color: "white",
                        marginRight: "5px",
                      }}
                    >
                      Send Message
                    </Button>
                  </div>
                </form>
              </Grid>
            </Grid>

          </Grid>

          <Grid item sx={{ minHeight: "50vh", margin: {xs: "15vh auto", sm: "6vh auto", lg: "15vh auto 10vh auto"}, border: "3px solid #964cff", borderRadius: "0.2vw", padding: "1vh", width: {xs: "90%", lg: "82%"} }}>
              <Maps lng={18.408380} lat={-33.907630} heigh={"50vh"} />
            </Grid>
          <Grid></Grid>
        </>
      ) : (
        <div
          style={{
            width: "100%",
            height: "70vh",
            display: "flex",
            justifyContent: "center",
            alignItems: "center",
          }}
        >
          <BounceLoader color="#9753f7" cssOverride={{}} loading size={100} />
        </div>
      )}
    </>
  );
}
