

// prop-types is a library for typechecking of props
import PropTypes from "prop-types";

// @mui material components
import Card from "@mui/material/Card";
import Icon from "@mui/material/Icon";

// Material Dashboard 2 React components
import Box from "@mui/material/Box";
import Typography from "@mui/material/Typography";

// Images
import pattern from "../../../assets/img/bankLogo/pattern-tree.svg";
import logo from "../../../assets/img/logoCropped.png";
import masterCardLogo from "../../../assets/images/logos/mastercard.png";
import visaLogo from "../../../assets/images/logos/visa.png";
import americanExpress from "../../../assets/images/logos/americanexpress.jpeg";

function MasterCard({ color, number, holder, expires, type}) {
  const numbers = [...`${number}`];

  if (numbers.length < 16 || numbers.length > 16) {
    throw new Error(
      "Invalid value for the prop number, the value for the number prop shouldn't be greater than or less than 16 digits"
    );
  }

  const num1 = numbers.slice(0, 4).join("");
  const num2 = numbers.slice(4, 8).join("");
  const num3 = numbers.slice(8, 12).join("");
  const num4 = numbers.slice(12, 16).join("");

  function linearGradient(color, colorState, angle = 195) {
    return `linear-gradient(${angle}deg, ${color}, ${colorState})`;
  }

  return (
    <Card
      sx={{
        background: `black`,
        position: "relative",
        width: {xs: "100%", lg: "80%"},
        borderRadius: "0.6vw",
        boxShadow: "0 20px 25px -5px rgba(0, 0, 0, 0.4), 0 10px 10px -5px rgba(0, 0, 0, 0.3) !important",
        display: 'flex',
        justifyContent: "center",
        margin: "4vh auto",
        padding: {xs: "2vh 28vw", lg: "initial"}
      }}
    >
      <Box
        position="absolute"
        top={0}
        left={0}
        width="100%"
        height="100%"
       
        sx={{
          backgroundImage: `url(${pattern})`,
          backgroundSize: "cover",
          opacity: "0.2 !important"
        }}>
        <span class="mask bg-gradient-dark opacity-10" style={{opacity: "0.8", boxShadow: "0 3px 3px 0 rgba(52, 71, 103, 0.15), 0 3px 1px -2px rgba(52, 71, 103, 0.2), 0 1px 5px 0 rgba(52, 71, 103, 0.15)" }}></span></Box>
        
      <Box position="relative" zIndex={2} p={2}>
        <Box color="white" p={1} lineHeight={0} display="inline-block" sx={{display: "flex", justifyContent: "center", flexDirection: "row"}}>
          <Typography variant="h4" sx={{ ml: 0, mr: {xs: 0, lg: 30}, pl: {xs: 0, lg: 2}, textAlign: "left", background: "-webkit-linear-gradient(90deg, #964cff, #bb2cc0)", WebkitBackgroundClip: "text", WebkitTextFillColor: "transparent"}}> ParkShare </Typography>
          <Box component={"img"} src={logo} sx={{width: {xs: "20%", lg: "10%"}, ml: {xs: "15%", lg: "initial"}, objectFit: "contain"}} />
        </Box>

        <Box sx={{display: "flex", flexDirection: "row", justifyContent: "center", alignItems: "center"}}>
          <Typography variant="h5" color="white" fontWeight="medium" sx={{ mt: 3, mb: 5, pb: 1, textAlign: "left", pl: {xs: 0, lg: 2}, ml: {xs: "2%", lg: "initial"}, mr: {xs: 0, lg: 30} }}>
            {num1}{num2}{num3}{num4}
          </Typography>
          <Typography variant="h5" color="white" sx={{mt: 3, mb: 5, ml: {xs: "15%", lg: "initial"} }}><span class="material-symbols-rounded">wifi</span></Typography>
        </Box>
        
        <Box display="flex" justifyContent="space-between" alignItems="center">
          <Box display="flex" alignItems="center">
            <Box lineHeight={1} sx={{textAlign: "left", pl: {xs: 0, lg: 2}, marginTop: {xs: "6vh", lg: "initial"}, mr: {xs: 2, lg: 3} }}>
              <Typography variant="button" color="white" opacity={0.8}>
                Card Holder
              </Typography>
              <Typography
                variant="h6"
                color="white"
                fontWeight="medium"
                textTransform="capitalize"
              >
                {holder}
              </Typography>
            </Box>
            
            <Box lineHeight={1} sx={{ marginTop: {xs: "6vh", lg: "initial"} }}>
              <Typography variant="button" color="white" fontWeight="regular" opacity={0.8}>
                Expires
              </Typography>
              <Typography variant="h6" color="white" fontWeight="medium">
                {expires}
              </Typography>
            </Box>
          </Box>

          <Box display="flex" justifyContent="flex-end" width="20%">
            <Box component="img" src={type  === "master" ? masterCardLogo  : type  === "visa" ? visaLogo  : americanExpress} alt="master card" width="60%" sx={{marginTop: {xs: "-15vh", lg: 1}, objectFit: "contain" }} />
          </Box>
        </Box>
      </Box>
    </Card>
  );
}

// Setting default values for the props of MasterCard
MasterCard.defaultProps = {
  color: "dark",
};

// Typechecking props for the MasterCard
MasterCard.propTypes = {
  color: PropTypes.oneOf(["primary", "secondary", "info", "success", "warning", "error", "dark"]),
  number: PropTypes.string.isRequired,
  holder: PropTypes.string.isRequired,
  expires: PropTypes.string.isRequired,
  type: PropTypes.string.isRequired,
};

export default MasterCard;
