import React, { useState } from 'react';
import { DatePicker, Button } from 'antd';
import dayjs, { Dayjs } from 'dayjs';
import { Grid } from '@mui/material';

interface DateRangeSelectorProps {
  onDateRangeChange: (dateRanges: [Dayjs | null, Dayjs | null][]) => void;
}

const DateRangeSelector: React.FC<DateRangeSelectorProps> = ({ onDateRangeChange }) => {
  const [dateRanges, setDateRanges] = useState<[Dayjs | null, Dayjs | null][]>([]);

  const handleDateRangeChange = (index: number, newValue: [Dayjs | null, Dayjs | null] | null) => {
    const updatedDateRanges = [...dateRanges];
    updatedDateRanges[index] = newValue || [null, null];
    setDateRanges(updatedDateRanges);
  };

  const handleApply = () => {
    onDateRangeChange(dateRanges);
  };

  const handleAddRange = () => {
    setDateRanges([...dateRanges, [null, null]]);
  };

  return (
    <Grid container spacing={2} justifyContent="flex-start" alignItems="center">
      {dateRanges.map((dateRange, index) => (
        <Grid item xs={12} key={index}>
          <DatePicker.RangePicker
            value={dateRange}
            onChange={(newValue) => handleDateRangeChange(index, newValue)}
            style={{ width: '100%' }}
          />
        </Grid>
      ))}
      <Grid item xs={12}>
        <Button onClick={handleAddRange} style={{ backgroundColor: '#c7d3e277', marginBottom: '2vh', border: '0.1vw solid #c7d3e277', color: '#000' }}>
          Add Range
        </Button>
      </Grid>
      {dateRanges.length > 0 && (
        <Grid item xs={12}>
          <Button onClick={handleApply} style={{ backgroundColor: '#964cff', color: '#fff', border: '0.1vw solid #964cff', width: '6vw' }}>
            Apply
          </Button>
        </Grid>
      )}
    </Grid>
  );
};

export default DateRangeSelector;
