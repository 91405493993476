import { useEffect, useState, useContext } from "react";
import { AuthContext } from "../../context/AuthContext";
import firebase from 'firebase/compat/app';
import { auth } from "../../firebase";
import { FireStoreUserContext } from "../../context/FireStoreUserContext";
import { UserHelper } from "../../data/firestore/user";

interface Props {
    children: React.ReactNode;
  }

export const AuthProvider: React.FC<Props> = ({ children }) => {
  const [user, setUser] = useState<firebase.User | null>(null);
  const {parkshareUser, setParkshareUser, setCredsCheckDone} = useContext(FireStoreUserContext)

  useEffect(() => {
    const unsubscribe = auth.onAuthStateChanged((firebaseUser:any) => {
      console.log("IN provider")
      setUser(firebaseUser);
      console.log(firebaseUser)
     if (firebaseUser !== null) {
      const userHelper = new UserHelper()
      userHelper.getUser(firebaseUser!.uid).then(async (data) => {
        console.log("User:",data)
        setParkshareUser(data)
      }).catch(resp => {
        console.log(resp)
      })
      console.log(parkshareUser)
      console.log("Set check done")
     } 
      
      setCredsCheckDone(true)
    });

    return unsubscribe;
  }, []);

  return <AuthContext.Provider value={user}>{children}</AuthContext.Provider>;
};