import { useContext, useState } from "react";

// @mui material components
import Card from "@mui/material/Card";
import Switch from "@mui/material/Switch";

// Material Dashboard 2 React components
import Box from "@mui/material/Box";
import Typography from "@mui/material/Typography";
import { FireStoreUserContext } from "../../../../context/FireStoreUserContext";

function PlatformSettings() {
  const [followsMe, setFollowsMe] = useState(true);
  const [newLaunches, setNewLaunches] = useState(false);
  const [productUpdate, setProductUpdate] = useState(true);
  const [newsletter, setNewsletter] = useState(false);

  const { parkshareUser } = useContext(FireStoreUserContext)

  return (
    <Card sx={{ boxShadow: "none", display: "flex", justifyContent: "center", alignItems: "center", flexDirection: "column" }}>
      <Box p={2}>
        <Typography variant="h6" fontWeight="medium" textTransform="capitalize" sx={{fontSize: "1.5rem",  background: "-webkit-linear-gradient(90deg, #964cff, #bb2cc0)",
          backgroundClip: "text", textFillColor: "transparent"}}>
           Settings
        </Typography>
      </Box>

      <Box pt={1} pb={2} px={2} lineHeight={1.25}>
        <Typography variant="caption"  color="text" textTransform="uppercase" sx={{fontSize: "1.2rem", fontWeight: 500}}>
          account
        </Typography>

        <Box display="flex" alignItems="center" mb={0.5} ml={-1.5}>
          <Box mt={0.5}>
            <Switch checked={followsMe} onChange={() => setFollowsMe(!followsMe)} />
          </Box>
          <Box width="80%" ml={0.5}>
            <Typography variant="button" fontWeight="regular" color="text">
              Let me know when someone sends me a message
            </Typography>
          </Box>
        </Box>
        <Box mt={3}>
          <Typography variant="caption" color="text" textTransform="uppercase" sx={{fontSize: "1.2rem", fontWeight: 500, marginBottom: "6vh"}}>
            General
          </Typography>
        </Box>
        <Box display="flex" alignItems="center" mb={0.5} ml={-1.5}>
          <Box mt={0.5}>
            <Switch checked={newLaunches} onChange={() => setNewLaunches(!newLaunches)} />
          </Box>
          <Box width="80%" ml={0.5}>
            <Typography variant="button" fontWeight="regular" color="text">
              System updates.
            </Typography>
          </Box>
        </Box>
        <Box display="flex" alignItems="center" mb={0.5} ml={-1.5}>
          <Box mt={0.5}>
            <Switch checked={newsletter} onChange={() => setNewsletter(!newsletter)} />
          </Box>
          <Box width="80%" ml={0.5}>
            <Typography variant="button" fontWeight="regular" color="text">
              Subscribe to newsletter
            </Typography>
          </Box>
        </Box>
      </Box>
    </Card>
  );
}

export default PlatformSettings;
