import { useContext, useEffect, useState } from 'react';
import AppBar from '@mui/material/AppBar';
import Box from '@mui/material/Box';
import Toolbar from '@mui/material/Toolbar';
import IconButton from '@mui/material/IconButton';
import Typography from '@mui/material/Typography';
import Menu from '@mui/material/Menu';
import Container from '@mui/material/Container';
import Avatar from '@mui/material/Avatar';
import Button from '@mui/material/Button';
import Tooltip from '@mui/material/Tooltip';
import MenuItem from '@mui/material/MenuItem';
import AdbIcon from '@mui/icons-material/Adb';
import { useNavigate } from 'react-router-dom';
import MenuIcon from '@mui/icons-material/Menu';
import { AppRoute, CustomRoutes, SettingRoutes } from '../../routes';
import logo from "../../assets/img/LOGOPS.png";
import { auth } from '../../firebase';
import { AuthContext } from '../../context/AuthContext';
import "../navbar/Navbar.css";
import LoginIcon from '@mui/icons-material/Login';

function NavBar() {

  const navigate = useNavigate();

  const user = useContext(AuthContext)
  
  const [anchorElNav, setAnchorElNav] = useState<null | HTMLElement>(null);
  const [anchorElUser, setAnchorElUser] = useState<null | HTMLElement>(null);
  const [isLoggedIn, setIsLoggedIn] = useState(false)
  const [isHost, setIsHost] = useState(false)

  const handleOpenNavMenu = (event: React.MouseEvent<HTMLElement>) => {
    setAnchorElNav(event.currentTarget);
  };
  const handleOpenUserMenu = (event: React.MouseEvent<HTMLElement>) => {
    setAnchorElUser(event.currentTarget);
  };

  const handleCloseNavMenu = () => {
    setAnchorElNav(null);
  };

  const handleCloseUserMenu = () => {
    setAnchorElUser(null);
  };

  const handleLogout = () => {
    console.log("Loggin out")
    auth.signOut()
    window.location.reload();

  };

  useEffect(() => {
    
    const getData = async () => {
      await auth.onAuthStateChanged((user) => {
        if (user !==null) {
          console.log(user.uid);
          setIsLoggedIn(true)
          setIsHost(true)
        } 
      });
    }
    getData()
  }, [])
  
  return (
    <AppBar position="absolute" sx={{  height: '14vh', backgroundColor: "white", marginBottom: "13vh", padding: {xs: "2vh 1vw", lg: "1vh 5vw"} }}>
      <Container maxWidth="xl">
        <Toolbar disableGutters >
          <Box sx={{ flexGrow: 1, display: { xs: 'flex', md: 'none' }}}>
            <IconButton
              size="medium"
              aria-label="account of current user"
              aria-controls="menu-appbar"
              aria-haspopup="true"
              onClick={handleOpenNavMenu}
            >
              <MenuIcon style={{color:"black"}}/>
            </IconButton>

            <Menu
              id="menu-appbar"
              anchorEl={anchorElNav}
              anchorOrigin={{
                vertical: 'bottom',
                horizontal: 'left',
              }}
              keepMounted
              transformOrigin={{
                vertical: 'top',
                horizontal: 'left',
              }}
              open={Boolean(anchorElNav)}
              onClick={handleCloseNavMenu}
              onClose={handleCloseNavMenu}
              sx={{
                display: { xs: 'block', md: 'none' },
              }}
            >
              {CustomRoutes.map((cRo: AppRoute) => {
                return cRo.NavBarVisibile ?
                  <MenuItem key={cRo.DisplayName} onClick={() => navigate(cRo.Navigation)}>
                    <Typography textAlign="center">{cRo.DisplayName}</Typography>
                  </MenuItem>
                  :
                  null
              })}
            </Menu>
          </Box>
          <div style={{justifyContent : "center"}}></div>

          <Box 
            component="img"
            sx={{
              height: "100%",
              width: {xs: "50%",lg: 300},
              margin: {xs: "1vh 5vw", sm: "-1vw 14vw", xl: "1vh 5vw"}
            }}
            src={logo}
            onClick={handleOpenNavMenu} />
             
          <Box sx={{ flexGrow: 1, display: { xs: 'none', md: 'flex' }, justifyContent : "right"}}>
            {CustomRoutes.map((cRo: AppRoute) => {
              return cRo.NavBarVisibile ?
                <Button
                  key={cRo.DisplayName}
                  onClick={() => navigate(cRo.Navigation)}
                  sx={{ my: 2, color: 'black', display: 'block'}}

                >
                  {cRo.DisplayName}
                </Button> :
                null
            })}
          </Box>

          <Box sx={{ flexGrow: 0}}>

            {!isLoggedIn ? (
              <Box sx={{ flexGrow: 1, display: { xs: 'flex', md: 'flex' } }}>
                <Button
                  key={"Login"}
                  onClick={() => navigate('/login')}
                  sx={{ my: 2, color: 'black', display: 'block' }}
                >
                 <IconButton>
                  <LoginIcon/>
                  </IconButton>
                </Button>

              </Box>
            ) : (
              <>
                <Tooltip title="Open settings">
                  <IconButton onClick={handleOpenUserMenu}>
                  <Avatar alt={user!.displayName?.toString()} src={user!.photoURL?.toString()} />
                  </IconButton>
                </Tooltip>
                <Menu
                  sx={{ mt: '45px' }}
                  id="menu-appbar"
                  anchorEl={anchorElUser}
                  anchorOrigin={{
                    vertical: 'top',
                    horizontal: 'right',
                  }}
                  keepMounted
                  transformOrigin={{
                    vertical: 'top',
                    horizontal: 'right',
                  }}
                  
                  open={Boolean(anchorElUser)}
                  onClick={handleCloseUserMenu}
                  onClose={handleCloseUserMenu}
                >
                  {SettingRoutes.map((setting: AppRoute) => (
                    <MenuItem key={setting.DisplayName} onClick={() => navigate(setting.Navigation)}>
                      <Typography textAlign="center">{setting.DisplayName}</Typography>
                    </MenuItem>
                  ))}
                  <MenuItem key={"logout"} onClick={handleLogout}>
                    <Typography textAlign="center">Logout</Typography>
                  </MenuItem>
                </Menu>
              </>
            )}

          </Box>
        </Toolbar>
      </Container>
    </AppBar>
  );
}
export default NavBar;