// components/Home.js
import { BounceLoader } from 'react-spinners';
import { useEffect, useState } from 'react';
import Grid from '@mui/material/Grid';

import laodingLogo from '../../../src/assets/img/about/loadingLogo.webp'
import { Box } from '@mui/material';

export function Privacy() {
  const [isLoaded, setIsLoaded] = useState(false)

  function delay(ms: number) {
    return new Promise(resolve => setTimeout(resolve, ms));
  }

  useEffect(() => {
    const getData = async () => {



    }
    getData()
  }, [])


  return (
    <>
      <Grid container spacing={2}>
        <Grid item lg={12} md={12} xs={12}>
          <h1>END USER LICENSE AGREEMENT</h1>
          <p>Last updated January 23, 2022</p>
        </Grid>

      </Grid>
    </>


  );
}
