// components/Home.js
import { Accordion, AccordionDetails, AccordionSummary, Grid, Typography } from '@mui/material';
import ExpandMoreIcon from '@mui/icons-material/ExpandMore';
import { useContext, useEffect, useState } from 'react';
import { NavLink } from "react-router-dom";
import { Adsense } from '@ctrl/react-adsense';
import { collection, query, getDocs, addDoc, doc, deleteDoc, updateDoc, where } from "firebase/firestore";
import { firestoreKeys } from '../../utils/constants';
import { db } from '../../firebase';
import { FireStoreUserContext } from '../../context/FireStoreUserContext';
import { BounceLoader } from 'react-spinners';
import "./faq.css";

export function FAQ() {
  const [isLoaded, setIsLoaded] = useState(false);
  const [faqData, setItems] = useState<any>([])
  const [notificationID, setNotificationID] = useState("")


  const { parkshareUser, setParkshareUser, credsCheckDone } = useContext(FireStoreUserContext)

  useEffect(() => {
    const getData = async () => {
      const tmpArray: any[] = []
      let querySnapshot
      if (parkshareUser !== undefined) {
        querySnapshot = await getDocs(query(collection(db, firestoreKeys.FAQ), where("type", "!=", "public")));
      } else {
        querySnapshot = await getDocs(query(collection(db, firestoreKeys.FAQ), where("type", "!=", "private")));
      }

      querySnapshot.forEach((doc) => {
        let item = doc.data()
        item.id = doc.id
        tmpArray.push(item)
      });
      setItems(tmpArray)
      setIsLoaded(true)
    }
    getData()
  }, [])




  return (
    <>
      {credsCheckDone ? (
        <>
          {isLoaded ? (
            <>
              <Grid className='faqContain' container sx={{ alignContent: "center", minHeight: "60vh", display: "block", marginLeft: "0vw", marginRight: "0vw", padding: {xs: "8vh 3vw", lg: "8vh 2vw"}, marginTop: "auto", marginBottom: "auto" }}>
                <Grid item xs={12}>
                  <Typography variant="h1" sx={{ fontSize: {xs: "1.5rem", lg: "2.2rem"}, marginBottom: "6vh", fontWeight: 500 }}>Frequently asked questions</Typography>
                </Grid>
                {

                  faqData.map((item: any) =>
                    <Grid item  xs={12} key={item.question} sx={{  margin: "auto", width: "90%"}}>
                      <Accordion className='accordion-faq' style={{ boxShadow: "none", borderBottomColor: "#c7d3e2", borderBottomStyle: "solid" }}>
                        <AccordionSummary
                          expandIcon={<ExpandMoreIcon sx={{color: "#964cff"}}/>}
                          aria-controls="panel1a-content"
                          id="panel1a-header"
                        >
                          <Typography style={{ textAlign: "left", fontSize: "1.2rem" }}><b>{item.question}</b></Typography>
                        </AccordionSummary>
                        <AccordionDetails>
                          <Typography style={{ textAlign: "left", fontSize: "1.1rem" }}>
                            {item.answer}
                          </Typography>
                        </AccordionDetails>
                      </Accordion>
                    </Grid>
                  )
                }

                <Grid item lg={12} md={12} xs={12}>
                  {/* <div style={{ border: '2px solid red' }}> */}
                  <div>
                    <Adsense
                      client={process.env.REACT_APP_GOOGLE_AD_CLIENT!}
                      slot={process.env.REACH_APP_GOOGLE_AD_SLOT!}
                      style={{ width: 500, height: 300 }}
                      format=""
                    />
                  </div>
                </Grid>
              </Grid>
            </>
          ) : (
            <Grid container spacing={2} style={{ minHeight: "50vh", paddingBottom: "20px" }}
              direction="column"
              alignItems="center"
              justifyContent="center">
              <Grid item lg={12} md={12} xs={12}>
                <BounceLoader
                  color="#9753f7"
                  cssOverride={{}}
                  loading
                  size={100}
                />
              </Grid>
            </Grid>
          )}
        </>) : (
        <Grid container spacing={2} style={{ minHeight: "50vh", paddingBottom: "20px" }}
          direction="column"
          alignItems="center"
          justifyContent="center">
          <Grid item lg={12} md={12} xs={12}>
            <BounceLoader
              color="#9753f7"
              cssOverride={{}}
              loading
              size={100}
            />
          </Grid>
        </Grid>
      )}
    </>



  );
}

export default FAQ
