// components/Protected.js
import { BounceLoader } from 'react-spinners';
import { useContext, useEffect, useState } from 'react';
import { AuthContext } from '../../context/AuthContext';
import { Box, Grid } from '@mui/material';
import { SpotsHelper } from '../../data/firestore/spots';
import DefaultProjectCard from '../../examples/Cards/ProjectCards/DefaultProjectCard';
import backgroundImage from "../../assets/img/defaults/parking-icon-png-32.png";
import {Link, useNavigate} from 'react-router-dom';
import { getDownloadURL, getStorage, ref } from 'firebase/storage';
import * as Sentry from "@sentry/react";

export function Spots() {

  const user = useContext(AuthContext)
  const navigate = useNavigate();
  const storage = getStorage();
  
  const [isLoaded, setIsLoaded] = useState(false)
  const [spots, setSpots] = useState<any[]>([])

  const deleteItem = (id: any) => {
    console.log("Deleting", id)
  }

  const selectItem = (id: any) => {
    console.log("Selecting", id)
    console.log(spots.find(i => i.location.formal === id))
    navigate('/spot',{state:{spot:spots.find(i => i.location.formal === id)}});
  }

  useEffect(() => {
    const getData = async () => {
      setIsLoaded(false)
      console.log("Profile of", user)
      // Spots
      let items: any[] = [];
      const spHelper = new SpotsHelper(user!)
      let spotsQ = await spHelper.getMySpots()
      spotsQ.forEach((doc) => {
        items.push(doc.data())
        // getDownloadURL(ref(storage, doc.data().photoUrl))
        //     .then((url) => {
        //       console.log(url);
        //       doc.data().photoUrl = url;
              
        //     })
        //     .catch((error) => {
        //       Sentry.captureException(error);
        //     });
      });
      console.log("setting spots", items)
      setSpots(items)
      setIsLoaded(true)
    }
    getData()
  }, [])


  return (
    <>
      {isLoaded ? (
        <>
          <Grid container spacing={2} sx={{ width: "90vw", margin: "3vh auto", display: "flex", justify: "center" }} >
            <h1 style={{margin: "3vh auto", background: "-webkit-linear-gradient(90deg, #964cff, #bb2cc0)", WebkitBackgroundClip: "text", WebkitTextFillColor: "transparent"}}>Your spots</h1>
            <Grid container sx={{ margin: "3vh auto" }}>
              <Grid
                container
                spacing={6}
                alignItems="center"
                justifyContent="center"
              >
                {spots.map((doc) => {
                  return (

                    <Grid
                      item
                      xs={12}
                      md={6}
                      xl={3}
                      key={doc.location.formal}
                      sx={{display: "flex", justifyContent: "center", alignItems: "center", margin: "auto auto"}}
                    >
                      <DefaultProjectCard
                        id={doc.location.formal}
                        image={doc.photoUrl || backgroundImage}
                        label=""
                        title={doc.location.formal}
                        description={doc.description}
                        action={selectItem}
                        deleteAction={deleteItem}
                        authors={[
                          { name: "Elena Morison" },
                          { name: "Ryan Milly" },
                          { name: "Nick Daniel" },
                          { name: "Peterson" },
                        ]}
                      />
                    </Grid>

                  )
                })}
              </Grid>


            </Grid>

          </Grid>

        </>
      ) : (
        <Grid container spacing={2} style={{ minHeight: "50vh", paddingBottom: "20px" }}
          direction="column"
          alignItems="center"
          justifyContent="center">
          <Grid item lg={12} md={12} xs={12}>
            <BounceLoader
              color="#9753f7"
              cssOverride={{}}
              loading
              size={100}
            />
          </Grid>
        </Grid>
      )
      }
    </>


  );
}
