
import React, { useContext } from 'react';
import { Navigate } from 'react-router-dom';
import { AuthContext } from '../../context/AuthContext';

  const RequireAuth = ({children}:{children:any}) => {
    const user = useContext(AuthContext);
    console.log("Protected:",user)
   
    if (user === null) {
      return <Navigate to='/login' />;
    }
    console.log("Validate", user.emailVerified)
    if (user.emailVerified === false) {
      return <Navigate replace to='/validate' />;
      // return <Navigate to='/home' />;
    }
    return children;
  };
  
  export default RequireAuth;