import { useContext, useState } from 'react';
// @mui material components
import Card from "@mui/material/Card";
import Grid from "@mui/material/Grid";
import Icon from "@mui/material/Icon";
import Tooltip from "@mui/material/Tooltip";
import { BounceLoader } from 'react-spinners';
// Material Dashboard 2 React components
import Box from "@mui/material/Box";
import Typography from "@mui/material/Typography";
import Button from "@mui/material/Button";
import AddIcon from '@mui/icons-material/Add';
import CheckCircleOutlineIcon from '@mui/icons-material/CheckCircleOutline';
import DeleteForeverOutlinedIcon from '@mui/icons-material/DeleteForeverOutlined';
import ModeEditOutlineIcon from '@mui/icons-material/ModeEdit';
// Images
import masterCardLogo from "../../../../assets/images/logos/mastercard.png";
import visaLogo from "../../../../assets/images/logos/visa.png";
import americanExpress from "../../../../assets/images/logos/americanexpress.jpeg";
import axios from "axios";
import { AuthContext } from "../../../../context/AuthContext";
import { onAuthStateChanged } from "@firebase/auth";
import { getAuth, getIdToken } from "firebase/auth";
import Modal from '@mui/material/Modal';
// Material Dashboard 2 React context

function delay(ms: number) {
  return new Promise(resolve => setTimeout(resolve, ms));
}

const style = {
  position: 'absolute' as 'absolute',
  top: '50%',
  left: '50%',
  transform: 'translate(-50%, -50%)',
  width: 400,
  bgcolor: 'background.paper',
  border: '2px solid #000',
  boxShadow: 24,
  p: 4,
};

function PaymentMethod(cardLists: any) {

  const user = useContext(AuthContext)
  const [isCardLoaded, setIsCardLoaded] = useState(true)
  const [open, setOpen] = useState(false);
  const handleOpen = () => setOpen(true);
  const handleClose = () => setOpen(false);
  const [getAuthUrl, setAuthUrl] = useState("");

  const getUserToken = async () => {
    return new Promise((resolve, reject) => {
      const unsub = onAuthStateChanged(getAuth(), async (user) => {
        if (user) {
          const token = await getIdToken(user);
          resolve(token)
        } else {
          console.log("User not logged in")
          resolve(null)
        }
        unsub();
      });
    })
  }

  const redirectToPayout = async () => {
    window.location.href = getAuthUrl;
  }

  const setAsDefault = async () => {

  }

  const addCard = async () => {
    setIsCardLoaded(false)
    if (getAuthUrl === "") {
      const token = await getUserToken();
      console.log(token)
      const options = {
        withCredentials: true,
        url: 'http://127.0.0.1:5001/parkshare-prod/europe-west2/api/payment/initialise',
        method: 'POST',
        headers: {
          Authorization: `Bearer ${token}`,
          'Content-Type': 'application/json',
          'Acess-Control-Allow-Origin': 'http://localhost:3000'
        },
        data: {}
      }
      await delay(1000);
      axios(options)
        .then(function (response) {
          console.log(response.data.authorization_url);
          // authorization_url
          setAuthUrl(response.data.authorization_url)
          handleOpen()
          // window.location.href = response.data.authorization_url;
        })
        .catch(function (error) {
          console.log(error);
          setIsCardLoaded(true)
        });
    } else {
      console.log("Old link being used")
      handleOpen()
      setIsCardLoaded(true)
    }
  };

  return (
    <>
      <Card id="delete-account" sx={{ boxShadow: "0 20px 25px -5px rgba(0, 0, 0, 0.2), 0 10px 10px -5px rgba(0, 0, 0, 0.2) !important", width: {xs: "40%", lg: "100%"},  margin: "4vh auto" }}>
        <Box pt={2} px={0} sx={{display: "flex", justifyContent: "space-between", alignItems: "center", flexDirection: {xs: "column", lg: "row"}}}>
          <Typography variant="h6" fontWeight="medium" sx={{
            background: "-webkit-linear-gradient(90deg, #964cff, #bb2cc0)",
            backgroundClip: "text", textFillColor: "transparent", fontSize: "1.4rem", marginTop: "2vh"
          }}>
            &nbsp;&nbsp;&nbsp;Payment Methods
          </Typography>

          <Button onClick={addCard} sx={{ backgroundColor: "#9753f7", marginTop: "3vh", color: "white", alignItems: "center", "& .MuiButton-startIcon": { marginRight: '10px' }, "&:hover": { backgroundColor: "#bb2cc0"} }}>
            <AddIcon />
            add new card
          </Button>
        </Box>
        
        {isCardLoaded ? (
          <Box p={2}>
            <Grid container spacing={3}>
              {Object.values(cardLists.cards).map((obj: any) => {
                console.log("Loading", obj)
                return (
                  <>
                    <Grid item xs={12} md={12} sx={{display: "flex", flexDirection: {xs: "column", lg: "row"}}}>
                      <Box
                        borderRadius="lg"
                        display="flex"
                        justifyContent="space-between"
                        alignItems="center"
                        p={3}
                        sx={{display: "flex", flexDirection: {xs: "column", lg: "row"}}}
                      >
                        <Box component="img" src={obj.brand === "master" ? masterCardLogo : obj.brand === "visa" ? visaLogo : americanExpress} alt="card" sx={{width: {xs: "30%", lg:"10%"}, marginTop: "3vh" }} mr={2} />
                        <Typography variant="h6" fontWeight="medium" sx={{marginTop: "3vh"}}>
                          ************{obj.last4}
                        </Typography>

                        <Box  display="flex" justifyContent="flex-end" alignItems="center" sx={{marginTop: "3vh"}}>
                          <Tooltip title="Set as Default Card" placement="top">
                            <Button size="small" onClick={setAsDefault} endIcon={<CheckCircleOutlineIcon style={{ color: "#9753f7" }} />} />
                          </Tooltip>
                          <Tooltip title="Edit Card" placement="top">
                            <Button size="small" onClick={setAsDefault} endIcon={<ModeEditOutlineIcon style={{ color: "#9753f7" }} />} />
                          </Tooltip>
                          <Tooltip title="Remove Card" placement="top">
                            <Button size="small" onClick={setAsDefault} endIcon={<DeleteForeverOutlinedIcon style={{ color: "#9753f7" }} />} />
                          </Tooltip>
                        </Box>
                      </Box>
                    </Grid>
                  </>
                )
              })}

            </Grid>
          </Box>
        ) : (
          <div style={{ width: '100%', height: "40vh", alignContent: 'center', textAlign: 'center', justifyContent: 'center', display: 'flex' }}>
            <div style={{ marginTop: '5vh' }}><BounceLoader color="#9753f7" cssOverride={{}} loading size={100} /></div>

          </div>
        )}
      </Card>
      <Modal
        open={open}
        onClose={handleClose}
        aria-labelledby="modal-modal-title"
        aria-describedby="modal-modal-description"
      >
        <Box sx={style}>
          <Typography id="modal-modal-title" variant="h6" component="h2">
            Adding new Card
          </Typography>
          <Typography id="modal-modal-description" sx={{ mt: 2 }}>
            You are going to be redirected to our payment provider
          </Typography>

          <Typography id="modal-modal-description" sx={{ mt: 2 }}>
            There will be a 1 rand charge to validate.
          </Typography>
          <Button onClick={redirectToPayout} variant="contained" style={{ backgroundColor: "#9753f7", color: "white", marginRight: "5px" }}>Pay</Button>
        </Box>
      </Modal>
    </>
  );
}

export default PaymentMethod;
