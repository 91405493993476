import { BounceLoader } from "react-spinners";
import { useCallback, useContext, useEffect, useState } from "react";
import Grid from "@mui/material/Grid";
import * as React from "react";
import {
  Typography,
  Divider,
  TextField,
  Button,
  Checkbox,
  Box,
  Tabs,
  Tab,
  FormGroup,
  FormControlLabel,
  FormControl,
  InputLabel,
  InputAdornment,
  OutlinedInput,
  MenuItem,
  Fade,
  Modal,
  Paper,
  styled,
} from "@mui/material";
import { FireStoreUserContext } from "../../context/FireStoreUserContext";
import { GoogleMap, MarkerF, useJsApiLoader } from "@react-google-maps/api";
import { useGeolocated } from "react-geolocated";
import { TabPanel } from "../auth/Login";
import { DatePicker, TimePicker } from "antd";
import type { Dayjs } from "dayjs";
import Geocode from "react-geocode";
import { auth, db } from "../../firebase";
import { doc, getDoc, setDoc, updateDoc } from "firebase/firestore";
import Select, { SelectChangeEvent } from "@mui/material/Select";
import { getStorage, ref, uploadBytes, getDownloadURL } from "firebase/storage";
import * as Sentry from "@sentry/react";
import CardMedia from "@mui/material/CardMedia";
import backgroundImage from "../../assets/img/defaults/parking-icon-png-32.png";
import DefaultProjectCard from "../../examples/Cards/ProjectCards/DefaultProjectCard";
import { firestoreKeys } from "../../utils/constants";
import { AuthContext } from "../../context/AuthContext";
import { useForm } from "react-hook-form";
import { Store } from "react-notifications-component";
import { ModalClose, Sheet } from "@mui/joy";
import WeekScheduleSelector from "../userHome/spotTabs/WeekSelector";
import DateRangeSelector from "../userHome/spotTabs/DateRange";
import dayjs from "dayjs";

// Google
Geocode.setApiKey(process.env.REACT_APP_GOOGLE_TOKEN!);
Geocode.setLocationType("ROOFTOP");
// Enable or disable logs. Its optional.
Geocode.enableDebug();

export function HostRegistration() {
  const storage = getStorage();
  const user = useContext(AuthContext);

  const { isLoaded } = useJsApiLoader({
    id: "google-map-script",
    googleMapsApiKey: process.env.REACT_APP_GOOGLE_TOKEN!,
  });

  // eslint-disable-next-line @typescript-eslint/no-unused-vars
  const options = {
    provider: "google",
    // Optional depending on the providers
    apiKey: process.env.REACT_APP_GOOGLE_TOKEN!, // for Mapquest, OpenCage, Google Premier
    formatter: null, // 'gpx', 'string', ...
  };

  const { parkshareUser } = useContext(FireStoreUserContext);

  const [width, setWidth] = useState(12);
  const [pageLoaded, setPageLoaded] = useState(true);
  const [photoLoaded, setPhotoLoaded] = useState(true);
  const [spotForms, setSpotForms] = useState<any>([]);
  const [saveForm, setSaveForm] = useState(false);
  const [validLocation, setValidLocation] = useState(false);
  const [firstName, setFirstName] = useState(parkshareUser.person.firstName);
  const [lastName, setLastName] = useState(parkshareUser.person.lastName);
  const [email, setEmail] = useState(parkshareUser.person.email);
  const [emailChanged, setEmailChanged] = useState(false);
  const [phoneNumber, setPhoneNumber] = useState(parkshareUser.person.phoneNumber);
  const [bankCode, setBankCode] = useState(parkshareUser.banking.code);
  const [bankAccNumber, setBankAccNumber] = useState(parkshareUser.banking.accountNumber);
  const [bankName, setBankName] = useState(parkshareUser.banking.bankName);
  const [file, setFile] = useState<string>("");
  // Map stuff
  const [map, setMap] = useState(null);
  const [showMarker, setShowMarker] = useState(false);
  const [markerLong, setMarkerLong] = useState<number>(18.40838);
  const [markerLat, setMarkerLat] = useState(-33.90763);
  const [searchAddress, setSearchAddress] = useState("");
  const [searchAddressPassed, setSearchAddressPassed] = useState(false);
  const [notificationID, setNotificationID] = useState("")
  // Values
  const [spotName, setSpotName] = useState("");
  const [spotDesciption, setSpotDescription] = useState("");
  const [spotPrice, setSpotPrice] = useState(0.0);
  const [streetNumber, setStreetNumber] = useState("");
  const [route, setRoute] = useState("");
  const [postalCode, setPostalCode] = useState("");
  const [subLocalityLevel2, setSubLocalityLevel2] = useState("");
  const [locality, setLocality] = useState("");
  const [administrativeAreaLevel1, setAdministrativeAreaLevel1] = useState("");
  const [country, setCountry] = useState("");
  const [wheelchair, setWheelchair] = useState(false);
  const [mulitspot, setMultiSpot] = useState(false);

  const [linkBusiness, setLinkBusiness] = useState(false);
  const [businessName, setBusinessName] = useState("");
  const [spotTotal, setSpotTotal] = useState(1);
  const exampleMapStyles = [
    {
      featureType: "poi",
      elementType: "geometry",
      stylers: [
        {
          color: "#eeeeee",
        },
      ],
    },
    {
      featureType: "poi",
      elementType: "labels.text",
      stylers: [
        {
          visibility: "off",
        },
      ],
    },
    {
      featureType: "water",
      elementType: "labels.text.fill",
      stylers: [
        {
          color: "#9e9e9e",
        },
      ],
    },
  ];

  const Item = styled(Paper)(({ theme }) => ({

    ...theme.typography.body2,
    textAlign: 'center',
  }));

  const {
    getValues,
    setValue,
    formState: { isDirty, isValid },
  } = useForm({
    defaultValues: {
      name: "",
      description: "",
      wheelchair: wheelchair,
      mulitspot: mulitspot,
      spotTotal: spotTotal,
      price: 0,
      photoUrl: "",
      location: {
        lat: "",
        lng: "",
        street_number: "",
        street_name: "",
        postal_code: "",
        area: "",
        town: "",
        province: "",
        country: "",
        formal: "",
      },
      date: {
        type: "haha",
      },
    }
  });

  const changeBank = (event: SelectChangeEvent) => {
    setBankName(event.target.value as string);
  };

  const [canNotApply, setCanApply] = useState(true);
  const [canhostDetailsChanged, setCanHostDetailsChange] = useState(false);

  function timeout(delay: number) {
    return new Promise(res => setTimeout(res, delay));
  }

  const needHostData = () => {
    if (firstName === "" || bankAccNumber === "" || bankAccNumber === undefined) {
      return true;
    }
    return false;
  };

  const applyForHost = async () => {
    setPageLoaded(false);

    auth.onAuthStateChanged(async (user) => {
      if (needHostData()) {
        handleOpen();
        setPageLoaded(true);
        return;
      } else {
        if (canhostDetailsChanged) {
          const userUpdate = {
            person: {
              email: email,
              firstName: firstName,
              lastName: lastName,
              phoneNumber: phoneNumber,
            },
            banking: {
              bankName: bankName,
              accountNumber: bankAccNumber,
              code: bankCode,
            },
          };
          await updateDoc(
            doc(db, "users", user!.uid),
            JSON.parse(JSON.stringify(userUpdate))
          );
        }
      }

      if (user !== null) {
        spotForms.forEach(async (spot: any) => {
          // const spotSave = {
          // }
          const docRef = doc(db, "spots", spot.location.formal);
          const docSnap = await getDoc(docRef);
          if (docSnap.exists()) {
            console.log("Tell user sorry man");
          } else {
            spot.owner = user.uid;
            spot.management = {};
            spot.management.delegates = {};
            spot.admin = {};

            spot.admin.state = "pending";
            spot.admin.date = Date();
            spot.admin.note = "Created from portal";
            await setDoc(
              doc(db, "spots", spot.location.formal),
              JSON.parse(JSON.stringify(spot))
            )
              .then((data: any) => {
                handleOpenSucess();
              })
              .catch(() => {
                console.log("Oops");
              });
          }
        });
        setPageLoaded(true);
      }
    });
  };

  const addSpot = async () => {
    console.log("File at adding:", file);
    setPageLoaded(false)
    setSpotForms((spotForms: any) => [
      ...spotForms,
      {
        name: spotName,
        description: spotDesciption,
        wheelchair: wheelchair,
        mulitspot: mulitspot,
        spotTotal: spotTotal,
        price: spotPrice,
        photoUrl: file,
        location: {
          lat: markerLat,
          lng: markerLong,
          street_number: streetNumber,
          street_name: route,
          postal_code: postalCode,
          area: subLocalityLevel2,
          town: locality,
          province: administrativeAreaLevel1,
          country: country,
          formal: searchAddress,
        },
        date: {
          type: "haha",
        },
      },
    ]);

    await timeout(1000);
    setSaveForm(true);
    console.log("Setting forms:", spotForms);
    setWidth(12)
    setValidLocation(false);
    setPageLoaded(true)
    setNotificationID(Store.addNotification({

      title: "Added your spot to application",
      message: "Feel free to submit your request or add another spot.",
      type: "success",
      insert: "top",
      container: "top-left",
      animationIn: ["animate__animated", "animate__fadeIn"],
      animationOut: ["animate__animated", "animate__fadeOut"],
      dismiss: {
        duration: 5000,
        onScreen: true
      }
    }));
    Store.removeNotification(notificationID)

  };

  // const { coords, isGeolocationAvailable, isGeolocationEnabled } =
  const { coords, isGeolocationAvailable } =
    useGeolocated({
      positionOptions: {
        enableHighAccuracy: false,
      },
      userDecisionTimeout: 5000,
    });

  useEffect(() => {
    const getData = async () => {
      if (isGeolocationAvailable) {
        if (coords !== undefined) {
          if (coords.latitude !== undefined && coords.longitude !== undefined) {
            setCenter({
              lat: coords.latitude,
              lng: coords.longitude,
            });
          }
        }
      }
    };
    getData();
  }, [coords, isGeolocationAvailable]);

  const handleWeekScheduleChange = (weekSchedule: { [day: string]: [Dayjs | null, Dayjs | null] }) => {
    // Handle the updated week schedule here
    console.log('Updated week schedule:', weekSchedule);
    console.log(weekSchedule)
  };

  const handleDateRangeChange = (dateRanges: [dayjs.Dayjs | null, dayjs.Dayjs | null][]) => {
    // Handle the selected date ranges here
    console.log('Selected date ranges:', dateRanges);
  };

  const onSearch = () => {
    Geocode.fromAddress(searchAddress).then(
      async (response) => {
        setPageLoaded(false)
        const { lat, lng } = response.results[0].geometry.location;
        setCenter({
          lat: lat,
          lng: lng,
        });
        setMarkerLong(lng);
        setMarkerLat(lat);
        setShowMarker(true);
        setWidth(6)
        setValidLocation(true);
        await timeout(700);
        setPageLoaded(true)
      },
      (error) => {
        Sentry.captureException(error);
        setNotificationID(Store.addNotification({

          title: "This location is invalid!",
          message: "We could not process the location.",
          type: "danger",
          insert: "top",
          container: "top-left",
          animationIn: ["animate__animated", "animate__fadeIn"],
          animationOut: ["animate__animated", "animate__fadeOut"],
          dismiss: {
            duration: 2000,
            onScreen: true
          }
        }));
        Store.removeNotification(notificationID)
      }
    );
  };
  const containerStyle = {
    width: "100%",
    minHeight: "80%",
  };

  const [center, setCenter] = useState({
    lat: -33.90763,
    lng: 18.40838,
  });

  const onLoad = useCallback(function callback(map: any) {
    if (isGeolocationAvailable) {
      if (coords !== undefined) {
        if (coords.latitude !== undefined && coords.longitude !== undefined) {
          setCenter({
            lat: coords.latitude,
            lng: coords.longitude,
          });
        }
      }
    }
    // const bounds = new window.google.maps.LatLngBounds(center);
    // map.fitBounds(bounds);
    map.setZoom(10);
    setMap(map);
  }, []);

  const onUnmount = useCallback(function callback(map: any) {
    setMap(null);
  }, []);

  const onClick = (e: any) => {
    setPageLoaded(false)
    Geocode.fromLatLng(e.latLng.lat(), e.latLng.lng()).then(
      (response) => {
        try {
          const address = response.results[0];
          setStreetNumber(
            address.address_components.filter((adComp: any) =>
              adComp.types.includes("street_number")
            )[0].long_name
          );
          setPostalCode(
            address.address_components.filter((adComp: any) =>
              adComp.types.includes("postal_code")
            )[0].long_name
          );
          setCountry(
            address.address_components.filter((adComp: any) =>
              adComp.types.includes("country")
            )[0].long_name
          );
          setRoute(
            address.address_components.filter((adComp: any) =>
              adComp.types.includes("route")
            )[0].long_name
          );
          setSubLocalityLevel2(
            address.address_components.filter((adComp: any) =>
              adComp.types.includes("sublocality_level_2")
            )[0].long_name
          );
          setLocality(
            address.address_components.filter((adComp: any) =>
              adComp.types.includes("locality")
            )[0].long_name
          );
          setAdministrativeAreaLevel1(
            address.address_components.filter((adComp: any) =>
              adComp.types.includes("administrative_area_level_1")
            )[0].long_name
          );
          setSearchAddress(address.formatted_address);
          setWidth(6)
          setValidLocation(true);
          setPageLoaded(true)
        } catch (error: any) {
          setPageLoaded(true)
          Sentry.captureException("Geocoding parsing error:", error);
          setNotificationID(Store.addNotification({

            title: "This location is invalid!",
            message: "We could not process the location.",
            type: "danger",
            insert: "top",
            container: "top-left",
            animationIn: ["animate__animated", "animate__fadeIn"],
            animationOut: ["animate__animated", "animate__fadeOut"],
            dismiss: {
              duration: 2000,
              onScreen: true
            }
          }));
          Store.removeNotification(notificationID)
        }
      },
      (error) => {
        Sentry.captureException("Geocoding error:", error);
        console.log("Exception:", error)
        setPageLoaded(true)
        setNotificationID(Store.addNotification({
          title: "This location is invalid!",
          message: "Please retry using the map",
          type: "danger",
          insert: "top",
          container: "top-left",
          animationIn: ["animate__animated", "animate__fadeIn"],
          animationOut: ["animate__animated", "animate__fadeOut"],
          dismiss: {
            duration: 2000,
            onScreen: true
          }
        }));
        Store.removeNotification(notificationID)
      }
    );
    setMarkerLong(e.latLng.lng());
    setMarkerLat(e.latLng.lat());
    setShowMarker(true);
  };

  // Date
  const [value, setDateValue] = useState(0);


  function a11yProps(index: number) {
    return {
      id: `simple-tab-${index}`,
      "aria-controls": `simple-tabpanel-${index}`,
    };
  }

  const handleChange = (event: React.SyntheticEvent, newValue: number) => {
    setDateValue(newValue);
  };

  const handleImage = function loadFile(event: any) {
    setPhotoLoaded(false);
    const file = event.target.files[0];
    if (event.target.files.length > 0) {
      // File save and set url
      const storageRef = ref(storage, `${user!.uid}/${file.name}`);
      uploadBytes(storageRef, file)
        .then(response => {
          console.log("Upload resp:", response)
          getDownloadURL(ref(storage, `${user!.uid}/${file.name}`))
            .then((url) => {
              console.log(url);
              setFile(url);
              const docRef = doc(db, firestoreKeys.USERS, user!.uid);
              updateDoc(docRef, { photoUrl: `${user!.uid}/${file.name}` })
                .then(() => {
                  timeout(2000)
                  setPhotoLoaded(true)
                })
                .catch((error) => {
                  Sentry.captureException(error);
                  setNotificationID(Store.addNotification({

                    title: "There has been an error. ",
                    message: "Our team has been notified about the issue, please retry later.",
                    type: "danger",
                    insert: "top",
                    container: "top-left",
                    animationIn: ["animate__animated", "animate__fadeIn"],
                    animationOut: ["animate__animated", "animate__fadeOut"],
                    dismiss: {
                      duration: 5000,
                      onScreen: true
                    }
                  }));
                  Store.removeNotification(notificationID)
                });
            })
            .catch((error) => {
              Sentry.captureException(error);
              setPhotoLoaded(true);
            });
        })
        .catch((err) => {
          setNotificationID(Store.addNotification({

            title: "There was an issue with that image",
            message: "We have notified the team. you can retry the image or please try a new one.",
            type: "danger",
            insert: "top",
            container: "top-left",
            animationIn: ["animate__animated", "animate__fadeIn"],
            animationOut: ["animate__animated", "animate__fadeOut"],
            dismiss: {
              duration: 5000,
              onScreen: true
            }
          }));
          Store.removeNotification(notificationID)
          setPhotoLoaded(true);
        });
    }
  };

  // Spots
  const removeSpot = (id: number) => {
    console.log(spotForms);
    console.log("Removing", id);
    let tmpForms = spotForms;
    tmpForms = tmpForms.filter(function (obj: any) {
      return obj.location.formal !== id;
    });
    console.log("Lets set:", tmpForms);
    setSpotForms(tmpForms);
    console.log("")
    if (tmpForms.length === 0) {
      setSaveForm(false)
    }
  };

  const [open, setOpen] = useState(false);
  const [opens, setOpens] = useState(false);
  const [spotInModal, setSpotInModal] = useState<any>({});
  const handleOpen = () => setOpen(true);
  const handleClose = () => setOpen(false);
  const spotModalAction = (spotObject: any) => {
    setSpotInModal(spotObject);
    setOpens(true);

  };

  const [openSucess, setOpenSucess] = useState(false);
  const handleOpenSucess = () => {
    setSpotForms([]);
    setOpenSucess(true);
  };

  const [openFailure, setOpenFailure] = useState(false);
  const handleOpenFailure = () => {
    // setSpotForms([])
    setOpenFailure(true);
  };
  const handleCloseSucess = () => setOpenSucess(false);
  // Dattime
  const { RangePicker } = DatePicker;

  type RangeValue = [Dayjs | null, Dayjs | null] | null;
  const [dates, setDates] = useState<RangeValue>(null);
  const [valueRange, setValueRange] = useState<RangeValue>(null);


  const onOpenChange = (open: boolean) => {
    if (open) {
      setDates([null, null]);
    } else {
      setDates(null);
    }
  };

  return (
    <>
      {pageLoaded ? (
        <>
          <Grid container style={{ minHeight: "80vh" }}>
            <Grid
              container spacing={2}
            >
              <Grid
                xs={12} item container
                direction="column"
                alignItems="center"
                justifyContent="center">

                <Typography
                  variant="h6"
                  sx={{
                    background: "linear-gradient(180deg, #964cff, #bb2cc0)",
                    backgroundClip: "text", textFillColor: "transparent", fontSize: "1.8rem",
                    marginBottom: "0.5vh",
                    textDecorationThickness: "0.35vh"
                  }}
                >
                  Register:
                </Typography>
              </Grid>

              <Grid lg={12}
                md={12}
                xs={12} container
                direction="column"
                alignItems="center"
                justifyContent="center"
              >
                <Grid container>
                  {searchAddressPassed ? (
                    <><Grid item lg={12} md={12} xs={11} direction="column"
                      alignItems="center"
                      justifyContent="center">
                      <TextField
                        required
                        id="outlined-required"
                        label="Address"
                        value={searchAddress}
                        onChange={(e) => {
                          setSearchAddress(e.target.value);
                          setSearchAddressPassed(true);
                        }}
                        sx={{
                          width: "80%",
                          marginBottom: "4vh",
                          marginTop: "4vh",
                          color: "#000",
                          "& .MuiOutlinedInput-root": {
                            color: "#000",
                          },
                          "& .MuiOutlinedInput-root.Mui-focused": {
                            "& > fieldset": {
                              borderColor: "#9753f7",
                            },
                          },
                        }}
                      />
                    </Grid>
                      <Grid item lg={2} md={12} xs={12}>
                        <Button
                          variant="contained"
                          type="submit"
                          color="secondary"
                          onClick={onSearch}
                          sx={{
                            backgroundColor: "#9753f7",
                            color: "white",
                            marginRight: "5px",
                            marginBottom: {
                              xs: "5vh",
                              sm: "5vh",
                              lg: "1vh",
                              xl: "1vh",
                            },
                            "&: hover": {
                              opacity: "0.8",
                            },
                          }}
                        >
                          Search
                        </Button>
                      </Grid></>
                  ) : (
                    <Grid item lg={12} md={12} xs={11} direction="column"
                      alignItems="center"
                      justifyContent="center">
                      <TextField
                        required
                        id="outlined-required"
                        label="Address"
                        value={searchAddress}
                        onChange={(e) => {
                          setSearchAddress(e.target.value);
                          setSearchAddressPassed(true);
                        }}
                        sx={{
                          width: "80%",
                          marginTop: "4vh",
                          marginBottom: "4vh",
                          color: "#000",
                          "& .MuiOutlinedInput-root": {
                            color: "#000",
                          },
                          "& .MuiOutlinedInput-root.Mui-focused": {
                            "& > fieldset": {
                              borderColor: "#9753f7",
                            },
                          },
                        }}
                      />
                    </Grid>
                  )}

                </Grid>
              </Grid>
            </Grid>

            <Grid
              item
              xs={12}
              container
              direction="column"
              alignItems="center"
              justifyContent="center"
            >
              <>{isLoaded ? (
                <Grid container>
                  <Grid
                    container
                    item
                    sx={{
                      minHeight: "50vh",
                      width: "90%",
                      display: "flex",
                      justifyContent: "center",
                      margin: "auto auto"
                    }}>

                    <GoogleMap
                      mapContainerStyle={containerStyle}
                      options={{
                        backgroundColor: "#9753f7",
                        disableDoubleClickZoom: false,
                        zoomControl: true,
                        fullscreenControl: false,
                        styles: exampleMapStyles,
                        streetViewControl: false,
                      }}
                      center={center}
                      zoom={10}
                      onLoad={onLoad}
                      onUnmount={onUnmount}
                      onClick={onClick}
                    >
                      {/* Child components, such as markers, info windows, etc. */}
                      <>
                        {showMarker && (
                          <MarkerF
                            position={{ lat: markerLat, lng: markerLong }}
                          />
                        )}
                      </>
                    </GoogleMap>
                  </Grid>
                  <br />

                  {validLocation && (
                    <Grid container md={12} xs={12} >
                      {photoLoaded ? (<>
                        <Grid item
                          xs={12}
                          direction="column"
                          alignItems="center"
                          justifyItems="center">
                          <label htmlFor="upload" >

                            <CardMedia
                              src={file}
                              component="img"
                              sx={{
                                maxWidth: { xs: "50%", lg: "20%" },
                                margin: "3vh auto",
                                objectFit: "cover",
                                objectPosition: "center",
                              }}
                            />
                          </label>

                          <label htmlFor="avatar" />
                          <br />

                          <Button
                            variant="contained"
                            component="label"
                            color="secondary"
                            sx={{
                              backgroundColor: "#9753f7",
                              color: "white",
                              marginRight: "5px",
                              marginTop: "4vh",
                              marginBottom: "4vh"
                            }}
                          >
                            Upload File
                            <input
                              type="file"
                              id="upload"
                              onChange={handleImage}
                              accept="image/*"
                              hidden
                              style={{ textAlign: "center" }}
                            />
                          </Button>
                        </Grid></>
                      ) : (
                        <>
                          <Grid
                            container
                            spacing={2}
                            direction="column"
                            alignItems="center"
                            justifyContent="center"
                          >
                            <Grid item lg={12} md={12} xs={12}>
                              <BounceLoader
                                color="#9753f7"
                                cssOverride={{}}
                                loading
                                size={50}
                              />
                            </Grid>
                          </Grid>
                        </>
                      )}
                      <Grid
                        item
                        xs={12}
                        sx={{ paddingBottom: "1vh", display: "flex", flexDirection: { xs: "column", md: "row" } }}
                        mt={"3%"}
                        mb={"2%"}
                      >
                        <Grid item lg={6} md={6} xs={12}>
                          <TextField
                            required
                            id="outlined-required"
                            label="Name"
                            value={getValues("name")}
                            onChange={(e) => {
                              setSpotName(e.target.value);
                              setValue('name', e.target.value)
                            }}
                            sx={{
                              width: "80%",
                              marginTop: { xs: "10vh", md: "2%" },
                              marginBottom: {
                                xs: "12%",
                                sm: "12%",
                                md: "15%",
                                lg: "4vh",

                              },
                              "& .MuiOutlinedInput-root.Mui-focused": {
                                "& > fieldset": {
                                  borderColor: "#9753f7",
                                },
                              },
                            }}
                          />
                        </Grid>

                        <Grid item lg={6} md={6} xs={12}>
                          <TextField
                            required
                            id="outlined-required"
                            label="Description"
                            value={spotDesciption}
                            onChange={(e) => {
                              setSpotDescription(e.target.value);
                            }}
                            sx={{
                              width: "80%",
                              marginTop: "2%",
                              marginBottom: "4vh",
                              "& .MuiOutlinedInput-root.Mui-focused": {
                                "& > fieldset": {
                                  borderColor: "#9753f7",
                                },
                              },
                            }}
                          />
                        </Grid>
                      </Grid>

                      <br />

                      <Grid item lg={12} md={12} xs={12} container>
                        <Grid
                          item
                          lg={3}
                          md={4}
                          xs={12}
                          ml={5}
                          sx={{ marginLeft: { xs: "5vw", lg: "3vw" } }}
                        >
                          <FormGroup>
                            <FormControlLabel
                              control={
                                <Checkbox
                                  onChange={(
                                    e: React.ChangeEvent<HTMLInputElement>
                                  ) => {
                                    setWheelchair(e.target.checked);
                                  }}
                                />
                              }
                              label="Wheelchair"
                              sx={{ marginLeft: "0.2vw" }}
                            />
                          </FormGroup>
                        </Grid>

                        <Grid
                          item
                          lg={3}
                          md={4}
                          xs={12}
                          sx={{ marginLeft: { xs: "5vw", sm: "5vw", lg: "0.5vw" } }}
                        >
                          <FormGroup>
                            <FormControlLabel
                              control={
                                <Checkbox
                                  onChange={(
                                    e: React.ChangeEvent<HTMLInputElement>
                                  ) => {
                                    setMultiSpot(e.target.checked);
                                  }}
                                />
                              }
                              label="Multiple Parkings"
                              sx={{ marginLeft: "0.2vw" }}

                            />
                            {mulitspot && (
                              <TextField
                                required
                                id="outlined-required"
                                label="Total spots"
                                value={spotTotal}
                                onChange={(e: any) => { setSpotTotal(e.target.value) }}
                                style={{ width: "60%" }}
                              />
                            )}
                          </FormGroup>

                        </Grid>

                        <Grid item lg={3} md={3} xs={12}>
                          <FormControl
                            fullWidth
                            sx={{
                              "& .MuiOutlinedInput-inputAdornedStart.MuiOutlinedInput-adornedStart .MuiOutlinedInput-root.Mui-focused":
                              {
                                borderColor: "#9753f7",
                              },
                            }}
                          >
                            <InputLabel
                              htmlFor="outlined-adornment-amount"
                              sx={{
                                marginLeft: { xs: "8vw", lg: "0vw" },
                                marginTop: { xs: "8%", md: "6vh", lg: "0%" },
                              }}
                            >
                              Amount
                            </InputLabel>
                            <OutlinedInput
                              id="outlined-adornment-amount"
                              startAdornment={
                                <InputAdornment position="start">
                                  R
                                </InputAdornment>
                              }
                              label="Amount"
                              value={spotPrice}
                              onChange={(e) => {
                                setSpotPrice(Number(e.target.value));
                              }}
                              sx={{
                                marginLeft: { xs: "8vw", lg: "0vw" },
                                marginTop: { xs: "8%", md: "6vh", lg: "0%" },
                                width: "80%",
                                "& .MuiOutlinedInput-inputAdornedStart.MuiOutlinedInput-adornedStart .MuiOutlinedInput-root.Mui-focused":
                                {
                                  borderColor: "#9753f7",
                                  color: "#000",
                                },
                              }}
                            />
                          </FormControl>
                        </Grid>
                      </Grid>


                    </Grid>

                  )}
                  {validLocation && (
                    <Grid
                      item
                      lg={12}
                      md={12}
                      xs={12}
                      container
                      spacing={0}
                      direction="column"
                      alignItems="center"
                      justifyContent="center"
                    >
                      <Box
                        sx={{
                          borderBottom: 1,
                          borderColor: "divider",
                          width: "100%",
                          marginTop: "20px",
                          position: "flex",
                        }}
                      >
                        <Tabs
                          value={value}
                          onChange={handleChange}
                          aria-label="basic tabs example"
                          textColor="secondary"
                          indicatorColor="secondary"
                          variant="fullWidth"
                          sx={{ width: "100%" }}
                        >
                          <Tab
                            sx={{ width: "50%", marginTop: "4%" }}
                            label="Day Selection"
                            {...a11yProps(0)}
                          />
                          <Tab
                            sx={{ width: "50%", marginTop: "4%" }}
                            label="Date Selection"
                            {...a11yProps(1)}
                          />
                        </Tabs>
                      </Box>
                      <TabPanel value={value} index={0}>
                        <p style={{ marginBottom: "3vh" }}>Chose the days and time your spot is available.</p>
                        <p style={{ marginBottom: "3vh" }}>(You can change this at any time after creating your spot.)</p>


                        <Grid container spacing={2}>
                          <Grid
                            item
                            lg={12}
                            md={12}
                            xs={12}
                            container
                            spacing={0}
                          >
                            <WeekScheduleSelector onWeekScheduleChange={handleWeekScheduleChange} />

                          </Grid>
                        </Grid>



                      </TabPanel>

                      {/* Signup */}
                      <TabPanel value={value} index={1}>
                        <div
                          style={{ minHeight: "20vh" }}
                        >
                          <p style={{ marginBottom: "3vh" }}>Chose between the dates your spot will be available.</p>
                          <p style={{ marginBottom: "3vh" }}>(You can change this at any time after creating your spot.)</p>
                          <DateRangeSelector onDateRangeChange={handleDateRangeChange} ></DateRangeSelector>


                        </div>

                      </TabPanel>
                      {isValid && (
                        <Button
                          variant="contained"
                          type="submit"
                          color="secondary"
                          onClick={addSpot}
                          sx={{
                            backgroundColor: "#9753f7",
                            color: "white",
                            marginRight: "5px",
                            margin: "2%",
                          }}
                        >
                          Add spot
                        </Button>
                      )

                      }
                    </Grid>
                  )}
                </Grid>
              ) : (
                <></>
              )}
                <br />

                <br />
                <br />
                {spotForms.length !== 0 && (
                  <>

                    <Typography
                      variant="h6"
                      sx={{
                        color: "#000",
                        marginBottom: "0.5vh",
                        textDecorationThickness: "0.35vh",
                      }}
                    >
                      Your new spots:
                    </Typography>
                    <br />
                  </>
                )}
                <Grid
                  container
                  spacing={6}
                  alignItems="center"
                  justifyContent="center"
                >
                  {spotForms.map((obj: any, index: any) => {
                    return (
                      <>
                        <Grid
                          item
                          xs={12}
                          md={6}
                          xl={3}
                          direction="column"
                          alignItems="center"
                          justifyContent="center"
                          key={obj.location.formal}
                        >
                          <DefaultProjectCard
                            id={obj.location.formal}
                            image={obj.photoUrl || backgroundImage}
                            label=""
                            title={obj.location.formal}
                            description={obj.description}
                            action={spotModalAction}
                            deleteAction={removeSpot}
                            authors={[]}
                          />
                        </Grid>
                      </>
                    );
                  })}
                </Grid>

                {saveForm && (
                  <>
                    <Button
                      variant="contained"
                      type="submit"
                      color="secondary"
                      onClick={applyForHost}
                      sx={{
                        backgroundColor: "#9753f7",
                        color: "white",
                        margin: "2.37vw",
                        marginTop: { xs: "18%", lg: "5%" },
                      }}
                    >
                      Apply
                    </Button>
                    {emailChanged && (
                      <h5>
                        You are about to save your application with an email not
                        linked to your profile. Add will only be added as an
                        additional email to add. If a new acount is created with
                        this email they will get delegate access.{" "}
                      </h5>
                    )}
                  </>
                )}
              </>

              {/* Spots form end */}
            </Grid>
          </Grid>

          {/* Modal if host data needed */}
          <Modal
            aria-labelledby="transition-modal-title"
            aria-describedby="transition-modal-description"
            open={open}
            onClose={handleClose}
            closeAfterTransition
          >
            <Fade in={open}>
              <Box
                sx={{
                  position: "absolute" as "absolute",
                  top: "50%",
                  left: "50%",
                  transform: "translate(-50%, -50%)",
                  width: { xs: "60%", lg: "80%" },
                  height: { xs: "85%", lg: "60%" },
                  overflow: { xs: "scroll", lg: "hidden" },
                  bgcolor: "background.paper",
                  border: "0.5vh solid #9753f7",
                  boxShadow: 24,
                  p: 4,
                }}
              >
                <Typography
                  id="transition-modal-title"
                  variant="h6"
                  component="h2"
                  sx={{ fontSize: { xs: "1rem", lg: "1.2rem" } }}
                >
                  We require your banking details.
                </Typography>
                <Typography id="transition-modal-description" sx={{ mt: 2 }}>
                  This will be the banking infromation linked to your account
                  where we do payouts to.
                </Typography>
                <Divider
                  sx={{
                    textDecorationThickness: "0.3vh",
                    marginTop: "3vh",
                    marginBottom: "2vh",
                  }}
                >
                  Host Information
                </Divider>{" "}
                <br />
                <Grid container spacing={2}>
                  <Grid item lg={3} md={3} xs={12} container>
                    <TextField
                      required
                      id="outlined-required"
                      label="First Name"
                      value={firstName}
                      onChange={(e) => {
                        setFirstName(e.target.value);
                      }}
                      sx={{
                        "& .MuiOutlinedInput-root.Mui-focused": {
                          "& > fieldset": {
                            borderColor: "#9753f7",
                          },
                        },
                      }}
                    />
                  </Grid>

                  <Grid item lg={3} md={3} xs={12} container>
                    <TextField
                      required
                      id="outlined-required"
                      label="Second Name"
                      value={lastName}
                      onChange={(e) => {
                        setLastName(e.target.value);
                      }}
                      sx={{
                        "& .MuiOutlinedInput-root.Mui-focused": {
                          "& > fieldset": {
                            borderColor: "#9753f7",
                          },
                        },
                      }}
                    />
                  </Grid>
                  <Grid item lg={3} md={3} xs={12} container>
                    <TextField
                      required
                      id="outlined-required"
                      label="Email"
                      value={email}
                      onChange={(e) => {
                        setEmail(e.target.value);
                        setEmailChanged(true);
                      }}
                      sx={{
                        "& .MuiOutlinedInput-root.Mui-focused": {
                          "& > fieldset": {
                            borderColor: "#9753f7",
                          },
                        },
                      }}
                    />
                  </Grid>

                  <Grid item lg={3} md={3} xs={12} container>
                    <TextField
                      required
                      id="outlined-required"
                      label="Phone Number"
                      value={phoneNumber}
                      onChange={(e) => {
                        setPhoneNumber(e.target.value);
                      }}
                      sx={{
                        "& .MuiOutlinedInput-root.Mui-focused": {
                          "& > fieldset": {
                            borderColor: "#9753f7",
                          },
                        },
                      }}
                    />
                  </Grid>
                </Grid>
                <br />
                <Divider
                  sx={{
                    textDecorationThickness: "0.3vh",
                    marginTop: "2vh",
                  }}
                >
                  {" "}
                  Banking Information{" "}
                </Divider>{" "}
                <br />
                <Grid container spacing={2}>
                  <Grid item lg={6} md={6} xs={12} container>
                    <TextField
                      required
                      id="outlined-required"
                      label="Account Number"
                      style={{ width: "90%" }}
                      value={bankAccNumber}
                      onChange={(e) => {
                        setBankAccNumber(e.target.value);
                      }}
                      sx={{
                        "& .MuiOutlinedInput-root.Mui-focused": {
                          "& > fieldset": {
                            borderColor: "#9753f7",
                          },
                        },
                      }}
                    />
                  </Grid>

                  <Grid item lg={3} md={3} xs={12} container>
                    <TextField
                      required
                      id="outlined-required"
                      label="Bank Code"
                      value={bankCode}
                      onChange={(e) => {
                        setBankCode(e.target.value);
                      }}
                      sx={{
                        "& .MuiOutlinedInput-root.Mui-focused": {
                          "& > fieldset": {
                            borderColor: "#9753f7",
                          },
                        },
                      }}
                    />
                  </Grid>

                  <Grid item lg={3} md={3} xs={12} container>
                    <FormControl fullWidth>
                      <InputLabel id="demo-simple-select-label">
                        Bank Name
                      </InputLabel>
                      <Select
                        labelId="demo-simple-select-label"
                        id="demo-simple-select"
                        value={bankName}
                        label="Bank Name"
                        onChange={changeBank}
                        sx={{
                          "&.Mui-focused .MuiOutlinedInput-notchedOutline": {
                            color: "#000",
                            borderColor: "#9753f7",
                          },
                        }}
                      >
                        <Divider
                          sx={{
                            textDecorationThickness: "0.3vh",
                            marginTop: "0.01vh",
                            marginBottom: "3%",
                          }}
                        >
                          <Typography variant="subtitle1"> Popular </Typography>
                        </Divider>

                        <MenuItem
                          value={"Absa Bank Limited, South Africa"}
                          style={{ width: "80%" }}
                        >
                          <Typography
                            variant="subtitle1"
                            color="#000"
                            sx={{
                              marginX: "2%",
                              "&:hover": {
                                color: "#5A1ABC",
                              },
                            }}
                          >
                            Absa Bank Limited, South Africa
                          </Typography>
                        </MenuItem>

                        <MenuItem
                          value={"African Bank Limited"}
                          style={{ width: "100%" }}
                        >
                          <Typography
                            variant="subtitle1"
                            color="#000"
                            sx={{
                              marginX: "2%",
                              "&:hover": {
                                color: "#5A1ABC",
                              },
                            }}
                          >
                            African Bank Limited
                          </Typography>
                        </MenuItem>

                        <MenuItem value={"Bank Zero"}>
                          <Typography
                            variant="subtitle1"
                            color="#000"
                            sx={{
                              marginX: "2%",
                              "&:hover": {
                                color: "#5A1ABC",
                              },
                            }}
                          >
                            Bank Zero
                          </Typography>
                        </MenuItem>

                        <MenuItem value={"Bidvest Bank Limited"}>
                          <Typography
                            variant="subtitle1"
                            color="#000"
                            sx={{
                              marginX: "2%",
                              "&:hover": {
                                color: "#5A1ABC",
                              },
                            }}
                          >
                            Bidvest Bank Limited
                          </Typography>
                        </MenuItem>

                        <MenuItem value={"Capitec Bank Limited"}>
                          <Typography
                            variant="subtitle1"
                            color="#000"
                            sx={{
                              marginX: "2%",
                              "&:hover": {
                                color: "#5A1ABC",
                              },
                            }}
                          >
                            Capitec Bank Limited
                          </Typography>
                        </MenuItem>

                        <MenuItem value={"Discovery Bank Limited"}>
                          <Typography
                            variant="subtitle1"
                            color="#000"
                            sx={{
                              marginX: "2%",
                              "&:hover": {
                                color: "#5A1ABC",
                              },
                            }}
                          >
                            Discovery Bank Limited
                          </Typography>
                        </MenuItem>

                        <MenuItem value={"First National Bank"}>
                          <Typography
                            variant="subtitle1"
                            color="#000"
                            sx={{
                              marginX: "2%",
                              "&:hover": {
                                color: "#5A1ABC",
                              },
                            }}
                          >
                            First National Bank
                          </Typography>
                        </MenuItem>

                        <MenuItem value={"Nedbank"}>
                          <Typography
                            variant="subtitle1"
                            color="#000"
                            sx={{
                              marginX: "2%",
                              "&:hover": {
                                color: "#5A1ABC",
                              },
                            }}
                          >
                            Nedbank
                          </Typography>
                        </MenuItem>

                        <MenuItem value={"Standard Bank South Africa"}>
                          <Typography
                            variant="subtitle1"
                            color="#000"
                            sx={{
                              marginX: "2%",
                              "&:hover": {
                                color: "#5A1ABC",
                              },
                            }}
                          >
                            Standard Bank South Africa
                          </Typography>
                        </MenuItem>

                        <MenuItem value={"TymeBank"}>
                          <Typography
                            variant="subtitle1"
                            color="#000"
                            sx={{
                              marginX: "2%",
                              "&:hover": {
                                color: "#5A1ABC",
                              },
                            }}
                          >
                            TymeBank
                          </Typography>
                        </MenuItem>

                        <Divider
                          color="#9753f7"
                          sx={{
                            // background: "#9753f7",
                            textDecorationThickness: "0.3vh",
                            marginTop: "0.01vh",
                            marginBottom: "3%",
                            fontFamily: "Roboto Condensed",
                          }}
                        >
                          {" "}
                          <Typography variant="subtitle1">
                            {" "}
                            Other{" "}
                          </Typography>{" "}
                        </Divider>

                        <MenuItem value={"Albaraka Bank"}>
                          <Typography
                            variant="subtitle1"
                            color="#000"
                            sx={{
                              marginX: "2%",
                              "&:hover": {
                                color: "#5A1ABC",
                              },
                            }}
                          >
                            Albaraka Bank
                          </Typography>
                        </MenuItem>

                        <MenuItem value={"CitiBank"}>
                          <Typography
                            variant="subtitle1"
                            color="#000"
                            sx={{
                              marginX: "2%",
                              "&:hover": {
                                color: "#5A1ABC",
                              },
                            }}
                          >
                            CitiBank
                          </Typography>
                        </MenuItem>

                        <MenuItem value={"Finbond EPE"}>
                          <Typography
                            variant="subtitle1"
                            color="#000"
                            sx={{
                              marginX: "2%",
                              "&:hover": {
                                color: "#5A1ABC",
                              },
                            }}
                          >
                            Finbond EPE
                          </Typography>
                        </MenuItem>

                        <MenuItem value={"Finbond Mutual Bank"}>
                          <Typography
                            variant="subtitle1"
                            color="#000"
                            sx={{
                              marginX: "2%",
                              "&:hover": {
                                color: "#5A1ABC",
                              },
                            }}
                          >
                            Finbond Mutual Bank
                          </Typography>
                        </MenuItem>

                        <MenuItem value={"FirstRand Bank"}>
                          <Typography
                            variant="subtitle1"
                            color="#000"
                            sx={{
                              marginX: "2%",
                              "&:hover": {
                                color: "#5A1ABC",
                              },
                            }}
                          >
                            FirstRand Bank
                          </Typography>
                        </MenuItem>

                        <MenuItem value={"Grindrod Bank"}>
                          <Typography
                            variant="subtitle1"
                            color="#000"
                            sx={{
                              marginX: "2%",
                              "&:hover": {
                                color: "#5A1ABC",
                              },
                            }}
                          >
                            Grindrod Bank
                          </Typography>
                        </MenuItem>

                        <MenuItem value={"HSBC South Africa"}>
                          <Typography
                            variant="subtitle1"
                            color="#000"
                            sx={{
                              marginX: "2%",
                              "&:hover": {
                                color: "#5A1ABC",
                              },
                            }}
                          >
                            HSBC South Africa
                          </Typography>
                        </MenuItem>

                        <MenuItem value={"JP Morgan South Africa"}>
                          <Typography
                            variant="subtitle1"
                            color="#000"
                            sx={{
                              marginX: "2%",
                              "&:hover": {
                                color: "#5A1ABC",
                              },
                            }}
                          >
                            JP Morgan South Africa
                          </Typography>
                        </MenuItem>

                        <MenuItem value={"Mercantile Bank"}>
                          <Typography
                            variant="subtitle1"
                            color="#000"
                            sx={{
                              marginX: "2%",
                              "&:hover": {
                                color: "#5A1ABC",
                              },
                            }}
                          >
                            Mercantile Bank
                          </Typography>
                        </MenuItem>

                        <MenuItem value={"Olympus Mobile"}>
                          <Typography
                            variant="subtitle1"
                            color="#000"
                            sx={{
                              marginX: "2%",
                              "&:hover": {
                                color: "#5A1ABC",
                              },
                            }}
                          >
                            Olympus Mobile
                          </Typography>
                        </MenuItem>

                        <MenuItem value={"Rand Merchant Bank"}>
                          <Typography
                            variant="subtitle1"
                            color="#000"
                            sx={{
                              marginX: "2%",
                              "&:hover": {
                                color: "#5A1ABC",
                              },
                            }}
                          >
                            Rand Merchant Bank
                          </Typography>
                        </MenuItem>

                        <MenuItem value={"RMB Private Bank"}>
                          <Typography
                            variant="subtitle1"
                            color="#000"
                            sx={{
                              marginX: "2%",
                              "&:hover": {
                                color: "#5A1ABC",
                              },
                            }}
                          >
                            RMB Private Bank
                          </Typography>
                        </MenuItem>

                        <MenuItem value={"SASFIN Bank"}>
                          <Typography
                            variant="subtitle1"
                            color="#000"
                            sx={{
                              marginX: "2%",
                              "&:hover": {
                                color: "#5A1ABC",
                              },
                            }}
                          >
                            SASFIN Bank
                          </Typography>
                        </MenuItem>

                        <MenuItem value={"Société Générale South Africa"}>
                          <Typography
                            variant="subtitle1"
                            color="#000"
                            sx={{
                              marginX: "2%",
                              "&:hover": {
                                color: "#5A1ABC",
                              },
                            }}
                          >
                            Société Générale South Africa
                          </Typography>
                        </MenuItem>

                        <MenuItem value={"South African Bank of Athens"}>
                          <Typography
                            variant="subtitle1"
                            color="#000"
                            sx={{
                              marginX: "2%",
                              "&:hover": {
                                color: "#5A1ABC",
                              },
                            }}
                          >
                            South African Bank of Athens
                          </Typography>
                        </MenuItem>

                        <MenuItem value={"Standard Chartered Bank"}>
                          <Typography
                            variant="subtitle1"
                            color="#000"
                            sx={{
                              marginX: "2%",
                              "&:hover": {
                                color: "#5A1ABC",
                              },
                            }}
                          >
                            Standard Chartered Bank
                          </Typography>
                        </MenuItem>

                        <MenuItem value={"Ubank Ltd"}>
                          <Typography
                            variant="subtitle1"
                            color="#000"
                            sx={{
                              marginX: "2%",
                              "&:hover": {
                                color: "#5A1ABC",
                              },
                            }}
                          >
                            Ubank Ltd
                          </Typography>
                        </MenuItem>

                        <MenuItem value={"VBS Mutual Bank"}>
                          <Typography
                            variant="subtitle1"
                            color="#000"
                            sx={{
                              marginX: "2%",
                              "&:hover": {
                                color: "#5A1ABC",
                              },
                            }}
                          >
                            VBS Mutual Bank
                          </Typography>
                        </MenuItem>
                      </Select>
                    </FormControl>
                  </Grid>
                </Grid>
                {/* Ask for business */}
                <br />
                <Divider
                  sx={{
                    textDecorationThickness: "0.3vh",
                    marginTop: "2vh",
                  }}
                >
                  {" "}
                  Banking Information{" "}
                </Divider>{" "}
                <br />
                <Grid container spacing={2}>
                  <Grid item lg={3} md={3} xs={12} container>
                    <FormControlLabel
                      control={
                        <Checkbox
                          onChange={(
                            e: React.ChangeEvent<HTMLInputElement>
                          ) => {
                            setLinkBusiness(e.target.checked);
                          }}
                        />
                      }
                      label="Link spots to business"
                      sx={{ marginLeft: "0.2vw" }}

                    />
                  </Grid>
                  {
                    linkBusiness && (<>
                     <Grid item lg={3} md={3} xs={12} container>
                      <TextField
                        required
                        id="outlined-required"
                        label="Business Name"
                        value={businessName}
                        onChange={(e) => {
                          setBusinessName(e.target.value);
                        }}
                        sx={{
                          "& .MuiOutlinedInput-root.Mui-focused": {
                            "& > fieldset": {
                              borderColor: "#9753f7",
                            },
                          },
                        }}
                      />
                      </Grid>
                    </>)
                  }
                </Grid>
                <Button
                  variant="contained"
                  type="submit"
                  color="secondary"
                  onClick={() => {
                    setCanApply(true);
                    applyForHost();
                  }}
                  sx={{
                    backgroundColor: "#9753f7",
                    color: "white",
                    margin: "10px",
                    marginLeft: "2%",
                    marginTop: { xs: "10%", lg: "2%" },
                  }}
                >
                  Apply
                </Button>
              </Box>
            </Fade>
          </Modal>
          {/* Congrats modal */}
          <Modal
            aria-labelledby="transition-modal-title"
            aria-describedby="transition-modal-description"
            open={openSucess}
            onClose={handleCloseSucess}
            closeAfterTransition
          >
            <Fade in={openSucess}>
              <Box
                sx={{
                  position: "absolute" as "absolute",
                  top: "50%",
                  left: "50%",
                  transform: "translate(-50%, -50%)",
                  width: "80%",
                  bgcolor: "background.paper",
                  border: "2px solid #000",
                  boxShadow: 24,
                  p: 4,
                }}
              >
                <Typography
                  id="transition-modal-title"
                  variant="h6"
                  component="h2"
                >
                  Your spots have been added.
                </Typography>
                <Typography id="transition-modal-description" sx={{ mt: 2 }}>
                  You can now view them under your spots on the app or web.
                </Typography>
              </Box>
            </Fade>
          </Modal>
          <Modal
            aria-labelledby="modal-title"
            aria-describedby="modal-desc"
            open={opens}
            onClose={() => setOpens(false)}
            sx={{

              display: "flex",
              justifyContent: "center",
              alignItems: "center",
              overflow: "scroll"
            }}
          >
            <Sheet
              variant="outlined"
              sx={{
                width: { xs: "60%", lg: "initial" },
                height: { xs: "85%", lg: "80%" },
                overflow: { xs: "scroll", lg: "hidden" },
                maxWidth: 500,
                borderRadius: "md",
                p: 3,
                boxShadow: "lg",

              }}
            >
              <ModalClose
                variant="outlined"
                onClick={() => setOpens(false)}
                sx={{
                  backgroundColor: "#9753f7",
                  color: "#fff",
                  borderRadius: "50%",
                  top: 5,
                  right: 5
                }}
              />



              <Typography
                component="h2"
                id="modal-title"
                fontWeight="lg"
                mb={1}
              >

              </Typography>

              <Typography id="modal-desc">
                <Grid
                  item
                  lg={12}
                  md={12}
                  xs={12}
                  container
                  spacing={2}
                  alignItems="center"
                  justifyContent="center"
                >

                  <Grid item xs={12} sm={12} md={12} lg={6} xl={6} >
                    <Item sx={{ boxShadow: "none" }}>
                      <FormGroup>
                        {/* <Input
                            placeholder="Product Type Name"
                            type="text"
                            value={title}
                            onChange={(e) => setName(e.target.value)}
                          /> */}
                        <TextField
                          required
                          id="outlined-required"
                          label="Address"
                          type="text"
                          sx={{
                            width: "100%",
                            marginTop: { xs: "5vh", lg: "6%" },
                            marginBottom: {
                              xs: "12%",
                              sm: "4%",
                              md: "4%",
                              lg: "4%",
                              xl: "4%",
                            },
                            color: "#000",
                            "& .MuiOutlinedInput-root": {
                              color: "#000",
                            },
                            "& .MuiOutlinedInput-root.Mui-focused": {
                              "& > fieldset": {
                                borderColor: "#9753f7",
                              },
                            },
                          }}
                        />
                      </FormGroup>
                    </Item>
                  </Grid>

                  <Grid item xs={12} sm={12} md={12} lg={6} xl={6}>
                    <Item sx={{ boxShadow: "none" }}>
                      <FormGroup>
                        {/* <Input
                            placeholder="Product Type Name"
                            type="text"
                            value={title}
                            onChange={(e) => setName(e.target.value)}
                          /> */}
                        <TextField
                          required
                          id="outlined-required"
                          label="Name"
                          value={spotName}
                          onChange={(e) => {
                            setSpotName(e.target.value);
                          }}
                          sx={{
                            width: "100%",
                            marginTop: { xs: "1vh", lg: "6%" },
                            marginBottom: {
                              xs: "12%",
                              sm: "4%",
                            },
                            "& .MuiOutlinedInput-root.Mui-focused": {
                              "& > fieldset": {
                                borderColor: "#9753f7",
                              },
                            },
                          }}
                        />
                      </FormGroup>
                    </Item>
                  </Grid>

                  <Grid item xs={12} sm={12} md={12} lg={6} xl={6}>
                    <Item sx={{ boxShadow: "none" }}>
                      <FormGroup>
                        <TextField
                          required
                          id="outlined-required"
                          label="Description"
                          value={spotDesciption}
                          onChange={(e) => {
                            setSpotDescription(e.target.value);
                          }}
                          sx={{
                            width: "100%",
                            marginTop: "2%",
                            marginBottom: "4%",
                            "& .MuiOutlinedInput-root.Mui-focused": {
                              "& > fieldset": {
                                borderColor: "#9753f7",
                              },
                            },
                          }}
                        />
                      </FormGroup>
                    </Item>
                  </Grid>

                  <Grid item xs={12} sm={12} md={12} lg={6} xl={6}>
                    <Item sx={{ boxShadow: "none" }}>
                      <FormControl
                        fullWidth
                        sx={{
                          "& .MuiOutlinedInput-inputAdornedStart.MuiOutlinedInput-adornedStart .MuiOutlinedInput-root.Mui-focused":
                          {
                            borderColor: "#9753f7",
                          },
                        }}
                      >
                        <InputLabel
                          htmlFor="outlined-adornment-amount"
                          sx={{
                            marginBottom: "4%",
                            marginTop: { xs: "8%", sm: "4%", md: "4%", lg: "2%" },
                          }}
                        >
                          Amount
                        </InputLabel>
                        <OutlinedInput
                          id="outlined-adornment-amount"
                          startAdornment={
                            <InputAdornment position="start">
                              R
                            </InputAdornment>
                          }
                          label="Amount"
                          value={spotPrice}
                          onChange={(e) => {
                            setSpotPrice(Number(e.target.value));
                          }}
                          sx={{
                            marginBottom: "4%",
                            marginTop: { xs: "8%", sm: "4%", md: "4%", lg: "2%" },
                            width: "100%",
                            "& .MuiOutlinedInput-inputAdornedStart.MuiOutlinedInput-adornedStart .MuiOutlinedInput-root.Mui-focused":
                            {
                              borderColor: "#9753f7",
                              color: "#000",
                            },
                          }}
                        />
                      </FormControl>
                    </Item>
                  </Grid>
                </Grid>

                <Grid
                  item
                  lg={12}
                  md={12}
                  xs={12}
                  container
                  spacing={2}
                  alignItems="center"
                  justifyContent="center" mt={2}>

                  <Grid item xs={12} sm={12} md={12} lg={6} xl={6}>
                    <Item sx={{ boxShadow: "none" }}>
                      <FormGroup>
                        <FormControlLabel
                          control={
                            <Checkbox
                              onChange={(e) => {
                                setWheelchair(e.target.checked);
                              }}
                            />
                          }
                          label="Wheelchair"
                          sx={{ marginLeft: "0.5vw" }}
                        />
                      </FormGroup>
                    </Item>
                  </Grid>

                  <Grid item xs={12} sm={12} md={12} lg={6} xl={6} >
                    <Item sx={{ boxShadow: "none" }}>
                      <FormGroup>
                        <FormControlLabel
                          control={
                            <Checkbox
                              onChange={(e) => {
                                setMultiSpot(e.target.checked);
                              }}
                            />
                          }
                          label="Multiple Parkings"
                          sx={{
                            marginLeft: {
                              xs: "0.5vw",
                              sm: "0.5vw",
                              md: "2%",
                              lg: "-2%",
                              xl: "-2%",
                            },
                          }}
                        />
                      </FormGroup>
                    </Item>
                  </Grid>
                </Grid>

                <Grid
                  item
                  lg={12}
                  md={12}
                  xs={12}
                  container
                  spacing={0}
                  direction="column"
                  alignItems="center"
                  justifyContent="center"
                >
                  <Box
                    sx={{
                      borderBottom: 1,
                      borderColor: "divider",
                      width: "100%",
                      marginTop: "20px",
                      position: "flex",
                    }}
                  >
                    <Tabs
                      value={value}
                      onChange={handleChange}
                      aria-label="basic tabs example"
                      textColor="secondary"
                      indicatorColor="secondary"
                      variant="fullWidth"
                      sx={{ width: "100%" }}
                    >
                      <Tab
                        sx={{ width: "50%", marginTop: "4%" }}
                        label="Schedule"
                        {...a11yProps(0)}
                      />
                      <Tab
                        sx={{ width: "50%", marginTop: "4%" }}
                        label="Dates"
                        {...a11yProps(1)}
                      />
                    </Tabs>
                  </Box>

                  <TabPanel value={value} index={0}>
                    <Typography
                      variant="subtitle1"
                      sx={{
                        textAlign: "center",
                        color: "#000",
                        textDecoration: "underline",
                        textDecorationColor: "#9753f7",
                        textDecorationThickness: "0.3vh",
                        marginTop: "0.01vh",
                      }}
                    >
                      Set Schedule
                    </Typography>

                    <Grid container spacing={2} mt={2}>
                      <Grid
                        item
                        lg={12}
                        md={12}
                        xs={12}
                        container
                        spacing={0}
                      >
                      </Grid>
                    </Grid>


                  </TabPanel>

                  {/* Signup */}
                  <TabPanel value={value} index={1}>
                    <RangePicker
                      value={dates || valueRange}
                      onCalendarChange={(val) => setDates(val)}
                      onChange={(val) => setValueRange(val)}
                      onOpenChange={onOpenChange}
                      style={{ width: "100%" }}
                    />
                    <TimePicker.RangePicker
                      style={{ width: "100%", marginTop: "4vh" }}
                    />
                  </TabPanel>
                </Grid>
              </Typography>
            </Sheet>
          </Modal>
        </>
      ) : (
        <Grid
          container
          spacing={2}
          style={{ minHeight: "50vh", paddingBottom: "20px" }}
          direction="column"
          alignItems="center"
          justifyContent="center"
        >
          <Grid item lg={12} md={12} xs={12}>
            <BounceLoader color="#9753f7" cssOverride={{}} loading size={100} />
          </Grid>
        </Grid>
      )}
    </>
  );
}
