import { ContactUs } from "./pages/contact/contactUs";
import { FAQ } from "./pages/faq/faq";
import { Home } from "./pages/home/home";
import { Host } from "./pages/host/host";
import { About } from "./pages/about/about";
import { Profile } from './pages/userHome/userHome';
import { Bookings } from './pages/userHome/bookings';
import { Spots } from './pages/userHome/spots';
import { Billing } from './pages/userHome/billing';
import { PaymentStatus } from './pages/userHome/payment';
import { Privacy } from "./pages/privacy/privacy";
import { TAndC } from "./pages/tAndC/tAndC";
import Login from "./pages/auth/Login";
import ValidateEmail from "./pages/auth/ValidateEmail";
import { Spot } from "./pages/userHome/spot";

export interface AppRoute{
    Navigation: string,
    DisplayName: string,
    Protected: boolean,
    Component: () => JSX.Element,
    NavBarVisibile: boolean
}

export const CustomRoutes : AppRoute[] = [
    {
        DisplayName: 'Home',
        Navigation: '/home',
        Protected: false,
        Component: Home,
        NavBarVisibile: true
    },
    {
        DisplayName: 'About',
        Navigation: '/about',
        Protected: false,
        Component: About,
        NavBarVisibile: true
    },
    {
        DisplayName: 'FAQ',
        Navigation: '/faq',
        Protected: false,
        Component: FAQ,
        NavBarVisibile: true
    },
    {
        DisplayName: 'Contact',
        Navigation: '/contact-us',
        Protected: false,
        Component: ContactUs,
        NavBarVisibile: true
    },
    {
        DisplayName: 'Host',
        Navigation: '/host',
        Protected: false,
        Component: Host,
        NavBarVisibile: true
    },
    // {
    //     DisplayName: 'Validate',
    //     Navigation: '/validate',
    //     Protected: false,
    //     Component: ValidateEmail,
    //     NavBarVisibile: false
    // },
    {
        DisplayName: 'Privacy',
        Navigation: '/privacy',
        Protected: false,
        Component: Privacy,
        NavBarVisibile: false
    },
    {
        DisplayName: 'T&C',
        Navigation: '/terms-conditions',
        Protected: false,
        Component: TAndC,
        NavBarVisibile: false
    },
    {
        DisplayName: 'Login',
        Navigation: '/login',
        Protected: false,
        Component: Login,
        NavBarVisibile: false
    },
    {
        DisplayName: 'Signup',
        Navigation: '/signup',
        Protected: false,
        Component: Login,
        NavBarVisibile: false
    },
    {
        DisplayName: 'My Spots',
        Navigation: '/spot',
        Protected: true,
        Component: Spot,
        NavBarVisibile: false
    },
    
]

export const SettingRoutes : AppRoute[] = [
    {
        DisplayName: 'Profile',
        Navigation: '/profile',
        Protected: true,
        Component: Profile,
        NavBarVisibile: true
    },
    {
        DisplayName: 'Host Calendar',
        Navigation: '/booking',
        Protected: true,
        Component: Bookings,
        NavBarVisibile: true
    },
    {
        DisplayName: 'My Spots',
        Navigation: '/spots',
        Protected: true,
        Component: Spots,
        NavBarVisibile: true
    },
    {
        DisplayName: 'Billing',
        Navigation: '/billing',
        Protected: true,
        Component: Billing,
        NavBarVisibile: true
    },
    {
        DisplayName: 'Confirmation',
        Navigation: '/confirmation',
        Protected: false,
        Component: PaymentStatus,
        NavBarVisibile: false
    },
]