import { TextField } from "@aws-amplify/ui-react";
import { ListItem, ListItemAvatar, ListItemText, Button, List, Avatar, Typography  } from "@mui/material";
import { useState } from "react";

interface Delegate {
    name: string;
    avatarUrl: string;
}

interface Spot {
    management?: {
        delegates?: Record<string, Delegate>;
    };
}

interface UserListProps {
    spot: Spot;
}


export const UserList: React.FC<UserListProps> = ({ spot }) => {
    const [newDelegateName, setNewDelegateName] = useState('');
    const [newDelegateAvatarUrl, setNewDelegateAvatarUrl] = useState('');
    const [isFormOpen, setIsFormOpen] = useState(false);

    const delegates: Record<string, Delegate> = spot?.management?.delegates || {};

    const handleFormSubmit = (event: React.FormEvent) => {
        event.preventDefault();

        // Generate a unique delegate ID
        const newDelegateId = `delegate_${Date.now()}`;

        // Add the new delegate to the list
        delegates[newDelegateId] = {
            name: newDelegateName,
            avatarUrl: newDelegateAvatarUrl,
        };

        // Clear the form inputs
        setNewDelegateName('');
        setNewDelegateAvatarUrl('');

        // Close the form
        setIsFormOpen(false);
    };

    return (
        <div>
            {isFormOpen && (
                <form onSubmit={handleFormSubmit}>
                    <TextField
                        label="Delegate Name"
                        value={newDelegateName}
                        onChange={(e) => setNewDelegateName(e.target.value)}
                        required
                        style={{borderRadius: "0.2vw", height: "4vh", marginTop: "2vh", marginBottom: "2vh", fontFamily: "Roboto Condensed", width: "15vw"}}
                    />
                    <TextField
                        label="Avatar URL"
                        value={newDelegateAvatarUrl}
                        onChange={(e) => setNewDelegateAvatarUrl(e.target.value)}
                        required
                        style={{borderRadius: "0.2vw", height: "4vh", marginTop: "2vh", marginBottom: "2vh", fontFamily: "Roboto Condensed", width: "15vw"}}
                    />
                    <Button type="submit" style={{ backgroundColor: "#964cff", color: "#fff", marginTop: "2vh", marginBottom: "2vh"}}>Invite Delegate</Button>
                </form>
            )}

            {Object.keys(delegates).length > 0 ? (
                <List>
                    {Object.entries(delegates).map(([delegateId, delegate]) => (
                        <ListItem key={delegateId} alignItems="center">
                            <ListItemAvatar>
                                <Avatar alt={delegate.name} src={delegate.avatarUrl} />
                            </ListItemAvatar>
                            <ListItemText
                                primary={delegate.name}
                                secondary={<Typography variant="body2">User ID: {delegateId}</Typography>}
                            />
                        </ListItem>
                    ))}
                </List>
            ) : (
                <Typography variant="body1" sx={{fontSize: "1.1em"}}>No delegates available.</Typography>
            )}

            {!isFormOpen && (
                <Button onClick={() => setIsFormOpen(true)} style={{ color: "#964cff", fontSize: "1.1em"}}>Add New Delegate</Button>
            )}
        </div>
    );
};
