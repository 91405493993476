import React, { useMemo } from 'react'
import { GoogleMap, useJsApiLoader, MarkerF, GroundOverlay } from '@react-google-maps/api';


export interface MapsProps {
  width?: string;
  heigh?: string;
  lat: number;
  lng: number;
}

function Maps(props: MapsProps) {

  const { isLoaded } = useJsApiLoader({
    id: 'google-map-script',
    googleMapsApiKey: process.env.REACT_APP_GOOGLE_TOKEN!
  })

  const [map, setMap] = React.useState(null)

  const onLoad = React.useCallback(function callback(map: any) {
    map.setZoom(14)

    setMap(map)
  }, [])

  const onUnmount = React.useCallback(function callback(map: any) {
    setMap(null)
  }, [])

  const exampleMapStyles = [
    {
      featureType: "poi",
      elementType: "geometry",
      stylers: [
        {
          color: "#eeeeee",
        },
      ],
    },
    {
      featureType: "poi",
      elementType: "labels.text",
      stylers: [
        {
          visibility: "off",
        },
      ],
    },
    {
      featureType: "water",
      elementType: "labels.text.fill",
      stylers: [
        {
          color: "#9e9e9e",
        },
      ],
    },
  ];

  const contentStyles = {
    background: `white`,
    padding: 15,
  }


  return isLoaded ? (
    <GoogleMap
      options={{
        backgroundColor: "#9753f7",
        disableDoubleClickZoom: true,
        zoomControl: true,
        fullscreenControl: false,
        styles: exampleMapStyles
      }}

      mapContainerStyle={{
        width: props.width,
        height: props.heigh,
        backgroundColor: "#9753f7",
        background: `green`,
        padding: 15,
      }}

      center={{
        lat: props.lat,
        lng: props.lng
      }}
      zoom={14}
      onLoad={onLoad}
      onUnmount={onUnmount}
    >
      { /* Child components, such as markers, info windows, etc. */}
      <>
        <MarkerF
          position={{ lat: props.lat, lng: props.lng }}
        />
      </>
    </GoogleMap>
  ) : <></>
}

export default React.memo(Maps)