// components/Home.js
import { BounceLoader } from 'react-spinners';
import { useContext, useEffect, useState } from 'react';
import Grid from '@mui/material/Grid';
import { FireStoreUserContext } from '../../context/FireStoreUserContext';
import { HostRegistration } from './hostRegistration';
import LooksOneIcon from "@mui/icons-material/LooksOne";
import LooksTwoIcon from "@mui/icons-material/LooksTwo";
import LooksThreeIcon from "@mui/icons-material/Looks3";
import "./host.css";

export function Host() {
  const [isLoaded, setIsLoaded] = useState(false)
  const [isLoggedIn, setIsLoggedIn] = useState(false)
  const [isHost, setIsHost] = useState(false)

  const { parkshareUser, setParkshareUser, credsCheckDone } = useContext(FireStoreUserContext)

  useEffect(() => {
    const getData = async () => {
    }
    getData()
  }, [])


  return (
    <>
      {credsCheckDone ? (
        <>
          <Grid container style={{ padding: "6vh 15vw" }}>
            {parkshareUser !== undefined ? (
              <>
                {parkshareUser.hasOwnProperty('permissions') !== undefined ? (<>
                  {(parkshareUser.hasOwnProperty('isHost')) ? (<>
                    {parkshareUser.permissions.isHost !== undefined ? (
                      <h1>You are a host...</h1>
                    ) : (
                      <HostRegistration />
                    )}

                  </>) : (
                    <HostRegistration />
                  )
                  }
                </>) : (
                  <h1>We could not read your permissions</h1>
                )
                }
              </>
            ) : (
              <Grid container spacing={2}>
                <Grid item lg={6} md={4} xs={12} container
                  
                  direction="column"
                  alignItems="center"
                  justifyContent="center">
                  <div style={{ width: "70%" }}>
                    <h1 className='hostTitle' style={{ fontSize: 40, textAlign: "left" }}>Host your Parking with ParkShare</h1>
                  </div>
                </Grid>

                <Grid container lg={6} md={8} xs={12}>

                  <Grid item lg={10} md={10} xs={12}>
                    <div style={{ width: "85%", textAlign: "left" }}>
                      <div><span style={{ fontSize: "20px", fontWeight: 600, display: "flex", alignItems: "center"}}> <LooksOneIcon
                  sx={{ color: "#964cff", width: 40, height: 40, fontSize: "1.4rem" }}/> Earn a Passive Income</span></div>
                      <div ><p style={{ fontSize: "1.1em", marginTop: "3vh" }}>By signing up as a host with an available parking spot, like your driveway, you can
                        rent it out to other users and earn a passive income!</p></div>
                    </div>
                  </Grid>
                  <br /><br />

                  <Grid item lg={10} md={10} xs={12} sx={{marginTop: "4vh"}}>
                    <div style={{ width: "100%", textAlign: "left" }}>
                      <div><span style={{ fontSize: "20px", fontWeight: 600, display: "flex", alignItems: "center"}}><LooksTwoIcon
                  style={{ color: "#964cff", width: 40, height: 40, fontSize: "1.4rem" }}/> Add More Spots to Earn More</span></div>
                      
                      <div ><p style={{ fontSize: "1.1em", marginTop: "3vh" }}>ParkShare aims to create a platform where hosts can make a decent, passive income from hosting.
                        For this reason, we only take a 20% commission from booking transactions and you keep 80% of what your spot earns.</p></div>
                    </div>
                  </Grid>
                  <br /><br />

                  <Grid item lg={10} md={10} xs={12} sx={{marginTop: "4vh"}}>
                    <div style={{  textAlign: "left" }}>
                      <div><span style={{ fontSize: "20px", fontWeight: 600, display: "flex", alignItems: "center"}}><LooksThreeIcon
                  style={{ color: "#964cff", width: 40, height: 40, fontSize: "1.4rem" }}/> Available Near You</span></div>
                     
                      <div ><p style={{ fontSize: "1.1em", marginTop: "3vh" }}>We’re launching in Cape Town and the surrounding areas, but we plan to become available throughout South Africa soon.</p></div>
                    </div>
                  </Grid>
                </Grid>
              </Grid>
            )
            }
          </Grid>
        </>
      ) : (
        <Grid container spacing={2} style={{ minHeight: "50vh", paddingBottom: "20px" }}
          direction="column"
          alignItems="center"
          justifyContent="center">
          <Grid item lg={12} md={12} xs={12}>
            <BounceLoader
              color="#9753f7"
              cssOverride={{}}
              loading
              size={100}
            />
          </Grid>
        </Grid>

      )

      }
    </>


  );
}
