

// react-router-dom components
import { Link } from "react-router-dom";
import { DatePicker, TimePicker } from "antd";
import { FormGroup, Input, TextField } from "@mui/material";
// prop-types is a library for typechecking of props
import PropTypes from "prop-types";
import { styled } from '@mui/material/styles';
import Paper from '@mui/material/Paper';

// @mui material components
import Card from "@mui/material/Card";
import CardMedia from "@mui/material/CardMedia";
import Tooltip from "@mui/material/Tooltip";

// Material Dashboard 2 React components
import Box from "@mui/material/Box";
import Typography from "@mui/material/Typography";
import Button from "@mui/material/Button";
import Avatar from "@mui/material/Avatar";
import IconButton from "@mui/material/IconButton";
import HighlightOffIcon from '@mui/icons-material/HighlightOff';
import { Grid } from '@mui/material';
import { useState } from "react";


function DefaultProjectCard({ id, image, label, title, description, action, authors, deleteAction }) {



  const renderAuthors = authors.map(({ image: media, name }) => (
    <Tooltip key={name} title={name} placement="bottom">
      <Avatar
        src={media}
        alt={name}
        size="xs"
        sx={({ palette: { white } }) => ({
          border: `solid white`,
          cursor: "pointer",
          position: "relative",
          ml: -1.25,
          "&:hover, &:focus": {
            zIndex: "10",
          },
        })}
      />
    </Tooltip>
  ));

  return (

    <Card
      sx={{
        paddingLeft: 0,
        margin: "auto",
        display: "flex",
        flexDirection: "column",
        backgroundColor: "transparent",
        overflow: "visible",
        variant: "outlined",
        boxShadow: "8px 9px 15px grey",
        width: { xs: "90%", sm: "40%", md: "70%", lg: "70%", xl: "100%" }
      }}>

      <Grid container spacing={2}>
        <Grid item lg={12} md={12} xs={12} container
          spacing={0}
          direction="column"
          alignItems="center"
          justifyContent="center">
          <Box position="relative" width="80%" shadow="xl" borderRadius="xl" style={{ display: 'flex', justifyContent: 'center' }} >
            <IconButton fontSize="large" style={{ position: "absolute", top: "-5px", right: "-15px" }} onClick={() => { deleteAction(id) }}>
              <HighlightOffIcon fontSize="large" style={{ position: "absolute", top: "5px", right: "5px" }} />
            </IconButton>

            <CardMedia
              src={image}
              component="img"
              title={title}
              sx={{
                width: "20vw",
                height: "20vh",
                margin: 0,
                objectFit: "contain",
                objectPosition: "center",
                paddingTop: "10px",
                borderRadius: "0vw",

              }} />

          </Box>
        </Grid>
      </Grid>

      <Box mt={1} mx={0.5}>
        <Typography variant="button" fontWeight="regular" color="text" textTransform="capitalize">
          {label}
        </Typography>
        <Box mb={1}>
          {action.type === "internal" ? (
            <Typography
              component={Link}
              to={action.route}
              variant="h6"
              textTransform="capitalize"
              sx={{
                color: "#000", textDecoration: "none", fontWeight: "normal",
                "&:hover": {
                  color: "#9753f7"
                }
              }}
            >
              {title}
            </Typography>
          ) : (
            <Typography
              component="a"
              href={action.route}
              target="_blank"
              rel="noreferrer"
              variant="h5"
              textTransform="capitalize"
              sx={{ color: "#000", textDecoration: "none" }}
            >
              {title}
            </Typography>
          )}
        </Box>

        <Box mb={3} lineHeight={0}>
          <Typography variant="button" fontWeight="light" color="text">
            {description}
          </Typography>
        </Box>

        <Grid container spacing={2}>
          <Grid item lg={12} md={12} xs={12} container
            spacing={0}
            direction="column"
            alignItems="center"
            justifyContent="center">
            <Box display="flex" justifyContent="space-between" alignItems="center">
              <Button
                // component={Link}
                // to={action.route}
                variant="outlined"
                size="small"
                style={{ backgroundColor: "#9753f7", color: "white", marginRight: "5px", borderColor: "#9753f7" }}
                color={action.color}
                onClick={() => action(id)}>
                View Spot
              </Button>
            </Box>

          </Grid>

          <Grid item lg={12} md={12} xs={12} container
            spacing={0}
            direction="column"
            alignItems="center"
            justifyContent="center">
            <Box display="flex" justifyContent="space-between" alignItems="center">

              <Box display="flex" sx={{marginBottom: "2vh"}}>{renderAuthors}</Box>
            </Box>
          </Grid>

        </Grid>
      </Box>
    </Card>
  );
}

// Setting default values for the props of DefaultProjectCard
DefaultProjectCard.defaultProps = {
  authors: [],
};

// Typechecking props for the DefaultProjectCard
DefaultProjectCard.propTypes = {
  id: PropTypes.any.isRequired,
  image: PropTypes.string.isRequired,
  label: PropTypes.string.isRequired,
  title: PropTypes.string.isRequired,
  description: PropTypes.string.isRequired,
  action: PropTypes.func.isRequired,
  deleteAction: PropTypes.func.isRequired,
  authors: PropTypes.arrayOf(PropTypes.object),
};

export default DefaultProjectCard;
