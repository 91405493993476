// components/Protected.js
import { BounceLoader } from 'react-spinners';
import { useContext, useEffect, useState } from 'react';
import { AuthContext } from '../../context/AuthContext';
import { Avatar, Grid } from '@mui/material';
import Typography from '@mui/material/Typography';
import { FireStoreUserContext } from '../../context/FireStoreUserContext';
import Box from '@mui/material/Box';
import { getStorage, ref } from "firebase/storage";

// @mui material components
import Divider from "@mui/material/Divider";

// @mui icons
import FacebookIcon from "@mui/icons-material/Facebook";
import TwitterIcon from "@mui/icons-material/Twitter";
import InstagramIcon from "@mui/icons-material/Instagram";

// Material Dashboard 2 React example components
import ProfileInfoCard from "../../examples/Cards/InfoCards/ProfileInfoCard";
import ProfilesList from "../../examples/Lists/ProfilesList";
import DefaultProjectCard from "../../examples/Cards/ProjectCards/DefaultProjectCard";

// Overview page components
import PlatformSettings from "../../layout/profile/components/PlatformSettings";


import Header from "../../layout/profile/components/Header";

interface TabPanelProps {
  children?: React.ReactNode;
  index: number;
  value: number;
}

const storage = getStorage();

function TabPanel(props: TabPanelProps) {
  const { children, value, index, ...other } = props;

  return (
    <div
      role="tabpanel"
      hidden={value !== index}
      id={`vertical-tabpanel-${index}`}
      aria-labelledby={`vertical-tab-${index}`}
      {...other}
    >
      {value === index && (
        <Box sx={{ p: 3 }}>
          <Typography>{children}</Typography>
        </Box>
      )}
    </div>
  );
}

function a11yProps(index: number) {
  return {
    id: `vertical-tab-${index}`,
    'aria-controls': `vertical-tabpanel-${index}`,
  };
}


export function Profile() {

  const user = useContext(AuthContext)
  const { parkshareUser, setParkshareUser } = useContext(FireStoreUserContext)

  const [isLoaded, setIsLoaded] = useState(false)
  const [notificationID, setNotificationID] = useState("")
  const [value, setValue] = useState(0);

  const handleChange = (event: React.SyntheticEvent, newValue: number) => {
    setValue(newValue);
  };



  useEffect(() => {
    const getData = async () => {
      console.log("Context:", parkshareUser)
      setIsLoaded(true)
      console.log("Profile of", user)

    }
    getData()
  }, [])


  return (
    <>
      {isLoaded ? (
        <>
          <Box mb={2} />
          <Header >
            <Box mt={5} mb={3}>
              <Grid container spacing={1} >
                <Grid item xs={12} md={6} xl={4}>
                  <PlatformSettings />
                </Grid>
                <Grid item xs={12} md={6} xl={4} sx={{ display: "flex" }}>
                  <Divider orientation="vertical" sx={{ ml: -2, mr: 1 }} />
                  <ProfileInfoCard
                  />
                  <Divider orientation="vertical" sx={{ mx: 0 }} />
                </Grid>
                <Grid item xs={12} xl={4}>
                  <ProfilesList title="conversations" profiles={[]} shadow={false} />
                </Grid>
              </Grid>
            </Box>

          </Header>

        </>
      ) : (
        <div style={{ width: '100%', height: '70vh', alignContent: 'center', textAlign: 'center', justifyContent: 'center', display: 'flex' }}>
          <div style={{ marginTop: '25vh' }}><BounceLoader color="#9753f7" cssOverride={{}} loading size={100} /></div>

        </div>
      )}
    </>


  );
}
