import { BounceLoader } from 'react-spinners';
import { useEffect, useState } from 'react';
import { Grid } from '@mui/material';
import Box from '@mui/material/Box';
import { useLocation } from 'react-router-dom';

export function PaymentStatus() {
  const [isLoaded, setIsLoaded] = useState(false);
  const [checkedPaymentStatus, setCheckPaymentStatus] = useState(true);
  const location = useLocation();
  const searchParams = new URLSearchParams(location.search);

  useEffect(() => {
    const getData = async () => {
      setIsLoaded(true);
      console.log(searchParams.get('reference'));
    };
    getData();
  }, []);

  return (
    <>
      {isLoaded ? (
        <Box display="flex" justifyContent="center">
          <Grid container spacing={3}>
            <Grid item xs={12}>
              <h1>Payment information</h1>
              {checkedPaymentStatus ? (
                <>
                  <h5>Waiting to confirm payment with reference {searchParams.get('reference')}</h5>
                </>
              ) : (
                <>
                  {/* Add your content here */}
                </>
              )}
            </Grid>
            <Grid item xs={12} container justifyContent="center" alignItems="center">
              <BounceLoader color="#9753f7" cssOverride={{}} loading size={100} />
            </Grid>
            <Grid item xs={12}>
                            <>
                  <h5>You can leave this page we will notify you when card has been added.</h5>
                </>
            </Grid>
          </Grid>
        </Box>
      ) : (
        <Box display="flex" justifyContent="center" alignItems="center" height="100vh">
          <BounceLoader color="#9753f7" cssOverride={{}} loading size={100} />
        </Box>
      )}
    </>
  );
}