import Login from './pages/auth/Login';

import { BrowserRouter, Routes, Route } from 'react-router-dom';

import './App.css';
import { Home } from './pages/home/home';
import RequireAuth from './components/auth/RequireAuth';
import { Layout } from './layout/Layout';
import { AppRoute, CustomRoutes, SettingRoutes } from './routes'
import { NotFoundPage } from './pages/errors/400';

import { ReactNotifications } from 'react-notifications-component'
import 'react-notifications-component/dist/theme.css'
import React, { useEffect, useState } from 'react';
import { AuthProvider } from './components/auth/AuthProvider';
import { FireStoreUserContext } from './context/FireStoreUserContext';
import { auth } from './firebase';
import { UserHelper } from './data/firestore/user';
import { addDoc, collection, doc, getCountFromServer, getDoc, getDocs, query, setDoc, where } from "firebase/firestore";
import { db } from "./firebase";
import { firestoreKeys } from "./utils/constants";

function MyRoutes() {

  return (
    < >
      <ReactNotifications />

      <BrowserRouter>
        <Routes>

          <Route path="/" element={<Layout />}>

            {/* Default index page */}
            <Route index element={<Home />} />

            {/* These get loaded from the routes. */}
            {CustomRoutes.map((cRo: AppRoute) => {

              return (
                <Route
                  key={cRo.DisplayName}
                  path={cRo.Navigation}
                  element={cRo.Protected ?
                    <RequireAuth>
                      <cRo.Component />
                    </RequireAuth> :
                    <cRo.Component />}
                />
              )
            })}

            {SettingRoutes.map((setting: AppRoute) => (
              <Route
                key={setting.DisplayName}
                path={setting.Navigation}
                element={setting.Protected ?
                  <RequireAuth>
                    <setting.Component />
                  </RequireAuth> :
                  <setting.Component />}
              />
            ))}
            {/* <Route path="/login" element={<Login />} /> */}
            {/* Catches any routes not selected */}
            <Route path='*' element={<NotFoundPage />} />
          </Route>
        </Routes>
      </BrowserRouter>
    </>
  );
}

function App() {

  const [parkshareUser, setParkshareUser] = useState<any>();
  const [credsCheckDone, setCredsCheckDone] = useState<boolean>(false);
  const [isValidDev, setIsValidDev] = useState(false);
  const [username, setUsername] = useState('');
  const [password, setPassword] = useState('');
  const [devMessage, setDevMessage] = useState('');

  const handleLogin = async (e: any) => {
    e.preventDefault();

    try {
      const docRef = doc(db, "dev", "auth");
      const docSnap = await getDoc(docRef);
      const dataToCompare = docSnap.data()
      console.log("Got", docSnap.data())
      if (dataToCompare?.username === username && dataToCompare?.password === password ) {
        setIsValidDev(true)
      
      }else{
        setDevMessage("Sorry that wont work.")
        setIsValidDev(false)
      }
      
    } catch (error) {
      console.log('Login failed', error);
      setDevMessage("Sorry something went wrong . Contact the devs")
      setIsValidDev(false)
    }
  };

  useEffect(() => {
    const getContacts = async () => {
      console.log("Here??");

      getContacts()
    }
  }, [])

  return (
    <React.StrictMode>
      {isValidDev ? (
        <>
          <FireStoreUserContext.Provider value={{ parkshareUser, setParkshareUser, credsCheckDone, setCredsCheckDone }}>
            <AuthProvider>
              <MyRoutes />
            </AuthProvider>
          </FireStoreUserContext.Provider>
        </>
      ) : (
        <>
          <div>
            <h2>Login</h2>
            <p>This is a dev account</p>
            <form onSubmit={handleLogin}>
              <input
                type="text"
                placeholder="Username"
                value={username}
                onChange={(e) => setUsername(e.target.value)}
              />
              <br />
              <input
                type="password"
                placeholder="Password"
                value={password}
                onChange={(e) => setPassword(e.target.value)}
              />
              <br />
              <button type="submit">Login</button>
            </form>
            <p>{devMessage}</p>
          </div>
        </>
      )
      }
    </React.StrictMode>
  );
}

export default App;