

// @mui material components
import Card from "@mui/material/Card";

// Material Dashboard 2 React components
import Box from "@mui/material/Box";
import Typography from "@mui/material/Typography";
import Button from "@mui/material/Button";

// Billing page components
import Invoice from "../Invoice";

function Invoices() {
  return (
    <Card sx={{ height: "100%",  boxShadow: "0 20px 25px -5px rgba(0, 0, 0, 0.2), 0 10px 10px -5px rgba(0, 0, 0, 0.2) !important" }}>
      <Box pt={2} px={2} display="flex" justifyContent="space-between" alignItems="center">
        <Typography variant="h6" fontWeight="medium" sx={{
          background: "linear-gradient(90deg, #964cff, #bb2cc0)",
          backgroundClip: "text", textFillColor: "transparent", fontSize: "1.4rem"
        }}>
          Statements
        </Typography>
        {/* <Button variant="outlined" color="info" size="small">
          view all
        </Button> */}
      </Box>
      <Box p={2}>
        <Box component="ul" display="flex" flexDirection="column" p={0} m={0}>
          {/* Tempalte one */}
          <Invoice date="June, 30, 2023" id="" price="R2900" />
          <p>No invoices to download</p>
        </Box>
      </Box>
    </Card>
  );
}

export default Invoices;
