import { Link } from "react-router-dom";

// prop-types is library for typechecking of props
import PropTypes from "prop-types";

// @mui material components
import Card from "@mui/material/Card";
import Tooltip from "@mui/material/Tooltip";
import Icon from "@mui/material/Icon";
import EditIcon from '@mui/icons-material/Edit';

// Material Dashboard 2 React components
import Box from "@mui/material/Box";
import Typography from "@mui/material/Typography";
import { useContext, useState } from "react";
import { FireStoreUserContext } from "../../../../context/FireStoreUserContext";
import { FormControl, IconButton, Input, InputLabel, Button } from "@mui/material";


function ProfileInfoCard() {

  const { parkshareUser } = useContext(FireStoreUserContext)
  const [editing, setEditing] = useState(false)

  return (
    <Card style={{ height: "100%", width: '100%', boxShadow: "none" }}>
      <Box display="flex" justifyContent="space-between" alignItems="center" pt={2} px={2}>
        <Typography variant="h6" fontWeight="medium" textTransform="capitalize" sx={{fontSize: "1.5rem",  background: "-webkit-linear-gradient(90deg, #964cff, #bb2cc0)",
          backgroundClip: "text", textFillColor: "transparent"}}>
          Profile Information
        </Typography>
        <Typography variant="body2" color="secondary">
          <Tooltip title={"Edit"} placement="top">
            <IconButton onClick={() => { setEditing(true) }} sx={{color: "#964cff"}}>
              <EditIcon />
            </IconButton>
          </Tooltip>
        </Typography>
      </Box>
      <Box p={2}>
        <Box>
          <Box display="flex" py={1} pr={2}>
            {editing ? (
              <FormControl style={{ width: "90%" }}>
                <InputLabel htmlFor="my-input">First Name</InputLabel>
                <Input id="my-input" aria-describedby="my-helper-text" value={parkshareUser.person.firstName || ""} />
              </FormControl>
            ) : (
              <>
                <FormControl style={{ width: "90%" }}>
                  <InputLabel htmlFor="my-input">First Name</InputLabel>
                  <Input id="my-input" aria-describedby="my-helper-text" disabled/>
                </FormControl>
              </>
            )}
          </Box>
          <Box display="flex" py={1} pr={2}>
            {editing ? (
              <FormControl style={{ width: "90%" }}>
                <InputLabel htmlFor="my-input">Last Name</InputLabel>
                <Input id="my-input" aria-describedby="my-helper-text" value={parkshareUser.person.phoneNumber || ""} />
              </FormControl>
            ) : (
              <>
                <FormControl style={{ width: "90%" }}>
                  <InputLabel htmlFor="my-input">Last Name</InputLabel>
                  <Input id="my-input" aria-describedby="my-helper-text" disabled/>
                </FormControl>
              </>
            )}
          </Box>
          <Box display="flex" py={1} pr={2}>
            {editing ? (
              <FormControl style={{ width: "90%" }}>
                <InputLabel htmlFor="my-input">Phone Number</InputLabel>
                <Input id="my-input" aria-describedby="my-helper-text" value={parkshareUser.person.phoneNumber || ""} />
              </FormControl>
            ) : (
              <>
                <FormControl style={{ width: "90%" }}>
                  <InputLabel htmlFor="my-input">Phone Number</InputLabel>
                  <Input id="my-input" aria-describedby="my-helper-text" disabled/>
                </FormControl>
              </>
            )}
          </Box>
          <Box display="flex" py={1} pr={2}>
            {editing && (
              <>
                <Button variant="contained" style={{ backgroundColor: "grey", color: "white", marginRight: "5px", width: "5vw" }} onClick={() => { setEditing(false) }}>Cancel</Button>
                <Button variant="contained" style={{ backgroundColor: "#9753f7", color: "white", marginRight: "5px", width: "5vw" }}>Save </Button>
              </>
            )}
          </Box>

        </Box>
      </Box>
    </Card>
  );
}


export default ProfileInfoCard;
