import { Outlet } from 'react-router-dom';
import NavBar from './navbar/Navbar';
import { Footer } from './footer/Footer';
import { Alert } from '@mui/material';
import { useEffect, useState } from 'react';
import { collection, getDocs, orderBy, query, where } from 'firebase/firestore';
import { firestoreKeys } from '../utils/constants';
import { db } from '../firebase';
import { useSpring, animated } from "react-spring";
import { ReactNotifications } from 'react-notifications-component';

export function Layout() {

  const [show, setShow] = useState(false);
  const [banner, setBanner] = useState<any>();
  const [key, setKey] = useState(1);

  useEffect(() => {
    const getContacts = async () => {
      const querySnapshot = await getDocs(query(collection(db, firestoreKeys.BANNERS), where('active', '==', true)));
      querySnapshot.forEach((doc) => {
        setBanner(doc.data())
        setShow(true)
      });

    }
    getContacts()
  }, [])

  const scrolling = useSpring({
    from: { transform: "translate(60%,0)" },
    to: { transform: "translate(-60%,0)" },
    config: { duration: 12000 },
    reset: true,
    //reverse: key % 2 == 0,
    onRest: () => {
      setKey(key + 1);
    },
    overflow: "hidden",
  });

  return (
    <>
      <NavBar />
      
      <div style={{ minHeight: '100%', marginTop: "15vh", }}>
      {show && (
        <div style={{  marginBottom: "1vh" }}>
          <Alert severity="error">
            <div key={key} style={{ width: "90vw", overflow: "hidden" }}>
              <animated.div style={scrolling}>{banner.message}</animated.div>
            </div>
          </Alert>
        </div>
      )}
        <div style={{ minHeight: '72vh' }}>
          <ReactNotifications />
          <Outlet />
        </div>
      </div>
      <Footer />

    </>
  );
}