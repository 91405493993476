import { UserCredential } from 'firebase/auth';
import { addDoc, collection, doc, getCountFromServer, getDoc, getDocs, query, setDoc, where } from "firebase/firestore";
import { db } from "../../firebase";
import { firestoreKeys } from "../../utils/constants";
import firebase from 'firebase/compat/app';

export class SpotsHelper {

    fireBaseUser: firebase.User;

    constructor(fireBaseUser: firebase.User) {
        this.fireBaseUser = fireBaseUser;
    }

    async createSpot() {
        const docRef = await addDoc(collection(db, firestoreKeys.SPOTS), {
            host: {
                id: this.fireBaseUser.uid
            },
            delegated: {
                ids: [this.fireBaseUser.uid]
            }
        });
        console.log("Document written with ID: ", docRef);
        // await setDoc(doc(db, firestoreKeys.SPOTS, "testSpot1"), {
        //     host: {
        //         id: this.fireBaseUser.uid
        //     },
        // });
    }

    async getSpot(spotID: string) {

        const docRef = doc(db, firestoreKeys.SPOTS, spotID);
        const docSnap = await getDoc(docRef);
        console.log("spot Got", docSnap.data())
        return docSnap.data()
    }
    async getMyDeliagtedSpots(email: string) {
        const encoded = encodeURI(email.replace(".", "-"));
        console.log("Getting delegates for", `management.delegates.${encoded}`)
        const spotsQuery = query(collection(db, firestoreKeys.SPOTS), where(`management.delegates.${encoded}`, '!=', ""))
        const querySnapshot = await getDocs(spotsQuery);
        return querySnapshot
    }
    async getMySpots() {
        const spotsQuery = query(collection(db, firestoreKeys.SPOTS), where('owner', '==', this.fireBaseUser.uid))
        const querySnapshot = await getDocs(spotsQuery);

        return querySnapshot
    }

    async updateUser(id: string) {
        console.log("Getting user")
        console.log("Using", id)
        const docRef = doc(db, firestoreKeys.USERS, id);
        const docSnap = await getDoc(docRef);
        console.log("Got", docSnap.data())
        return docSnap.data()
    }
}