import React, { useState } from 'react';
import { createUserWithEmailAndPassword, signInWithEmailAndPassword, GoogleAuthProvider, signInWithPopup } from 'firebase/auth';

import { useNavigate } from 'react-router-dom'
import { Box, Button, Grid, Tabs, TextField } from '@mui/material';
import mockLogo from '../../../src/assets/img/home/Mockup.webp'
import Tab from '@mui/material/Tab';
import Typography from '@mui/material/Typography';
import { Store } from 'react-notifications-component';
import { UserHelper } from '../../data/firestore/user';
import { auth, db } from '../../firebase';
import { doc, setDoc } from 'firebase/firestore';
import GoogleButton from 'react-google-button'
import { Divider } from '@aws-amplify/ui-react';
import { FireStoreUserContext } from '../../context/FireStoreUserContext';

export interface TabPanelProps {
  children?: React.ReactNode;
  index: number;
  value: number;
}

export function TabPanel(props: TabPanelProps) {
  const { children, value, index, ...other } = props;

  return (
    <div
      role="tabpanel"
      hidden={value !== index}
      id={`simple-tabpanel-${index}`}
      aria-labelledby={`simple-tab-${index}`}
      {...other}
    >
      {value === index && (
        <Box sx={{ p: 3 }}>
          <Typography>{children}</Typography>
        </Box>
      )}
    </div>
  );
}

function a11yProps(index: number) {
  return {
    id: `simple-tab-${index}`,
    'aria-controls': `simple-tabpanel-${index}`,
  };
}

const Login = () => {

  const navigate = useNavigate();

  const { parkshareUser, setParkshareUser } = React.useContext(FireStoreUserContext)

  const [email, setEmail] = useState('');
  const [password, setPassword] = useState('');
  const [value, setValue] = React.useState(0);
  const [firstName, setFirstName] = useState('')
  const [lastName, setLastName] = useState('')
  const [emailSignup, setEmailSignup] = useState('')
  const [passwordSignup, setPasswordSignup] = useState('');
  const [notificationID, setNotificationID] = useState("")

  const handleChange = (event: React.SyntheticEvent, newValue: number) => {
    setValue(newValue);
  };

  const onLogin = (e: any) => {
    e.preventDefault();
    signInWithEmailAndPassword(auth, email, password)
      .then((userCredential) => {
        // Signed in 
        const user = userCredential.user;
        const userHelper = new UserHelper()
        userHelper.getUser(userCredential.user.uid).then((data: any) => {
          console.log(data)
          data.uid = userCredential.user.uid
          setParkshareUser(data)


        })
        navigate("/home")
        console.log(user);
      })
      .catch((error) => {
        const errorCode = error.code;
        const errorMessage = error.message;
        console.log(errorCode, errorMessage)
      });

  }

  const googleSubmit = async (e: any) => {
    e.preventDefault()
    const provider = new GoogleAuthProvider();
    signInWithPopup(auth, provider).then((userCredential) => {
      // Signed in 
      const user = userCredential.user;
      const userHelper = new UserHelper()
      userHelper.getUser(userCredential.user.uid).then(async (data) => {
        console.log(data)
        if (data === undefined) {
          console.log("NO user yet in firestore lets make it")
          await setDoc(doc(db, "users", user.uid), {
            person: {
              firstName: user.displayName,
              photoUrl: user.photoURL,
              email: user.email,
            }
          }).then((data: any) => {
            data.uid = userCredential.user.uid
            setParkshareUser(data)
          }).catch(() => {
            console.log("Oops")
          });
        } else {
          data.uid = userCredential.user.uid
          setParkshareUser(data)
        }


      })
      navigate("/")
      console.log(user);
    })
      .catch((error) => {
        const errorCode = error.code;
        const errorMessage = error.message;
        console.log(errorCode, errorMessage)
      });

  }

  const onSubmit = async (e: any) => {
    e.preventDefault()


    await createUserWithEmailAndPassword(auth, emailSignup, passwordSignup)
      .then(async (userCredential) => {
        // Signed in 
        const user = userCredential.user;
        await setDoc(doc(db, "users", user.uid), {
          person: {
            firstName: firstName,
            lastName: lastName,
            email: emailSignup,
          }
        });
        setNotificationID(Store.addNotification({
          title: "Welcome!",
          message: "to Parkshare",
          type: "success",
          insert: "top",
          container: "top-center",
          animationIn: ["animate__animated", "animate__fadeIn"],
          animationOut: ["animate__animated", "animate__fadeOut"],
          dismiss: {
            duration: 2000,
            onScreen: true
          }
        }));

        Store.removeNotification(notificationID)
        navigate("/")
        // ...
      })
      .catch((error) => {
        const errorCode = error.code;
        const errorMessage = error.message;
        console.log(errorCode, errorMessage);
        setNotificationID(Store.addNotification({
          title: "Oops!",
          message: errorMessage,
          type: "warning",
          insert: "top",
          container: "top-center",
          animationIn: ["animate__animated", "animate__fadeIn"],
          animationOut: ["animate__animated", "animate__fadeOut"],
          dismiss: {
            duration: 2000,
            onScreen: true
          }
        }));

        Store.removeNotification(notificationID)
        // ..
      });


  }



  return (
    <>
      <Grid container spacing={2} style={{ minHeight: "70vh" }}>
        <Grid item lg={3} md={0} xs={12} container>

        </Grid>
        <Grid item lg={3} md={6} xs={12} container
          spacing={0}
          direction="column"
          alignItems="center"
          justifyContent="center"
        >
          <Box
            component="img"
            sx={{
              objectFit: "cover",
              width: "100%",
              display: { xs: 'none', md: 'flex' }
            }}

            src={mockLogo}
          />
        </Grid>
        <Grid item lg={3} md={6} xs={12} container
          spacing={0}
          direction="column"
          alignItems="center"
          justifyContent="center">
          <Box sx={{ borderBottom: 1, borderColor: 'divider', width: "100%", marginTop: "20px", position: "flex" }}>
            <Tabs value={value} onChange={handleChange} aria-label="basic tabs example" textColor="secondary"
              indicatorColor="secondary" variant="fullWidth" sx={{ width: "100%" }}>
              <Tab sx={{ width: "50%" }} label="Login" {...a11yProps(0)} />
              <Tab sx={{ width: "50%" }} label="Signup" {...a11yProps(1)} />
            </Tabs>
          </Box>
          <TabPanel value={value} index={0}>
            <form className="mt-8 space-y-6" style={{ minHeight: "50vh", minWidth: "30vw" }} >


              <TextField id="outlined-basic" label="Email" variant="outlined" value={email}
                onChange={(e) => setEmail(e.target.value)} style={{ minWidth: "100%" }} />
              <br /><br />
              <TextField
                style={{ width: "100%" }}
                id="outlined-password-input"
                label="Password"
                type="password"
                autoComplete="current-password"
                required
                onChange={(e) => setPassword(e.target.value)}
              />
              <br /><br />
              <div>
                <GoogleButton style={{ width: "100%" }}
                  onClick={googleSubmit}
                />

              </div>

             
              <br />
              <Divider></Divider>
              <br />
              <Button onClick={onLogin} variant="contained" style={{ backgroundColor: "#9753f7", color: "white", marginRight: "5px" }}>Login</Button>
              
            </form>
          </TabPanel>
          {/* Signup */}
          <TabPanel value={value} index={1}>
            <form onSubmit={onSubmit} className="mt-8 space-y-6" style={{ minHeight: "50vh", minWidth: "30vw" }} >
              <div className=" space-y-6 rounded-md shadow-sm">
                <div>
                  <TextField id="outlined-basic" label="Name" variant="outlined" value={firstName}
                    onChange={(e) => setFirstName(e.target.value)} style={{ minWidth: "100%" }} />

                </div>
                <br />
                <div>
                  <TextField id="outlined-basic" label="Surname" variant="outlined" value={lastName}
                    onChange={(e) => setLastName(e.target.value)} style={{ minWidth: "100%" }} />

                </div>
                <br />
                <div>
                  <TextField id="outlined-basic" label="Email" variant="outlined" value={emailSignup}
                    onChange={(e) => setEmailSignup(e.target.value)} style={{ minWidth: "100%" }} />

                </div>
                <br />
                <TextField fullWidth id="outlined-basic" type="password" label="Password" variant="outlined" required value={passwordSignup}
                  onChange={(e) => setPasswordSignup(e.target.value)} style={{ minWidth: "100%" }}
                />
              </div>
              <br />
              <div >
                <GoogleButton style={{ width: "100%" }}
                  label='Sign up with Google'
                  onClick={googleSubmit}
                />
              </div>
             
              <br />
              <Divider></Divider>
              <br/>
              <div>
                <Button onClick={onSubmit} variant="contained" style={{ backgroundColor: "#9753f7", color: "white", marginRight: "5px" }}>Sign up</Button>
              </div>
             
              

            </form>
          </TabPanel>


        </Grid>
        <Grid item lg={3} md={0} xs={12} container>

        </Grid>
      </Grid>

    </>
  )
}

export default Login