import React, { useState } from 'react';
import { Checkbox, Button, TimePicker } from 'antd';
import dayjs, { Dayjs } from 'dayjs';
import { Grid } from '@mui/material';

interface WeekScheduleSelectorProps {
  onWeekScheduleChange: (weekSchedule: { [day: string]: [Dayjs | null, Dayjs | null] }) => void;
}

const WeekScheduleSelector: React.FC<WeekScheduleSelectorProps> = ({ onWeekScheduleChange }) => {
  const [weekSchedule, setWeekSchedule] = useState<{ [day: string]: [Dayjs | null, Dayjs | null] }>({});

  const handleTimeRangeChange = (day: string, index: number, newValue: [Dayjs | null, Dayjs | null]) => {
    setWeekSchedule((prevSchedule) => ({
      ...prevSchedule,
      [day]: newValue,
    }));
  };

  const handleClearTimeRange = (day: string) => {
    setWeekSchedule((prevSchedule) => {
      const updatedSchedule = { ...prevSchedule };
      updatedSchedule[day] = [null, null];
      return updatedSchedule;
    });
  };

  const handleStartTimeChange = (day: string, startTime: Dayjs | null) => {
    const endTime = weekSchedule[day]?.[1];
    const updatedEndTime = endTime && startTime ? endTime.startOf('hour').subtract(1, 'hour') : endTime;
    handleTimeRangeChange(day, 0, [startTime, updatedEndTime]);
  };

  const daysOfWeek = ['Monday', 'Tuesday', 'Wednesday', 'Thursday', 'Friday', 'Saturday', 'Sunday'];

  return (
    <div style={{ width: '100%' }}>
      <Grid container spacing={2} style={{ marginBottom: '1rem' }}>
        {daysOfWeek.map((day) => (
          <Grid item key={day} xs={12} sm={6} md={4} lg={3}>
            <Checkbox
              checked={Boolean(weekSchedule[day])}
              onChange={(e) => {
                if (e.target.checked) {
                  setWeekSchedule((prevSchedule) => ({
                    ...prevSchedule,
                    [day]: [null, null],
                  }));
                } else {
                  setWeekSchedule((prevSchedule) => {
                    const updatedSchedule = { ...prevSchedule };
                    delete updatedSchedule[day];
                    return updatedSchedule;
                  });
                }
              }}
              style={{ fontSize: '1em', display: 'flex', alignItems: 'center' }}
            >
              {day}
            </Checkbox>
            {weekSchedule[day] && (
              <Grid container spacing={2}>
                <Grid item xs={6}>
                  <TimePicker
                    value={weekSchedule[day][0]}
                    onChange={(startTime) => handleStartTimeChange(day, startTime)}
                    disabled={!weekSchedule[day]}
                    format="HH:mm"
                    minuteStep={10}
                  />
                </Grid>
                <Grid item xs={6}>
                  <TimePicker
                    value={weekSchedule[day][1]}
                    onChange={(endTime) => handleTimeRangeChange(day, 1, [weekSchedule[day][0] || null, endTime || null])}
                    disabled={!weekSchedule[day]}
                    format="HH:mm"
                    minuteStep={10}
                    disabledHours={() => {
                      const startHour = weekSchedule[day]?.[0]?.hour() || 0;
                      return Array.from({ length: startHour+1}, (_, i) => i);
                    }}
                  />
                </Grid>
                <Grid item xs={12}>
                  <Button
                    onClick={() => handleClearTimeRange(day)}
                    style={{ backgroundColor: '#c7d3e277', border: '0.1vw solid #c7d3e277', color: '#000' }}
                  >
                    Clear
                  </Button>
                </Grid>
              </Grid>
            )}
          </Grid>
        ))}
      </Grid>
      {Object.keys(weekSchedule).length > 0 && (
        <Button
          onClick={() => onWeekScheduleChange(weekSchedule)}
          style={{
            backgroundColor: '#964cff',
            color: '#fff',
            border: '0.1vw solid #964cff',
            height: '5vh',
            width: '5vw',
          }}
        >
          Apply
        </Button>
      )}
    </div>
  );
};

export default WeekScheduleSelector;
