import { createContext} from "react";

export const FireStoreUserContext = createContext<any>({
  parkshareUser: null,
  setParkshareUser: (value: any) => {},
  credsCheckDone: false,
  setCredsCheckDone: (value: boolean) => {},
});


